define("labs-nyc-factfinder/templates/components/lookup-layer-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rNhnJkyj",
    "block": "[[[18,1,[[28,[37,1],null,[[\"model\",\"layer\"],[[30,0,[\"model\"]],[30,0,[\"layer\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/lookup-layer-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});