define("labs-nyc-factfinder/templates/components/select-geography-list-ccds", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nMk7YfAh",
    "block": "[[[10,1],[14,0,\"comma-separated-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[1,[30,2]],[1,[52,[51,[28,[37,3],[[30,1,[\"lastObject\"]],[30,2]],null]],\",\"]],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@sortedLabels\",\"ccd\"],false,[\"each\",\"-track-array\",\"unless\",\"eq\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/select-geography-list-ccds.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});