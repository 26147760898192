define("labs-nyc-factfinder/templates/components/select-geography-list-ntas", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "W/QcUjl7",
    "block": "[[[10,1],[14,0,\"comma-separated-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sortedLabels\"]]],null]],null],null,[[[41,[30,1,[\"features\",\"length\"]],[[[1,\"      \"],[10,\"strong\"],[12],[1,[30,1,[\"label\"]]],[1,\":\"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"features\"]]],null]],null],null,[[[1,\"      \"],[1,[30,2,[\"properties\",\"ntaname\"]]],[1,\" [\"],[1,[30,2,[\"properties\",\"geoid\"]]],[1,\"]\"],[1,[52,[51,[28,[37,4],[[30,1,[\"features\",\"lastObject\",\"properties\",\"geoid\"]],[30,2,[\"properties\",\"geoid\"]]],null]],\",\"]],[1,\"\\n\"]],[2]],null]],[1]],null],[13],[1,\"\\n\"]],[\"boro\",\"feature\"],false,[\"each\",\"-track-array\",\"if\",\"unless\",\"eq\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/select-geography-list-ntas.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});