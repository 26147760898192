define("labs-nyc-factfinder/components/layer-menu-item", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      const recordIdentifier = this.get('for');
      const foundRecord = this.get('store').peekRecord('layer-group', recordIdentifier);

      if (foundRecord) {
        foundRecord.set('layers', foundRecord.layers.map(layer => {
          if (layer.id === 'choropleth-nta-fill') {
            layer.paint = { ...layer.paint,
              ...this.defaultFill
            };
          }

          if (layer.id === 'choropleth-nta-line') {
            layer.paint = { ...layer.paint,
              ...this.defaultLine
            };
          }

          return layer;
        }));
        this.set('model', foundRecord);
      }
    },

    store: (0, _service.inject)(),
    for: '',
    actions: {
      updatePaintFor(layerId, newPaintStyle) {
        const model = this.get('model');
        model.set('layers', model.layers.map(layer => {
          if (layer.id === layerId) {
            layer.paint = { ...layer.paint,
              ...newPaintStyle
            };
          }

          return layer;
        }));
      },

      toggle() {
        this.get('model').toggleProperty('visible');
      }

    }
  });

  _exports.default = _default;
});