define("labs-nyc-factfinder/chart-config/acs/social/residence-1-year-ago", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'dfhssmcnt',
    label: 'Moved within same borough in NYC'
  }, {
    property: 'dhdfcntnyc',
    label: 'Moved from different borough in NYC'
  }, {
    property: 'dhnonyc',
    label: 'Moved from different county outside of NYC'
  }, {
    property: 'abroad',
    label: 'Moved from abroad'
  }];
  _exports.default = _default;
});