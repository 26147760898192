define("labs-nyc-factfinder/chart-config/acs/social/household-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'mrdfam',
    label: 'Married-couple family'
  }, {
    property: 'mhns',
    label: 'Male householder, no spouse present, family'
  }, {
    property: 'fhns',
    label: 'Female householder, no spouse present, family'
  }, {
    property: 'nfam1',
    label: 'Nonfamily households'
  }];
  _exports.default = _default;
});