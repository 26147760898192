define("labs-nyc-factfinder/templates/components/tab-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+V63Lawp",
    "block": "[[[8,[39,0],null,[[\"@href\",\"@class\"],[\"#top\",\"active\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,0,[\"tabName\"]]],[1,\"\\n\"],[41,[30,0,[\"tabNameSmall\"]],[[[1,\"    \"],[10,\"small\"],[12],[1,[30,0,[\"tabNameSmall\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,2],null,[[\"icon\",\"transform\",\"class\"],[\"caret-down\",\"grow-2 right-5\",\"light-gray tab-dropdown-trigger\"]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@_tooltipVariantClass\"],[\"tab-popover\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"ul\"],[14,0,\"menu vertical\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"dropdownItems\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[15,\"onClick\",[28,[37,6],[[30,0],\"sendAnalytics\",\"Selected Dropdown Item\",[30,2,[\"title\"]]],null]],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@href\",\"@afterScroll\"],[[30,2,[\"anchor\"]],[28,[37,6],[[30,0],\"closeTabDropdown\"],null]]],[[\"default\"],[[[[1,[30,2,[\"title\"]]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"popover\",\"item\"],false,[\"scroll-to\",\"if\",\"fa-icon\",\"ember-popover\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/tab-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});