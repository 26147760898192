define("labs-nyc-factfinder/adapters/acs-row", ["exports", "ember-data", "fetch", "labs-nyc-factfinder/config/environment"], function (_exports, _emberData, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    SupportServiceHost
  } = _environment.default;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    query(store, modelType, query) {
      const {
        geotype = 'cities',
        geoid = 'NYC',
        compareTo = 1
      } = query;
      const URL = `${SupportServiceHost}/survey/acs/${geotype}/${geoid}?compareTo=${compareTo}`;
      return (0, _fetch.default)(URL).then(d => d.json());
    },

    keyForAttribute(key) {
      return key;
    }

  });

  _exports.default = _default;
});