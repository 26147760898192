define("labs-nyc-factfinder/table-config/acs/social/ancestry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total population',
    highlight: true,
    data: 'pop_5'
  }, {
    title: 'Afghan',
    data: 'afg'
  }, {
    title: 'Albanian',
    data: 'alb'
  }, {
    title: 'Alsatian',
    data: 'alsatn'
  }, {
    title: 'American',
    data: 'amercn'
  }, {
    title: 'Arab',
    data: 'arab'
  }, {
    indent: 1,
    title: 'Egyptian',
    data: 'egyptn'
  }, {
    indent: 1,
    title: 'Iraqi',
    data: 'iraqi'
  }, {
    indent: 1,
    title: 'Jordanian',
    data: 'jrdnian'
  }, {
    indent: 1,
    title: 'Lebanese',
    data: 'lebanese'
  }, {
    indent: 1,
    title: 'Moroccan',
    data: 'moroccan'
  }, {
    indent: 1,
    title: 'Palestinian',
    data: 'palstnian'
  }, {
    indent: 1,
    title: 'Syrian',
    data: 'syrian'
  }, {
    indent: 1,
    title: 'Arab',
    data: 'arabsub'
  }, {
    indent: 1,
    title: 'Other Arab',
    data: 'oarab'
  }, {
    title: 'Armenian',
    data: 'armenian'
  }, {
    title: 'Assyrian/Chaldean/Syriac',
    data: 'asyrchlds'
  }, {
    title: 'Australian',
    data: 'austrln'
  }, {
    title: 'Austrian',
    data: 'austrian'
  }, {
    title: 'Basque',
    data: 'basque'
  }, {
    title: 'Belgian',
    data: 'belgian'
  }, {
    title: 'Brazilian',
    data: 'brzlian'
  }, {
    title: 'British',
    data: 'british'
  }, {
    title: 'Bulgarian',
    data: 'bulgrian'
  }, {
    title: 'Cajun',
    data: 'cajun'
  }, {
    title: 'Canadian',
    data: 'canadian'
  }, {
    title: 'Carpatho Rusyn',
    data: 'carprusn'
  }, {
    title: 'Celtic',
    data: 'celtic'
  }, {
    title: 'Croatian',
    data: 'croatian'
  }, {
    title: 'Cypriot',
    data: 'cypriot'
  }, {
    title: 'Czech',
    data: 'czech'
  }, {
    title: 'Czechoslovakian',
    data: 'czechslv'
  }, {
    title: 'Danish',
    data: 'danish'
  }, {
    title: 'Dutch',
    data: 'dutch'
  }, {
    title: 'Eastern European',
    data: 'eeurpn'
  }, {
    title: 'English',
    data: 'english'
  }, {
    title: 'Estonian',
    data: 'estonian'
  }, {
    title: 'European',
    data: 'european'
  }, {
    title: 'Finnish',
    data: 'finnish'
  }, {
    title: 'French (except Basque)',
    data: 'frnotbsq'
  }, {
    title: 'French Canadian',
    data: 'frcandian'
  }, {
    title: 'German',
    data: 'german'
  }, {
    title: 'German Russian',
    data: 'germrus'
  }, {
    title: 'Greek',
    data: 'greek'
  }, {
    title: 'Guyanese',
    data: 'guyanese'
  }, {
    title: 'Hungarian',
    data: 'hgrian'
  }, {
    title: 'Icelander',
    data: 'icelndr'
  }, {
    title: 'Iranian',
    data: 'iranian'
  }, {
    title: 'Irish',
    data: 'irish'
  }, {
    title: 'Israeli',
    data: 'israeli'
  }, {
    title: 'Italian',
    data: 'italian'
  }, {
    title: 'Latvian',
    data: 'latvian'
  }, {
    title: 'Lithuanian',
    data: 'lthian'
  }, {
    title: 'Luxemburger',
    data: 'luxmbgr'
  }, {
    title: 'Macedonian',
    data: 'macdnian'
  }, {
    title: 'Maltese',
    data: 'maltese'
  }, {
    title: 'New Zealander',
    data: 'nzealnd'
  }, {
    title: 'Northern European',
    data: 'neurpn'
  }, {
    title: 'Norwegian',
    data: 'norwgian'
  }, {
    title: 'Pennsylvania German',
    data: 'penngerm'
  }, {
    title: 'Polish',
    data: 'polish'
  }, {
    title: 'Portuguese',
    data: 'portgese'
  }, {
    title: 'Romanian',
    data: 'romanian'
  }, {
    title: 'Russian',
    data: 'russian'
  }, {
    title: 'Scandinavian',
    data: 'scandnvn'
  }, {
    title: 'Scotch-Irish',
    data: 'sctchirsh'
  }, {
    title: 'Scottish',
    data: 'scottish'
  }, {
    title: 'Serbian',
    data: 'serbian'
  }, {
    title: 'Slavic',
    data: 'slavic'
  }, {
    title: 'Slovak',
    data: 'slovak'
  }, {
    title: 'Slovene',
    data: 'slovene'
  }, {
    title: 'Soviet Union',
    data: 'svtunion'
  }, {
    title: 'Subsaharan African',
    data: 'subsaf'
  }, {
    indent: 1,
    title: 'Cabo Verdean',
    data: 'cvrdean'
  }, {
    indent: 1,
    title: 'Ethiopian',
    data: 'ethpian'
  }, {
    indent: 1,
    title: 'Ghanaian',
    data: 'ghanaian'
  }, {
    indent: 1,
    title: 'Kenyan',
    data: 'kenyan'
  }, {
    indent: 1,
    title: 'Liberian',
    data: 'liberian'
  }, {
    indent: 1,
    title: 'Nigerian',
    data: 'nigerian'
  }, {
    indent: 1,
    title: 'Senegalese',
    data: 'snglese'
  }, {
    indent: 1,
    title: 'Sierra Leonean',
    data: 'sleonean'
  }, {
    indent: 1,
    title: 'Somali',
    data: 'somali'
  }, {
    indent: 1,
    title: 'South African',
    data: 'safrican'
  }, {
    indent: 1,
    title: 'Sudanese',
    data: 'sudanese'
  }, {
    indent: 1,
    title: 'Ugandan',
    data: 'ugandan'
  }, {
    indent: 1,
    title: 'Zimbabwean',
    data: 'zmbwean'
  }, {
    indent: 1,
    title: 'African',
    data: 'african'
  }, {
    indent: 1,
    title: 'Other Subsaharan African',
    data: 'osubsafr'
  }, {
    title: 'Swedish',
    data: 'swedish'
  }, {
    title: 'Swiss',
    data: 'swiss'
  }, {
    title: 'Turkish',
    data: 'turkish'
  }, {
    title: 'Ukrainian',
    data: 'ukrnian'
  }, {
    title: 'Welsh',
    data: 'welsh'
  }, {
    title: 'West Indian (except Hispanic groups)',
    data: 'windnhsp'
  }, {
    indent: 1,
    title: 'Bahamian',
    data: 'bahamian'
  }, {
    indent: 1,
    title: 'Barbadian',
    data: 'brbdian'
  }, {
    indent: 1,
    title: 'Belizean',
    data: 'belizean'
  }, {
    indent: 1,
    title: 'Bermudan',
    data: 'bermudan'
  }, {
    indent: 1,
    title: 'British West Indian',
    data: 'brtwind'
  }, {
    indent: 1,
    title: 'Dutch West Indian',
    data: 'dchwind'
  }, {
    indent: 1,
    title: 'Haitian',
    data: 'haitian'
  }, {
    indent: 1,
    title: 'Jamaican',
    data: 'jamaican'
  }, {
    indent: 1,
    title: 'Trinidadian and Tobagonian',
    data: 'tandtob'
  }, {
    indent: 1,
    title: 'U.S. Virgin Islander',
    data: 'usvrgis'
  }, {
    indent: 1,
    title: 'West Indian',
    data: 'windsub'
  }, {
    indent: 1,
    title: 'Other West Indian',
    data: 'owind'
  }, {
    title: 'Yugoslavian',
    data: 'yugoslv'
  }, {
    title: 'Other groups',
    data: 'othr'
  }, {
    title: 'Unclassified or not reported',
    data: 'unclsnr'
  }];
  _exports.default = _default;
});