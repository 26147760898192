define("labs-nyc-factfinder/templates/components/labs-ui/icon-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mxgo8Ygs",
    "block": "[[[1,[28,[35,0],null,[[\"icon\",\"transform\",\"fixedWidth\"],[[30,0,[\"icon\"]],[30,0,[\"transform\"]],[30,0,[\"fixedWidth\"]]]]]],[1,[28,[35,1],null,[[\"text\",\"side\"],[[30,0,[\"tip\"]],[30,0,[\"side\"]]]]]],[1,\"\\n\"]],[],false,[\"fa-icon\",\"ember-tooltip\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/labs-ui/icon-tooltip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});