define("labs-nyc-factfinder/chart-config/acs/housing/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'vlu50',
    label: 'Less than $50,000'
  }, {
    property: 'vl50t99',
    label: '$50,000 to $99,999'
  }, {
    property: 'vl100t149',
    label: '$100,000 to $149,999'
  }, {
    property: 'vl150t199',
    label: '$150,000 to $199,999'
  }, {
    property: 'vl200t299',
    label: '$200,000 to $299,999'
  }, {
    property: 'vl300t499',
    label: '$300,000 to $499,999'
  }, {
    property: 'vl500t999',
    label: '$500,000 to $999,999'
  }, {
    property: 'vl1milpl',
    label: '$1,000,000 or more'
  }];
  _exports.default = _default;
});