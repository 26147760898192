define("labs-nyc-factfinder/services/map-mouseover", ["exports", "ember", "@ember/object"], function (_exports, _ember, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    get
  } = _ember.default;
  const {
    service
  } = _ember.default.inject;

  var _default = _ember.default.Service.extend({
    registeredLayers: service(),
    mapMouseover: service(),
    currentEvent: null,
    highlightedFeature: [],
    tooltipTemplate: '',
    highlightedLayer: null,
    mousePosition: (0, _object.computed)('currentEvent', function () {
      const event = this.get('currentEvent');

      if (event) {
        const {
          point: {
            x,
            y
          }
        } = event;
        return {
          x,
          y
        };
      }

      return null;
    }),
    hasMousePosition: (0, _object.computed)('mousePosition.x', 'mousePosition.y', function () {
      const x = this.get('mousePosition.x');
      const y = this.get('mousePosition.y');
      return !!(x && y);
    }),
    hoveredFeature: (0, _object.computed)('registeredLayers.visibleLayerIds.@each', 'currentEvent', 'mousePosition', function () {
      const layers = this.get('registeredLayers.visibleLayerIds.@each');
      const currentEvent = this.get('currentEvent');

      if (currentEvent) {
        const map = currentEvent.target;
        return map.queryRenderedFeatures(currentEvent.point, {
          layers
        }).objectAt(0) || {};
      }

      return {};
    }),
    tooltipText: (0, _object.computed)('hoveredFeature', function () {
      const feature = this.get('hoveredFeature');
      return get(feature, 'properties.bbl');
    }),
    highlightedFeatureSource: (0, _object.computed)('highlightedFeature', function () {
      const features = this.get('highlightedFeature');
      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features
        }
      };
    }),

    highlighter(e) {
      const map = e.target;
      this.set('currentEvent', e); // of all registered layers, we only want to query the ones
      // that exist on the map AND are highlightable

      const layers = this.get('registeredLayers.highlightableAndVisibleLayerIds');
      const features = map.queryRenderedFeatures(e.point, {
        layers
      });

      if (features.length > 0) {
        map.getCanvas().style.cursor = 'pointer';
        const thisFeature = features[0];
        const prevFeature = this.get('highlightedFeature')[0];

        if (!prevFeature || thisFeature.properties.geoid !== prevFeature.properties.geoid) {
          this.set('highlightedFeature', [thisFeature]); // move the layer

          const layerId = thisFeature.layer.id;
          this.set('highlightedLayer', layerId);
          const beforeLayerId = map.getStyle().layers.filter(d => d.id !== 'highlighted-feature') // highlighted-feature cannot be before itself!
          .reduce((acc, curr) => {
            if (curr.id === layerId) return 'hit';
            if (acc === 'hit') return curr;
            return acc;
          }).id;

          if (map.getLayer('highlighted-feature')) {
            map.moveLayer('highlighted-feature', beforeLayerId);
          }
        }

        this.set('tooltipTemplate', this.get('registeredLayers').getTooltipTemplate(thisFeature.layer.id));
      } else {
        map.getCanvas().style.cursor = '';
        this.set('highlightedFeature', []);
      }
    }

  });

  _exports.default = _default;
});