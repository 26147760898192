define("labs-nyc-factfinder/chart-config/acs/demographic/asian-subgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'asnseast',
    label: 'Southeast Asian'
  }, {
    property: 'asnsouth',
    label: 'South Asian'
  }, {
    property: 'asnchinot',
    label: 'Chinese, except Taiwanese'
  }];
  _exports.default = _default;
});