define("labs-nyc-factfinder/templates/components/page-load-animation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mphRYt/d",
    "block": "[[[10,0],[14,0,\"loading-wrapper\"],[14,\"aria-busy\",\"true\"],[14,\"aria-live\",\"polite\"],[14,\"aria-label\",\"Loading\"],[12],[1,\"\\n  \"],[10,\"lottie-player\"],[14,\"src\",\"/dcp-loading-screen.json\"],[14,\"background\",\"transparent\"],[14,\"speed\",\"1\"],[14,5,\"width:500px; height:500px;\"],[14,\"mode\",\"normal\"],[14,\"loop\",\"\"],[14,\"autoplay\",\"\"],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "labs-nyc-factfinder/templates/components/page-load-animation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});