define("labs-nyc-factfinder/templates/components/comparison-area-selector-trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GPZujDKV",
    "block": "[[[10,0],[12],[10,1],[14,5,\"color:#007B80; font-weight: 500;\"],[12],[1,\"Compare to: \"],[13],[1,[30,1]],[13]],[\"@placeholder\"],false,[]]",
    "moduleName": "labs-nyc-factfinder/templates/components/comparison-area-selector-trigger.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});