define("labs-nyc-factfinder/utils/fetch-selection-summary", ["exports", "labs-nyc-factfinder/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchSelectionSummary;
  const {
    SupportServiceHost
  } = _environment.default;

  const SELECTION_SUMMARY_API_URL = function () {
    let survey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'decennial';
    let summaryVars = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pop1,hunits';
    return `${SupportServiceHost}/summary/${survey}/${summaryVars}`;
  };

  const maxGeoidLength = 500;

  async function fetchSelectionSummary(survey, summaryVars, geoid) {
    try {
      let selectionResponse = {
        totals: {
          hunits: 0,
          pop1: 0
        }
      }; // to ensure we don't receive errors from the URLs being too long, we limit the selections to 500 geoids
      // The limit can be increased when indices are added to the geoids in the pff db

      if (geoid.length > maxGeoidLength) {
        selectionResponse = null;
        return selectionResponse;
      } else if (geoid.length) {
        let selectionResponse = null;
        const response = await fetch(SELECTION_SUMMARY_API_URL(survey, summaryVars), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(geoid)
        });
        selectionResponse = await response.json();
        return selectionResponse;
      }

      return null;
    } catch (e) {
      throw Error(`Error in fetching selection summary`, e);
    }
  }
});