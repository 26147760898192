define("labs-nyc-factfinder/utils/admin-boundary-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const adminBoundaryStyles = {
    paint: {
      lines: {
        'line-color': '#444',
        'line-opacity': 0.3,
        'line-width': {
          stops: [[11, 3], [16, 6]]
        }
      },
      labels: {
        'text-color': '#626262',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 2,
        'text-halo-blur': 2
      }
    },
    layout: {
      lines: {
        'line-join': 'round',
        'line-cap': 'round'
      }
    },
    labelLayout: field => ({
      'text-field': `{${field}}`,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-size': {
        stops: [[11, 12], [14, 16]]
      }
    })
  };
  var _default = adminBoundaryStyles;
  _exports.default = _default;
});