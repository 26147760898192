define("labs-nyc-factfinder/chart-config/acs/social/place-of-birth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'ntvnys',
    label: 'Native born, in New York State'
  }, {
    property: 'ntvnotnys',
    label: 'Native born, outside New York State'
  }, {
    property: 'fb1',
    label: 'Foreign-born'
  }];
  _exports.default = _default;
});