define("labs-nyc-factfinder/router", ["exports", "@ember/routing/router", "labs-nyc-factfinder/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _router.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    // eslint-disable-line
    this.route('explorer', {
      path: 'explorer/:geotype/:geoid'
    });
    this.route('about');
    this.route('features');
    this.route('data');
    this.route('tutorial');
  });
});