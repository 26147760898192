define("labs-nyc-factfinder/chart-config/census/index", ["exports", "labs-nyc-factfinder/chart-config/census/household-size", "labs-nyc-factfinder/chart-config/census/household-type", "labs-nyc-factfinder/chart-config/census/housing-tenure", "labs-nyc-factfinder/chart-config/census/race-group"], function (_exports, _householdSize, _householdType, _housingTenure, _raceGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    householdSize: _householdSize.default,
    householdType: _householdType.default,
    housingTenure: _housingTenure.default,
    raceGroup: _raceGroup.default
  };
  _exports.default = _default;
});