define("labs-nyc-factfinder/table-config/acs/housing/mortgage-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Owner-occupied units',
    highlight: true,
    data: 'oochu3'
  }, {
    title: 'Housing units with a mortgage',
    data: 'huwmrtg'
  }, {
    title: 'Housing units without a mortgage',
    data: 'hunomrtg1'
  }];
  _exports.default = _default;
});