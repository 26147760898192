define("labs-nyc-factfinder/table-config/acs/economic/industry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Civilian employed population 16 years and over',
    highlight: true,
    data: 'cvem16pl3'
  }, {
    title: 'Agriculture, forestry, fishing and hunting, and mining',
    data: 'agffhm'
  }, {
    title: 'Construction',
    data: 'constctn'
  }, {
    title: 'Manufacturing',
    data: 'mnfctrng'
  }, {
    title: 'Wholesale trade',
    data: 'whlsale'
  }, {
    title: 'Retail trade',
    data: 'retail'
  }, {
    title: 'Transportation and warehousing, and utilities',
    data: 'trwhut'
  }, {
    title: 'Information',
    data: 'info'
  }, {
    title: 'Finance and insurance, and real estate and rental and leasing',
    data: 'fire'
  }, {
    title: 'Professional, scientific, and management, and administrative and waste management services',
    data: 'prfsmgawm'
  }, {
    title: 'Educational services, and health care and social assistance',
    data: 'edhlthcsa'
  }, {
    title: 'Arts, entertainment, and recreation, and accommodation, and food services',
    data: 'artenrafs'
  }, {
    title: 'Other services, except public administration',
    data: 'othnotpa'
  }, {
    title: 'Public administration',
    data: 'pubadmin'
  }];
  _exports.default = _default;
});