define("labs-nyc-factfinder/helpers/numeral-format", ["exports", "@ember/component/helper", "numeral"], function (_exports, _helper, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numeralFormat = numeralFormat;
  _exports.default = void 0;

  function numeralFormat(params) {
    const [number, template] = params;
    return (0, _numeral.default)(number).format(template || '0,0');
  }

  var _default = (0, _helper.helper)(numeralFormat);

  _exports.default = _default;
});