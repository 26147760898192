define("labs-nyc-factfinder/initializers/resize", ["exports", "@ember/object", "ember-resize/services/resize", "labs-nyc-factfinder/config/environment"], function (_exports, _object, _resize, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    const resizeServiceDefaults = (0, _object.getWithDefault)(_environment.default, 'resizeServiceDefaults', {
      debounceTimeout: 200,
      heightSensitive: true,
      widthSensitive: true
    });
    const injectionFactories = (0, _object.getWithDefault)(resizeServiceDefaults, 'injectionFactories', ['view', 'component']) || [];
    application.unregister('config:resize-service');
    application.register('config:resize-service', resizeServiceDefaults, {
      instantiate: false
    });
    application.register('service:resize', _resize.default);
    const resizeService = application.resolveRegistration('service:resize');
    resizeService.prototype.resizeServiceDefaults = resizeServiceDefaults;
    injectionFactories.forEach(factory => {
      application.inject(factory, 'resizeService', 'service:resize');
    });
  }

  var _default = {
    initialize,
    name: 'resize'
  };
  _exports.default = _default;
});