define("labs-nyc-factfinder/table-config/acs/social/disability-status-of-the-civilian-noninstitutionalized-population", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total civilian noninstitutionalized population',
    highlight: true,
    data: 'cvnipop1'
  }, {
    title: 'With a disability',
    data: 'cvnid'
  }, {
    indent: 1,
    title: 'With a hearing difficulty',
    data: 'cvnid_hrg'
  }, {
    indent: 1,
    title: 'With a vision difficulty',
    data: 'cvnid_vsn'
  }, {
    indent: 1,
    title: 'With a cognitive difficulty',
    data: 'cvnid_cog'
  }, {
    indent: 1,
    title: 'With an ambulatory difficulty',
    data: 'cvnid_amb'
  }, {
    indent: 1,
    title: 'With a self-care difficulty',
    data: 'cvnid_scr'
  }, {
    indent: 1,
    title: 'With an independent living difficulty',
    data: 'cvnid_ild'
  }, {
    divider: true
  }, {
    title: 'Under 18 years',
    highlight: true,
    data: 'cvniu18_1'
  }, {
    title: 'With a disability',
    data: 'cvniu18d'
  }, {
    indent: 1,
    title: 'With a hearing difficulty',
    data: 'cu18dhrg'
  }, {
    indent: 1,
    title: 'With a vision difficulty',
    data: 'cu18dvsn'
  }, {
    indent: 1,
    title: 'With a cognitive difficulty',
    data: 'cu18dcog'
  }, {
    indent: 1,
    title: 'With an ambulatory difficulty',
    data: 'cu18damb'
  }, {
    indent: 1,
    title: 'With a self-care difficulty',
    data: 'cu18dscr'
  }, {
    divider: true
  }, {
    title: '18 to 64 years',
    highlight: true,
    data: 'cni1864_1'
  }, {
    title: 'With a disability',
    data: 'cni18t64d'
  }, {
    indent: 1,
    title: 'With a hearing difficulty',
    data: 'c1864dhrg'
  }, {
    indent: 1,
    title: 'With a vision difficulty',
    data: 'c1864dvsn'
  }, {
    indent: 1,
    title: 'With a cognitive difficulty',
    data: 'c1864dcog'
  }, {
    indent: 1,
    title: 'With an ambulatory difficulty',
    data: 'c1864damb'
  }, {
    indent: 1,
    title: 'With a self-care difficulty',
    data: 'c1864dscr'
  }, {
    indent: 1,
    title: 'With an independent living difficulty',
    data: 'c1864dild'
  }, {
    divider: true
  }, {
    title: '65 years and over',
    highlight: true,
    data: 'cvni65pl'
  }, {
    title: 'With a disability',
    data: 'cvni65pld'
  }, {
    indent: 1,
    title: 'With a hearing difficulty',
    data: 'c65pldhrg'
  }, {
    indent: 1,
    title: 'With a vision difficulty',
    data: 'c65pldvsn'
  }, {
    indent: 1,
    title: 'With a cognitive difficulty',
    data: 'c65pldcog'
  }, {
    indent: 1,
    title: 'With an ambulatory difficulty',
    data: 'c65pldamb'
  }, {
    indent: 1,
    title: 'With a self-care difficulty',
    data: 'c65pldscr'
  }, {
    indent: 1,
    title: 'With an independent living difficulty',
    data: 'c65pldild'
  }];
  _exports.default = _default;
});