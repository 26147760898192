define("labs-nyc-factfinder/chart-config/acs/demographic/hispanic-subgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'hsppr',
    label: 'Puerto Rican'
  }, {
    property: 'hspdom',
    label: 'Dominican'
  }, {
    property: 'hspme',
    label: 'Mexican'
  }, {
    property: 'hspcam',
    label: 'Central American'
  }, {
    property: 'hspsam',
    label: 'South American'
  }];
  _exports.default = _default;
});