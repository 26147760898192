define("labs-nyc-factfinder/components/data-table-row-change", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // state
    mode: 'current',
    reliability: false,
    comparison: true,
    isSelected: false,
    rowConfig: {},
    data: {},
    // configuration
    tagName: 'tr',
    classNameBindings: ['getClassNames'],
    // computeds
    getClassNames: (0, _object.computed)('rowConfig', 'isSelected', function () {
      const {
        rowConfig
      } = this.getProperties('rowConfig', 'isSelected');
      const classes = [];

      if (rowConfig.highlight) {
        classes.push('row-highlight');
      }

      if (rowConfig.indent) {
        classes.push(`row-indent-x${rowConfig.indent}`);
      }

      if (this.isSelected) {
        classes.push('is-selected');
      }

      return classes.join(' ');
    }),
    noPriorData: (0, _object.computed)('data.previous.sum', function () {
      const {
        'data.previous': previous
      } = this.getProperties('data.previous');

      if (previous && (typeof previous.sum === 'undefined' || previous.sum === null) || typeof previous === 'undefined') {
        return true;
      }

      return false;
    }),
    actions: {
      showData() {
        window.logModel = this.get('data');
      }

    },

    click() {
      this.set('isSelected', !this.isSelected);
    }

  });

  _exports.default = _default;
});