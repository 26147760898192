define("labs-nyc-factfinder/mirage/static/profile-all-nta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'QUlBTk5I',
    sum: 14761,
    m: 1517.06525897866,
    cv: 6.24773471282213,
    percent: 0.00176027217974548,
    percent_m: 0.000180852639801109,
    variable: 'aiannh',
    variablename: 'AIANNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 15202,
    previous_m: 1482.12718752474,
    previous_cv: 5.9267805009049,
    previous_percent: 0.00189562349391018,
    previous_percent_m: 0.000184736834448158,
    previous_is_reliable: true,
    comparison_sum: 15017,
    comparison_m: 875,
    comparison_cv: 3.5,
    comparison_percent: 0.002,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -441,
    change_m: 2120.893208061166,
    change_significant: false,
    change_percent: -0.0290093408762005,
    change_percent_m: 0.13755250297533175,
    change_percent_significant: false,
    change_percentage_point: -0.00013535131416470003,
    change_percentage_point_m: 0.0002585253862291976,
    change_percentage_point_significant: false,
    difference_sum: -256,
    difference_m: 1751.317218552935,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.10162222578368522
  }, {
    id: 'QXNuMVJj',
    sum: 1175781,
    m: 10960.7832749307,
    cv: 0.566694829797043,
    percent: 1,
    percent_m: 0,
    variable: 'asn1rc',
    variablename: 'Asn1Rc',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 1177700,
    comparison_m: 3785,
    comparison_cv: 0.2,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 1,
    change_percentage_point_m: 0,
    change_percentage_point_significant: true,
    difference_sum: -1919,
    difference_m: 11595.904233823276,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'QXNuMnBs',
    sum: 17122,
    m: 1402.6578342561,
    cv: 4.9800229792208,
    percent: 0.0145622356544288,
    percent_m: 0.00118520944292399,
    variable: 'asn2pl',
    variablename: 'Asn2pl',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 17175,
    comparison_m: 1660,
    comparison_cv: 5.9,
    comparison_percent: 0.015,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0145622356544288,
    change_percentage_point_m: 0.00118520944292399,
    change_percentage_point_significant: true,
    difference_sum: -53,
    difference_m: 2173.2576929577435,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.15507164226886214
  }, {
    id: 'QXNuQmh1dG4=',
    sum: 100,
    m: 91.5259526036195,
    cv: 55.6388769626866,
    percent: 0.0000850498519707326,
    percent_m: 0.0000778386494406817,
    variable: 'asnbhutn',
    variablename: 'AsnBhutn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 100,
    comparison_m: 90,
    comparison_cv: 54.7,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0000850498519707326,
    change_percentage_point_m: 0.0000778386494406817,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 128.3627671873741,
    difference_percent: 0.00850498519707326,
    difference_percent_m: 0.007783864944068171,
    percent_significant: false
  }, {
    id: 'QXNuQm5n',
    sum: 65684,
    m: 3573.85506141477,
    cv: 3.30758825336041,
    percent: 0.055864144768456,
    percent_m: 0.00299461369451909,
    variable: 'asnbng',
    variablename: 'AsnBng',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 65991,
    comparison_m: 3237,
    comparison_cv: 3,
    comparison_percent: 0.056,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.055864144768456,
    change_percentage_point_m: 0.00299461369451909,
    change_percentage_point_significant: true,
    difference_sum: -307,
    difference_m: 4821.888530441156,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.42388337050893227
  }, {
    id: 'QXNuQnVybQ==',
    sum: 4494,
    m: 792.963429169341,
    cv: 10.7264049353118,
    percent: 0.00382214034756473,
    percent_m: 0.000673472354310401,
    variable: 'asnburm',
    variablename: 'AsnBurm',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 4509,
    comparison_m: 620,
    comparison_cv: 8.4,
    comparison_percent: 0.004,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00382214034756473,
    change_percentage_point_m: 0.000673472354310401,
    change_percentage_point_significant: true,
    difference_sum: -15,
    difference_m: 1006.5738919721693,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.12056388397942373
  }, {
    id: 'QXNuQ2hpTm9U',
    sum: 564902,
    m: 7847.04619586249,
    cv: 0.844436884370852,
    percent: 0.480448314779708,
    percent_m: 0.00494785740317975,
    variable: 'asnchinot',
    variablename: 'AsnChiNoT',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 565173,
    comparison_m: 5911,
    comparison_cv: 0.6,
    comparison_percent: 0.48,
    comparison_percent_m: 0.005,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.480448314779708,
    change_percentage_point_m: 0.00494785740317975,
    change_percentage_point_significant: true,
    difference_sum: -271,
    difference_m: 9824.258496191964,
    significant: false,
    difference_percent: 0.04483147797080278,
    difference_percent_m: 0.7034294057131864,
    percent_significant: false
  }, {
    id: 'QXNuQ21i',
    sum: 2642,
    m: 1036.87029082716,
    cv: 23.857543005947,
    percent: 0.00224701708906676,
    percent_m: 0.000881607832602476,
    variable: 'asncmb',
    variablename: 'AsnCmb',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 2700,
    comparison_m: 1036,
    comparison_cv: 23.3,
    comparison_percent: 0.002,
    comparison_percent_m: 0.001,
    comparison_is_reliable: false,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00224701708906676,
    change_percentage_point_m: 0.000881607832602476,
    change_percentage_point_significant: false,
    difference_sum: -58,
    difference_m: 1465.7407683488918,
    significant: false,
    difference_percent: 0.024701708906676,
    difference_percent_m: 0.13331287899171765,
    percent_significant: false
  }, {
    id: 'QXNuRWFzdA==',
    sum: 686015,
    m: 8690.01329112908,
    cv: 0.770053548646924,
    percent: 0.583454741997022,
    percent_m: 0.00500413834506425,
    variable: 'asneast',
    variablename: 'AsnEast',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 686453,
    comparison_m: 6802,
    comparison_cv: 0.6,
    comparison_percent: 0.583,
    comparison_percent_m: 0.005,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.583454741997022,
    change_percentage_point_m: 0.00500413834506425,
    change_percentage_point_significant: true,
    difference_sum: -438,
    difference_m: 11035.557756633783,
    significant: false,
    difference_percent: 0.04547419970219835,
    difference_percent_m: 0.7073994668964797,
    percent_significant: false
  }, {
    id: 'QXNuRmxw',
    sum: 70963,
    m: 3010.98306205797,
    cv: 2.57935098594595,
    percent: 0.060353926453991,
    percent_m: 0.00249826639440059,
    variable: 'asnflp',
    variablename: 'AsnFlp',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 71450,
    comparison_m: 2932,
    comparison_cv: 2.5,
    comparison_percent: 0.061,
    comparison_percent_m: 0.002,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.060353926453991,
    change_percentage_point_m: 0.00249826639440059,
    change_percentage_point_significant: true,
    difference_sum: -487,
    difference_m: 4202.694730765011,
    significant: false,
    difference_percent: -0.06460735460089939,
    difference_percent_m: 0.32002085834194194,
    percent_significant: false
  }, {
    id: 'QXNuSG1uZw==',
    sum: 60,
    m: 56.7450438364444,
    cv: 57.4924456296296,
    percent: 0.0000510299111824396,
    percent_m: 0.0000482592312363935,
    variable: 'asnhmng',
    variablename: 'AsnHmng',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 60,
    comparison_m: 59,
    comparison_cv: 59.8,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0000510299111824396,
    change_percentage_point_m: 0.0000482592312363935,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 81.85963596303124,
    difference_percent: 0.00510299111824396,
    difference_percent_m: 0.00482592312363935,
    percent_significant: false
  }, {
    id: 'QXNuSW5k',
    sum: 228575,
    m: 5809.90731423488,
    cv: 1.54516397230093,
    percent: 0.194402699142102,
    percent_m: 0.00459699677293223,
    variable: 'asnind',
    variablename: 'AsnInd',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 228887,
    comparison_m: 4990,
    comparison_cv: 1.3,
    comparison_percent: 0.194,
    comparison_percent_m: 0.004,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.194402699142102,
    change_percentage_point_m: 0.00459699677293223,
    change_percentage_point_significant: true,
    difference_sum: -312,
    difference_m: 7658.663264565165,
    significant: false,
    difference_percent: 0.04026991421019943,
    difference_percent_m: 0.6093634328571853,
    percent_significant: false
  }, {
    id: 'QXNuSW5kbnNu',
    sum: 3765,
    m: 765.084962602194,
    cv: 12.3531803905302,
    percent: 0.00320212692669808,
    percent_m: 0.000650018577251485,
    variable: 'asnindnsn',
    variablename: 'AsnIndnsn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 3778,
    comparison_m: 675,
    comparison_cv: 10.9,
    comparison_percent: 0.003,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00320212692669808,
    change_percentage_point_m: 0.000650018577251485,
    change_percentage_point_significant: true,
    difference_sum: -13,
    difference_m: 1020.2842741118774,
    significant: false,
    difference_percent: 0.02021269266980802,
    difference_percent_m: 0.11926961686750089,
    percent_significant: false
  }, {
    id: 'QXNuSnBu',
    sum: 25189,
    m: 1604.09818901463,
    cv: 3.87127586332343,
    percent: 0.0214232072129078,
    percent_m: 0.00134958675907512,
    variable: 'asnjpn',
    variablename: 'AsnJpn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 25224,
    comparison_m: 1431,
    comparison_cv: 3.4,
    comparison_percent: 0.021,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0214232072129078,
    change_percentage_point_m: 0.00134958675907512,
    change_percentage_point_significant: true,
    difference_sum: -35,
    difference_m: 2149.626013984762,
    significant: false,
    difference_percent: 0.042320721290779684,
    difference_percent_m: 0.16796977169332838,
    percent_significant: false
  }, {
    id: 'QXNuS29y',
    sum: 85080,
    m: 3189.30039350325,
    cv: 2.27877813086575,
    percent: 0.0723604140566993,
    percent_m: 0.00262728159094456,
    variable: 'asnkor',
    variablename: 'AsnKor',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 85212,
    comparison_m: 2865,
    comparison_cv: 2,
    comparison_percent: 0.072,
    comparison_percent_m: 0.002,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0723604140566993,
    change_percentage_point_m: 0.00262728159094456,
    change_percentage_point_significant: true,
    difference_sum: -132,
    difference_m: 4287.174127557683,
    significant: false,
    difference_percent: 0.036041405669930526,
    difference_percent_m: 0.3301909834946463,
    percent_significant: false
  }, {
    id: 'QXNuTGFv',
    sum: 518,
    m: 181.991758055138,
    cv: 21.357777523458,
    percent: 0.000440558233208395,
    percent_m: 0.000154729225640068,
    variable: 'asnlao',
    variablename: 'AsnLao',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 538,
    comparison_m: 173,
    comparison_cv: 19.5,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.000440558233208395,
    change_percentage_point_m: 0.000154729225640068,
    change_percentage_point_significant: false,
    difference_sum: -20,
    difference_m: 251.09759058979418,
    significant: false,
    difference_percent: 0.0440558233208395,
    difference_percent_m: 0.015472922564006801,
    percent_significant: false
  }, {
    id: 'QXNuTWFsc24=',
    sum: 1750,
    m: 462.843386038949,
    cv: 16.077929172,
    percent: 0.00148837240948782,
    percent_m: 0.000393403017835357,
    variable: 'asnmalsn',
    variablename: 'AsnMalsn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 1750,
    comparison_m: 544,
    comparison_cv: 18.9,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00148837240948782,
    change_percentage_point_m: 0.000393403017835357,
    change_percentage_point_significant: true,
    difference_sum: 0,
    difference_m: 714.2548564763138,
    difference_percent: 0.048837240948781974,
    difference_percent_m: 0.0393403017835357,
    percent_significant: false
  }, {
    id: 'QXNuTWdvbA==',
    sum: 644,
    m: 263.258428165178,
    cv: 24.850235813889,
    percent: 0.000547721046691518,
    percent_m: 0.000223842677074166,
    variable: 'asnmgol',
    variablename: 'AsnMgol',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 644,
    comparison_m: 287,
    comparison_cv: 27.1,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.000547721046691518,
    change_percentage_point_m: 0.000223842677074166,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 389.4534632019597,
    difference_percent: 0,
    difference_percent_m: 0.0223842677074166
  }, {
    id: 'QXNuTmVwYWw=',
    sum: 8478,
    m: 1220.9471733044,
    cv: 8.75462522562884,
    percent: 0.00721052645007871,
    percent_m: 0.00103623595612069,
    variable: 'asnnepal',
    variablename: 'AsnNepal',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 8478,
    comparison_m: 1242,
    comparison_cv: 8.9,
    comparison_percent: 0.007,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00721052645007871,
    change_percentage_point_m: 0.00103623595612069,
    change_percentage_point_significant: true,
    difference_sum: 0,
    difference_m: 1741.6302707520917,
    difference_percent: 0.02105264500787085,
    difference_percent_m: 0.1440064219664304,
    percent_significant: false
  }, {
    id: 'QXNuTkg=',
    sum: 1165511,
    m: 10884.1506329157,
    cv: 0.567691334017587,
    percent: 0.138988997255425,
    percent_m: 0.00124495782704372,
    variable: 'asnnh',
    variablename: 'AsnNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 1010424,
    previous_m: 11104.1417498157,
    previous_cv: 0.668059957580641,
    previous_percent: 0.125995492251723,
    previous_percent_m: 0.00133786385474339,
    previous_is_reliable: true,
    comparison_sum: 1167421,
    comparison_m: 3568,
    comparison_cv: 0.2,
    comparison_percent: 0.138,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 155087,
    change_m: 15548.848799830788,
    change_significant: true,
    change_percent: 0.15348705098057844,
    change_percent_m: 0.01663498736264086,
    change_percent_significant: true,
    change_percentage_point: 0.012993505003702,
    change_percentage_point_m: 0.0018275118836675903,
    change_percentage_point_significant: true,
    difference_sum: -1910,
    difference_m: 11454.054260391787,
    significant: false,
    difference_percent: 0.09889972554249837,
    difference_percent_m: 0.15968468903177352,
    percent_significant: false
  }, {
    id: 'QXNuT0Fzbg==',
    sum: 10707,
    m: 1231.79503165096,
    cv: 6.99366367229554,
    percent: 0.00910628765050634,
    percent_m: 0.00104419487924606,
    variable: 'asnoasn',
    variablename: 'AsnOAsn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 10707,
    comparison_m: 1286,
    comparison_cv: 7.3,
    comparison_percent: 0.009,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00910628765050634,
    change_percentage_point_m: 0.00104419487924606,
    change_percentage_point_significant: true,
    difference_sum: 0,
    difference_m: 1780.762477142864,
    difference_percent: 0.010628765050634037,
    difference_percent_m: 0.14458018349150392,
    percent_significant: false
  }, {
    id: 'QXNuT2tpbnc=',
    sum: 0,
    m: 0,
    cv: null,
    percent: 0,
    percent_m: 0,
    variable: 'asnokinw',
    variablename: 'AsnOkinw',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 0,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: 0,
    difference_m: 0,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'QXNuUGFr',
    sum: 48937,
    m: 3010.50361235458,
    cv: 3.73969262801269,
    percent: 0.0416208460589174,
    percent_m: 0.00253086070481734,
    variable: 'asnpak',
    variablename: 'AsnPak',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 48973,
    comparison_m: 3097,
    comparison_cv: 3.8,
    comparison_percent: 0.042,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0416208460589174,
    change_percentage_point_m: 0.00253086070481734,
    change_percentage_point_significant: true,
    difference_sum: -36,
    difference_m: 4319.090297736316,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.39249529815258327
  }, {
    id: 'QXNuU0Vhc3Q=',
    sum: 105622,
    m: 3742.29301364818,
    cv: 2.15386014303124,
    percent: 0.0898313546485272,
    percent_m: 0.00307067381714458,
    variable: 'asnseast',
    variablename: 'AsnSEast',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 106360,
    comparison_m: 3653,
    comparison_cv: 2.1,
    comparison_percent: 0.09,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0898313546485272,
    change_percentage_point_m: 0.00307067381714458,
    change_percentage_point_significant: true,
    difference_sum: -738,
    difference_m: 5229.6430088486895,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.429290550691455
  }, {
    id: 'QXNuU291dGg=',
    sum: 356315,
    m: 7599.21719652755,
    cv: 1.29648903972643,
    percent: 0.303045380049516,
    percent_m: 0.00581301755310133,
    variable: 'asnsouth',
    variablename: 'AsnSouth',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 357005,
    comparison_m: 6872,
    comparison_cv: 1.2,
    comparison_percent: 0.303,
    comparison_percent_m: 0.006,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.303045380049516,
    change_percentage_point_m: 0.00581301755310133,
    change_percentage_point_significant: true,
    difference_sum: -690,
    difference_m: 10245.608132268188,
    significant: false,
    difference_percent: 0.0045380049515983956,
    difference_percent_m: 0.8354111147971648,
    percent_significant: false
  }, {
    id: 'QXNuU3JpTA==',
    sum: 4541,
    m: 811.617520757161,
    cv: 10.8651070490768,
    percent: 0.00386211377799097,
    percent_m: 0.000689339947314103,
    variable: 'asnsril',
    variablename: 'AsnSriL',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 4576,
    comparison_m: 837,
    comparison_cv: 11.1,
    comparison_percent: 0.004,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00386211377799097,
    change_percentage_point_m: 0.000689339947314103,
    change_percentage_point_significant: true,
    difference_sum: -35,
    difference_m: 1165.8867869566068,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.12145738194786723
  }, {
    id: 'QXNuVGhhaQ==',
    sum: 7325,
    m: 928.336684613939,
    cv: 7.70427863617282,
    percent: 0.00622990165685617,
    percent_m: 0.000787410165855128,
    variable: 'asnthai',
    variablename: 'AsnThai',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 7346,
    comparison_m: 955,
    comparison_cv: 7.9,
    comparison_percent: 0.006,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00622990165685617,
    change_percentage_point_m: 0.000787410165855128,
    change_percentage_point_significant: true,
    difference_sum: -21,
    difference_m: 1331.85359555771,
    significant: false,
    difference_percent: 0.02299016568561696,
    difference_percent_m: 0.127279800804841,
    percent_significant: false
  }, {
    id: 'QXNuVHdu',
    sum: 10200,
    m: 1056.63853800626,
    cv: 6.29738684073101,
    percent: 0.00867508490101473,
    percent_m: 0.000895023403167379,
    variable: 'asntwn',
    variablename: 'AsnTwn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 10200,
    comparison_m: 996,
    comparison_cv: 5.9,
    comparison_percent: 0.009,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.00867508490101473,
    change_percentage_point_m: 0.000895023403167379,
    change_percentage_point_significant: true,
    difference_sum: 0,
    difference_m: 1452.0678358809573,
    difference_percent: 0,
    difference_percent_m: 0.13420383348538584
  }, {
    id: 'QXNuVnRu',
    sum: 14105,
    m: 1239.20861843355,
    cv: 5.34078914624707,
    percent: 0.0119962816204718,
    percent_m: 0.00104799528394403,
    variable: 'asnvtn',
    variablename: 'AsnVtn',
    base: 'Asn1Rc',
    category: 'asian_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: null,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 14229,
    comparison_m: 1262,
    comparison_cv: 5.4,
    comparison_percent: 0.012,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: null,
    change_m: null,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: 0.0119962816204718,
    change_percentage_point_m: 0.00104799528394403,
    change_percentage_point_significant: true,
    difference_sum: -124,
    difference_m: 1768.6949991448464,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.14485489688543252
  }, {
    id: 'QmxOSA==',
    sum: 1848921,
    m: 13365.7170402489,
    cv: 0.439448518975072,
    percent: 0.220486701364894,
    percent_m: 0.00148368959372275,
    variable: 'blnh',
    variablename: 'BlNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 1868741,
    previous_m: 13253.5433752638,
    previous_cv: 0.431138679662758,
    previous_percent: 0.233023901041521,
    previous_percent_m: 0.00151515770967295,
    previous_is_reliable: true,
    comparison_sum: 1853055,
    comparison_m: 3317,
    comparison_cv: 0.1,
    comparison_percent: 0.219,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -19820,
    change_m: 18822.826674014686,
    change_significant: false,
    change_percent: -0.01060607114629582,
    change_percent_m: 0.010019642023864808,
    change_percent_significant: false,
    change_percentage_point: -0.012537199676626976,
    change_percentage_point_m: 0.0021206220067948364,
    change_percentage_point_significant: true,
    difference_sum: -4134,
    difference_m: 13771.16120739278,
    significant: false,
    difference_percent: 0.14867013648940386,
    difference_percent_m: 0.1789227433984059,
    percent_significant: false
  }, {
    id: 'RmVt',
    sum: 4390153,
    m: 14384.1851350711,
    cv: 0.199177238069936,
    percent: 0.523532564916074,
    percent_m: 0.00101508310994398,
    variable: 'fem',
    variablename: 'Fem',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 4214838,
    previous_m: 14360.7364017309,
    previous_cv: 0.207123759995081,
    previous_percent: 0.525572025774594,
    previous_percent_m: 0.000995428552390774,
    previous_is_reliable: true,
    comparison_sum: 4418616,
    comparison_m: 268,
    comparison_cv: 0,
    comparison_percent: 0.523,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 175315,
    change_m: 20325.73570624196,
    change_significant: true,
    change_percent: 0.041594718468420375,
    change_percent_m: 0.004923575600283674,
    change_percent_significant: true,
    change_percentage_point: -0.002039460858520026,
    change_percentage_point_m: 0.0014217143605550076,
    change_percentage_point_significant: false,
    difference_sum: -28463,
    difference_m: 14386.681549266335,
    significant: false,
    difference_percent: 0.053256491607399425,
    difference_percent_m: 0.10150831099439801,
    percent_significant: false
  }, {
    id: 'RlBvcDB0NQ==',
    sum: 268133,
    m: 4395.496786485,
    cv: 0.996533257994942,
    percent: 0.031975276767949,
    percent_m: 0.000517321720768136,
    variable: 'fpop0t5',
    variablename: 'FPop0t5',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 252095,
    previous_m: 3956.09403326058,
    previous_cv: 0.953973853095397,
    previous_percent: 0.031435153578298,
    previous_percent_m: 0.000485206916810025,
    previous_is_reliable: true,
    comparison_sum: 269674,
    comparison_m: 178,
    comparison_cv: 0,
    comparison_percent: 0.032,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 16038,
    change_m: 5913.634415484265,
    change_significant: false,
    change_percent: 0.06361887383724389,
    change_percent_m: 0.024137252653362733,
    change_percent_significant: false,
    change_percentage_point: 0.0005401231896509998,
    change_percentage_point_m: 0.000709258426032991,
    change_percentage_point_significant: false,
    difference_sum: -1541,
    difference_m: 4399.099453297226,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0517321720768136
  }, {
    id: 'RlBvcDEwdDE0',
    sum: 225389,
    m: 3588.86500164049,
    cv: 0.96796243475107,
    percent: 0.026877988369396,
    percent_m: 0.000422049045562274,
    variable: 'fpop10t14',
    variablename: 'FPop10t14',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 232134,
    previous_m: 3635.90868972256,
    previous_cv: 0.952156443578759,
    previous_percent: 0.0289461034163495,
    previous_percent_m: 0.000445908145874062,
    previous_is_reliable: true,
    comparison_sum: 227093,
    comparison_m: 2312,
    comparison_cv: 0.6,
    comparison_percent: 0.027,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -6745,
    change_m: 5108.794769806282,
    change_significant: false,
    change_percent: -0.029056493232357174,
    change_percent_m: 0.02168641166672107,
    change_percent_significant: false,
    change_percentage_point: -0.0020681150469535024,
    change_percentage_point_m: 0.0006139702528762042,
    change_percentage_point_significant: true,
    difference_sum: -1704,
    difference_m: 4269.109509019415,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0422049045562274
  }, {
    id: 'RlBvcDE1dDE5',
    sum: 225601,
    m: 3442.20263784688,
    cv: 0.927533299899786,
    percent: 0.0269032696987169,
    percent_m: 0.000404291213746274,
    variable: 'fpop15t19',
    variablename: 'FPop15t19',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 262990,
    previous_m: 4024.84571132857,
    previous_cv: 0.930345153098167,
    previous_percent: 0.0327937128445887,
    previous_percent_m: 0.00049321149148583,
    previous_is_reliable: true,
    comparison_sum: 226700,
    comparison_m: 355,
    comparison_cv: 0.1,
    comparison_percent: 0.027,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -37389,
    change_m: 5296.049659888019,
    change_significant: true,
    change_percent: -0.14216890376059926,
    change_percent_m: 0.018538324722540456,
    change_percent_significant: true,
    change_percentage_point: -0.005890443145871799,
    change_percentage_point_m: 0.0006377373760774198,
    change_percentage_point_significant: true,
    difference_sum: -1099,
    difference_m: 3460.4600850176002,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.040429121374627404
  }, {
    id: 'RlBvcDIwdDI0',
    sum: 292573,
    m: 4258.83211690717,
    cv: 0.884892212941212,
    percent: 0.0348897847330584,
    percent_m: 0.000499442326260034,
    variable: 'fpop20t24',
    variablename: 'FPop20t24',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 315103,
    previous_m: 4778.86147528886,
    previous_cv: 0.921947097838305,
    previous_percent: 0.0392919780161543,
    previous_percent_m: 0.000585419808303264,
    previous_is_reliable: true,
    comparison_sum: 293909,
    comparison_m: 3345,
    comparison_cv: 0.7,
    comparison_percent: 0.035,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -22530,
    change_m: 6401.184890315231,
    change_significant: true,
    change_percent: -0.07150043001812106,
    change_percent_m: 0.019518365730703723,
    change_percent_significant: true,
    change_percentage_point: -0.0044021932830959,
    change_percentage_point_m: 0.0007695186737265474,
    change_percentage_point_significant: true,
    difference_sum: -1336,
    difference_m: 5415.410972400895,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0499442326260034
  }, {
    id: 'RlBvcDI1dDI5',
    sum: 403634,
    m: 5189.99161849034,
    cv: 0.781651224234267,
    percent: 0.0481339815052766,
    percent_m: 0.000605716952549594,
    variable: 'fpop25t29',
    variablename: 'FPop25t29',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 375173,
    previous_m: 4835.87541196007,
    previous_cv: 0.783569684324043,
    previous_percent: 0.0467824465912881,
    previous_percent_m: 0.000588275455854402,
    previous_is_reliable: true,
    comparison_sum: 405287,
    comparison_m: 203,
    comparison_cv: 0,
    comparison_percent: 0.048,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 28461,
    change_m: 7093.779246635742,
    change_significant: true,
    change_percent: 0.07586100279071255,
    change_percent_m: 0.0195876816530864,
    change_percent_significant: true,
    change_percentage_point: 0.0013515349139884975,
    change_percentage_point_m: 0.0008443702023204463,
    change_percentage_point_significant: false,
    difference_sum: -1653,
    difference_m: 5193.960146169778,
    significant: false,
    difference_percent: 0.013398150527660135,
    difference_percent_m: 0.060571695254959396,
    percent_significant: false
  }, {
    id: 'RlBvcDMwdDM0',
    sum: 364850,
    m: 4537.53490344702,
    cv: 0.756031212968318,
    percent: 0.0435089292581897,
    percent_m: 0.000528765059495575,
    variable: 'fpop30t34',
    variablename: 'FPop30t34',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 331402,
    previous_m: 4200.43878660313,
    previous_cv: 0.770501755854845,
    previous_percent: 0.0413243926541784,
    previous_percent_m: 0.000510532176423835,
    previous_is_reliable: true,
    comparison_sum: 366861,
    comparison_m: 247,
    comparison_cv: 0,
    comparison_percent: 0.043,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 33448,
    change_m: 6183.2765585893,
    change_significant: true,
    change_percent: 0.10092878135919518,
    change_percent_m: 0.019549507901151248,
    change_percent_significant: true,
    change_percentage_point: 0.0021845366040113,
    change_percentage_point_m: 0.0007350072049357182,
    change_percentage_point_significant: false,
    difference_sum: -2011,
    difference_m: 4544.252633822195,
    significant: false,
    difference_percent: 0.05089292581897009,
    difference_percent_m: 0.0528765059495575,
    percent_significant: false
  }, {
    id: 'RlBvcDM1dDM5',
    sum: 313555,
    m: 3998.49396648288,
    cv: 0.775205440944758,
    percent: 0.0373919208265087,
    percent_m: 0.000466487188796607,
    variable: 'fpop35t39',
    variablename: 'FPop35t39',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 300774,
    previous_m: 3859.80206746408,
    previous_cv: 0.780115380830948,
    previous_percent: 0.0375052138374779,
    previous_percent_m: 0.000469432188216743,
    previous_is_reliable: true,
    comparison_sum: 315142,
    comparison_m: 3125,
    comparison_cv: 0.6,
    comparison_percent: 0.037,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 12781,
    change_m: 5557.519770545129,
    change_significant: false,
    change_percent: 0.04249369958839527,
    change_percent_m: 0.018860208674254995,
    change_percent_significant: false,
    change_percentage_point: -0.00011329301096920513,
    change_percentage_point_m: 0.0006617982144470631,
    change_percentage_point_significant: false,
    difference_sum: -1587,
    difference_m: 5074.798419641907,
    significant: false,
    difference_percent: 0.03919208265087004,
    difference_percent_m: 0.0466487188796607,
    percent_significant: false
  }, {
    id: 'RlBvcDQwdDQ0',
    sum: 279093,
    m: 3783.31957413063,
    cv: 0.82405876128045,
    percent: 0.033282273793219,
    percent_m: 0.000442520289370633,
    variable: 'fpop40t44',
    variablename: 'FPop40t44',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 300980,
    previous_m: 3838.48290343985,
    previous_cv: 0.775275519107662,
    previous_percent: 0.0375309011443945,
    previous_percent_m: 0.000466689617201796,
    previous_is_reliable: true,
    comparison_sum: 280418,
    comparison_m: 3132,
    comparison_cv: 0.7,
    comparison_percent: 0.033,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -21887,
    change_m: 5389.569370552716,
    change_significant: true,
    change_percent: -0.07271911754933882,
    change_percent_m: 0.017258513767458784,
    change_percent_significant: true,
    change_percentage_point: -0.004248627351175503,
    change_percentage_point_m: 0.0006431356041369718,
    change_percentage_point_significant: true,
    difference_sum: -1325,
    difference_m: 4911.510052926694,
    significant: false,
    difference_percent: 0.02822737932189945,
    difference_percent_m: 0.0442520289370633,
    percent_significant: false
  }, {
    id: 'RlBvcDQ1dDQ5',
    sum: 279877,
    m: 3740.30493409294,
    cv: 0.812407450870313,
    percent: 0.033375767011085,
    percent_m: 0.000437239601162832,
    variable: 'fpop45t49',
    variablename: 'FPop45t49',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 293322,
    previous_m: 3739.93890324428,
    previous_cv: 0.775093272962172,
    previous_percent: 0.0365759817445547,
    previous_percent_m: 0.000454702914115476,
    previous_is_reliable: true,
    comparison_sum: 281658,
    comparison_m: 193,
    comparison_cv: 0,
    comparison_percent: 0.033,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -13445,
    change_m: 5289.331148642522,
    change_significant: false,
    change_percent: -0.04583699824765957,
    change_percent_m: 0.01762411614707255,
    change_percent_significant: false,
    change_percentage_point: -0.0032002147334697015,
    change_percentage_point_m: 0.0006308194741208758,
    change_percentage_point_significant: true,
    difference_sum: -1781,
    difference_m: 3745.281030844013,
    significant: false,
    difference_percent: 0.037576701108499755,
    difference_percent_m: 0.0437239601162832,
    percent_significant: false
  }, {
    id: 'RlBvcDUwdDU0',
    sum: 282668,
    m: 3705.13980842829,
    cv: 0.796823349365464,
    percent: 0.033708598096626,
    percent_m: 0.000432780994296839,
    variable: 'fpop50t54',
    variablename: 'FPop50t54',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 278007,
    previous_m: 3522.17844522392,
    previous_cv: 0.770175539461318,
    previous_percent: 0.0346662676405398,
    previous_percent_m: 0.000428085131084281,
    previous_is_reliable: true,
    comparison_sum: 285202,
    comparison_m: 202,
    comparison_cv: 0,
    comparison_percent: 0.034,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 4661,
    change_m: 5112.123042337696,
    change_significant: false,
    change_percent: 0.0167657648908121,
    change_percent_m: 0.018535456899891372,
    change_percent_significant: false,
    change_percentage_point: -0.0009576695439138042,
    change_percentage_point_m: 0.0006087333311722027,
    change_percentage_point_significant: false,
    difference_sum: -2534,
    difference_m: 3710.642127718601,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0432780994296839
  }, {
    id: 'RlBvcDU1dDU5',
    sum: 270819,
    m: 3612.00304540292,
    cv: 0.810780090082244,
    percent: 0.0322955864403829,
    percent_m: 0.00042220669672246,
    variable: 'fpop55t59',
    variablename: 'FPop55t59',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 250322,
    previous_m: 3359.41840204521,
    previous_cv: 0.815829066762669,
    previous_percent: 0.0312140681648851,
    previous_percent_m: 0.000409469929025626,
    previous_is_reliable: true,
    comparison_sum: 272993,
    comparison_m: 2843,
    comparison_cv: 0.6,
    comparison_percent: 0.032,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 20497,
    change_m: 4932.773864672895,
    change_significant: true,
    change_percent: 0.08188253529454063,
    change_percent_m: 0.020469928206479162,
    change_percent_significant: true,
    change_percentage_point: 0.0010815182754977982,
    change_percentage_point_m: 0.0005881531412256016,
    change_percentage_point_significant: false,
    difference_sum: -2174,
    difference_m: 4596.652586393709,
    significant: false,
    difference_percent: 0.02955864403828956,
    difference_percent_m: 0.042220669672246,
    percent_significant: false
  }, {
    id: 'RlBvcDV0OQ==',
    sum: 232629,
    m: 3718.86716622146,
    cv: 0.971808984924011,
    percent: 0.027741369616016,
    percent_m: 0.000437386121600576,
    variable: 'fpop5t9',
    variablename: 'FPop5t9',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 228964,
    previous_m: 3619.36859134297,
    previous_cv: 0.960947602105875,
    previous_percent: 0.0285508181594296,
    previous_percent_m: 0.000444016285579178,
    previous_is_reliable: true,
    comparison_sum: 234049,
    comparison_m: 2327,
    comparison_cv: 0.6,
    comparison_percent: 0.028,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 3665,
    change_m: 5189.393220791813,
    change_significant: false,
    change_percent: 0.016006883178141543,
    change_percent_m: 0.022841865722142113,
    change_percent_significant: false,
    change_percentage_point: -0.0008094485434135996,
    change_percentage_point_m: 0.0006232632519476213,
    change_percentage_point_significant: false,
    difference_sum: -1420,
    difference_m: 4386.9011842073705,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0437386121600576
  }, {
    id: 'RlBvcDYwdDY0',
    sum: 254518,
    m: 3573.97649684494,
    cv: 0.853625318943037,
    percent: 0.0303516668684005,
    percent_m: 0.000418595316053971,
    variable: 'fpop60t64',
    variablename: 'FPop60t64',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 212168,
    previous_m: 3146.71288807861,
    previous_cv: 0.901594666816938,
    previous_percent: 0.0264564297760778,
    previous_percent_m: 0.000385160311993391,
    previous_is_reliable: true,
    comparison_sum: 256568,
    comparison_m: 2933,
    comparison_cv: 0.7,
    comparison_percent: 0.03,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 42350,
    change_m: 4761.83893049734,
    change_significant: true,
    change_percent: 0.19960597262546662,
    change_percent_m: 0.024500965945159808,
    change_percent_significant: true,
    change_percentage_point: 0.003895237092322701,
    change_percentage_point_m: 0.0005688325804286971,
    change_percentage_point_significant: true,
    difference_sum: -2050,
    difference_m: 4623.396695071712,
    significant: false,
    difference_percent: 0.03516668684004998,
    difference_percent_m: 0.0418595316053971,
    percent_significant: false
  }, {
    id: 'RlBvcDY1dDY5',
    sum: 212783,
    m: 3217.02253644577,
    cv: 0.919075678165543,
    percent: 0.0253747032872287,
    percent_m: 0.000377735600170262,
    variable: 'fpop65t69',
    variablename: 'FPop65t69',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 162160,
    previous_m: 2667.59535912027,
    previous_cv: 1.00002375196259,
    previous_percent: 0.0202206489785866,
    previous_percent_m: 0.000327670385456649,
    previous_is_reliable: true,
    comparison_sum: 214644,
    comparison_m: 2853,
    comparison_cv: 0.8,
    comparison_percent: 0.025,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 50623,
    change_m: 4179.150511766713,
    change_significant: true,
    change_percent: 0.3121793290577208,
    change_percent_m: 0.029317543084085237,
    change_percent_significant: true,
    change_percentage_point: 0.0051540543086421,
    change_percentage_point_m: 0.0005000520624308003,
    change_percentage_point_significant: true,
    difference_sum: -1861,
    difference_m: 4299.865463011602,
    significant: false,
    difference_percent: 0.03747032872287015,
    difference_percent_m: 0.0377735600170262,
    percent_significant: false
  }, {
    id: 'RlBvcDcwdDc0',
    sum: 159837,
    m: 2790.30876427681,
    cv: 1.0612288336664,
    percent: 0.0190608105408833,
    percent_m: 0.00032891828710728,
    variable: 'fpop70t74',
    variablename: 'FPop70t74',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 128606,
    previous_m: 2324.52360710749,
    previous_cv: 1.09877008820724,
    previous_percent: 0.0160366106471393,
    previous_percent_m: 0.000286277316793615,
    previous_is_reliable: true,
    comparison_sum: 161099,
    comparison_m: 2686,
    comparison_cv: 1,
    comparison_percent: 0.019,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 31231,
    change_m: 3631.6983630252107,
    change_significant: true,
    change_percent: 0.24284248013311976,
    change_percent_m: 0.031231015939113514,
    change_percent_significant: true,
    change_percentage_point: 0.0030241998937440007,
    change_percentage_point_m: 0.0004360526822577048,
    change_percentage_point_significant: true,
    difference_sum: -1262,
    difference_m: 3873.037438497074,
    significant: false,
    difference_percent: 0.006081054088330262,
    difference_percent_m: 0.032891828710728,
    percent_significant: false
  }, {
    id: 'RlBvcDc1dDc5',
    sum: 123427,
    m: 2506.98464295257,
    cv: 1.23474022901275,
    percent: 0.0147188614815694,
    percent_m: 0.000296423486542997,
    variable: 'fpop75t79',
    variablename: 'FPop75t79',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 107808,
    previous_m: 2111.98106052114,
    previous_cv: 1.19089405623571,
    previous_percent: 0.0134431902138842,
    previous_percent_m: 0.000260588017316833,
    previous_is_reliable: true,
    comparison_sum: 124380,
    comparison_m: 1845,
    comparison_cv: 0.9,
    comparison_percent: 0.015,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 15619,
    change_m: 3278.0231847868354,
    change_significant: true,
    change_percent: 0.14487793113683586,
    change_percent_m: 0.03230772307859309,
    change_percent_significant: true,
    change_percentage_point: 0.0012756712676852012,
    change_percentage_point_m: 0.00039468088140094193,
    change_percentage_point_significant: true,
    difference_sum: -953,
    difference_m: 3112.7153740745434,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0296423486542997
  }, {
    id: 'RlBvcDgwdDg0',
    sum: 90248,
    m: 2121.93967869023,
    cv: 1.42932024573841,
    percent: 0.0107622141912926,
    percent_m: 0.000251443018249023,
    variable: 'fpop80t84',
    variablename: 'FPop80t84',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 91038,
    previous_m: 2015.57981732305,
    previous_cv: 1.34589565312821,
    previous_percent: 0.0113520439178131,
    previous_percent_m: 0.000249269056803297,
    previous_is_reliable: true,
    comparison_sum: 91235,
    comparison_m: 1728,
    comparison_cv: 1.2,
    comparison_percent: 0.011,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -790,
    change_m: 2926.63458600489,
    change_significant: false,
    change_percent: -0.008677695028449658,
    change_percent_m: 0.03201538223576019,
    change_percent_significant: false,
    change_percentage_point: -0.0005898297265205001,
    change_percentage_point_m: 0.00035406024078648516,
    change_percentage_point_significant: false,
    difference_sum: -987,
    difference_m: 2736.532842850602,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0251443018249023
  }, {
    id: 'RlBvcDg1cGw=',
    sum: 110519,
    m: 2408.2335850162,
    cv: 1.32463357812175,
    percent: 0.0131795624302751,
    percent_m: 0.000285068159032536,
    variable: 'fpop85pl',
    variablename: 'FPop85pl',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 91792,
    previous_m: 2063.42918463416,
    previous_cv: 1.36652894149622,
    previous_percent: 0.0114460644489543,
    previous_percent_m: 0.000255250254943227,
    previous_is_reliable: true,
    comparison_sum: 111704,
    comparison_m: 1844,
    comparison_cv: 1,
    comparison_percent: 0.013,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 18727,
    change_m: 3171.3292165904145,
    change_significant: true,
    change_percent: 0.20401560048805997,
    change_percent_m: 0.037694289750493555,
    change_percent_significant: true,
    change_percentage_point: 0.0017334979813208012,
    change_percentage_point_m: 0.00038264415315379067,
    change_percentage_point_significant: true,
    difference_sum: -1185,
    difference_m: 3033.137814211543,
    significant: false,
    difference_percent: 0.017956243027509977,
    difference_percent_m: 0.028506815903253598,
    percent_significant: false
  }, {
    id: 'SHNwMQ==',
    sum: 2441307,
    m: 17075.4240357304,
    cv: 0.425190153561471,
    percent: 0.29112965207763,
    percent_m: 0.00188551066370957,
    variable: 'hsp1',
    variablename: 'Hsp1',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 2270124,
    previous_m: 16683.2615516271,
    previous_cv: 0.446750941152802,
    previous_percent: 0.283074621003114,
    previous_percent_m: 0.00191963136673627,
    previous_is_reliable: true,
    comparison_sum: 2457137,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 0.291,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 171183,
    change_m: 23872.606099879387,
    change_significant: true,
    change_percent: 0.07540689407274669,
    change_percent_m: 0.010910473517649651,
    change_percent_significant: true,
    change_percentage_point: 0.008055031074515995,
    change_percentage_point_m: 0.0026907499042312095,
    change_percentage_point_significant: false,
    difference_sum: -15830,
    difference_m: 17075.4240357304,
    significant: false,
    difference_percent: 0.012965207762999142,
    difference_percent_m: 0.18855106637095698,
    percent_significant: false
  }, {
    id: 'SHNwMg==',
    sum: 2441307,
    m: 17075.4240357304,
    cv: 0.425190153561471,
    percent: 1,
    percent_m: 0,
    variable: 'hsp2',
    variablename: 'Hsp2',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 2270124,
    previous_m: 16683.2615516271,
    previous_cv: 0.446750941152802,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 2457137,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 171183,
    change_m: 23872.606099879387,
    change_significant: true,
    change_percent: 0.07540689407274669,
    change_percent_m: 0.010910473517649651,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -15830,
    difference_m: 17075.4240357304,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'SHNwQWxsT3Ro',
    sum: 73130,
    m: 3356.62509077198,
    cv: 2.79023871863445,
    percent: 0.0299552657654281,
    percent_m: 0.00135887204538861,
    variable: 'hspalloth',
    variablename: 'HspAllOth',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 58778,
    previous_m: 2685.76115840556,
    previous_cv: 2.77770859039392,
    previous_percent: 0.0258919777069446,
    previous_percent_m: 0.00116768790975684,
    previous_is_reliable: true,
    comparison_sum: 73407,
    comparison_m: 3230,
    comparison_cv: 2.7,
    comparison_percent: 0.03,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 14352,
    change_m: 4298.865548025429,
    change_significant: true,
    change_percent: 0.2441729898941781,
    change_percent_m: 0.08058011629147677,
    change_percent_significant: false,
    change_percentage_point: 0.004063288058483501,
    change_percentage_point_m: 0.0017916551817609668,
    change_percentage_point_significant: false,
    difference_sum: -277,
    difference_m: 4658.307847276735,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.16871672222215037
  }, {
    id: 'SHNwQXJn',
    sum: 18293,
    m: 2114.15940742414,
    cv: 7.02565619192001,
    percent: 0.0074931174162037,
    percent_m: 0.000864407535736357,
    variable: 'hsparg',
    variablename: 'HspArg',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 14115,
    previous_m: 1308.96447621775,
    previous_cv: 5.63742887599472,
    previous_percent: 0.0062177220275192,
    previous_percent_m: 0.00057479140679136,
    previous_is_reliable: true,
    comparison_sum: 18356,
    comparison_m: 2145,
    comparison_cv: 7.1,
    comparison_percent: 0.007,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 4178,
    change_m: 2486.575556865305,
    change_significant: false,
    change_percent: 0.295997166135317,
    change_percent_m: 0.1920386561380474,
    change_percent_significant: false,
    change_percentage_point: 0.0012753953886844999,
    change_percentage_point_m: 0.00103806818136334,
    change_percentage_point_significant: false,
    difference_sum: -63,
    difference_m: 3011.7594525459685,
    significant: false,
    difference_percent: 0.04931174162036989,
    difference_percent_m: 0.13218170780549784,
    percent_significant: false
  }, {
    id: 'SHNwQm9s',
    sum: 3707,
    m: 619.719291292437,
    cv: 10.1626396670464,
    percent: 0.00151844892920063,
    percent_m: 0.000253625068608053,
    variable: 'hspbol',
    variablename: 'HspBol',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 4884,
    previous_m: 910.302696909111,
    previous_cv: 11.33037468552,
    previous_percent: 0.00215142432748167,
    previous_percent_m: 0.000400680669730107,
    previous_is_reliable: true,
    comparison_sum: 3802,
    comparison_m: 607,
    comparison_cv: 9.7,
    comparison_percent: 0.002,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -1177,
    change_m: 1101.2279509710972,
    change_significant: false,
    change_percent: -0.240990990990991,
    change_percent_m: 0.19003570454174037,
    change_percent_significant: false,
    change_percentage_point: -0.0006329753982810401,
    change_percentage_point_m: 0.000474205308407452,
    change_percentage_point_significant: false,
    difference_sum: -95,
    difference_m: 867.4681550351,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0253625068608053
  }, {
    id: 'SHNwQ0Ft',
    sum: 178227,
    m: 5879.24102244499,
    cv: 2.00531159733063,
    percent: 0.0730047470473808,
    percent_m: 0.00235347833850689,
    variable: 'hspcam',
    variablename: 'HspCAm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 155004,
    previous_m: 5718.16316661216,
    previous_cv: 2.24257892143117,
    previous_percent: 0.068279970609535,
    previous_percent_m: 0.0024683891678209,
    previous_is_reliable: true,
    comparison_sum: 179031,
    comparison_m: 5054,
    comparison_cv: 1.7,
    comparison_percent: 0.073,
    comparison_percent_m: 0.002,
    comparison_is_reliable: true,
    change_sum: 23223,
    change_m: 8201.394088811976,
    change_significant: false,
    change_percent: 0.1498219400789657,
    change_percent_m: 0.056902482789146946,
    change_percent_significant: false,
    change_percentage_point: 0.004724776437845801,
    change_percentage_point_m: 0.003410543266641945,
    change_percentage_point_significant: false,
    difference_sum: -804,
    difference_m: 7752.960144357768,
    significant: false,
    difference_percent: 0.00047470473808086666,
    difference_percent_m: 0.3088504539388141,
    percent_significant: false
  }, {
    id: 'SHNwQ2hs',
    sum: 7503,
    m: 992.400120918977,
    cv: 8.04055375555129,
    percent: 0.00307335374043494,
    percent_m: 0.000405934850088814,
    variable: 'hspchl',
    variablename: 'HspChl',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 7636,
    previous_m: 1038.81182126505,
    previous_cv: 8.26999145994613,
    previous_percent: 0.003363692908405,
    previous_percent_m: 0.000456933173398269,
    previous_is_reliable: true,
    comparison_sum: 7524,
    comparison_m: 974,
    comparison_cv: 7.9,
    comparison_percent: 0.003,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -133,
    change_m: 1436.658623334023,
    change_significant: false,
    change_percent: -0.017417496071241486,
    change_percent_m: 0.18643668561001844,
    change_percent_significant: false,
    change_percentage_point: -0.00029033916797005987,
    change_percentage_point_m: 0.0006112045708831377,
    change_percentage_point_significant: false,
    difference_sum: -21,
    difference_m: 1390.5157316621771,
    significant: false,
    difference_percent: 0.007335374043493992,
    difference_percent_m: 0.0405934850088814,
    percent_significant: false
  }, {
    id: 'SHNwQ29s',
    sum: 102206,
    m: 3904.73545838895,
    cv: 2.32246577187425,
    percent: 0.0418652795408361,
    percent_m: 0.001572411763563,
    variable: 'hspcol',
    variablename: 'HspCol',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 98700,
    previous_m: 3982.30335358822,
    previous_cv: 2.45273870565881,
    previous_percent: 0.043477801212621,
    previous_percent_m: 0.00172487805111164,
    previous_is_reliable: true,
    comparison_sum: 102532,
    comparison_m: 3800,
    comparison_cv: 2.3,
    comparison_percent: 0.042,
    comparison_percent_m: 0.002,
    comparison_is_reliable: true,
    change_sum: 3506,
    change_m: 5577.2483358731615,
    change_significant: false,
    change_percent: 0.03552178318135765,
    change_percent_m: 0.05753918027662903,
    change_percent_significant: false,
    change_percentage_point: -0.0016125216717848972,
    change_percentage_point_m: 0.0023340272160791084,
    change_percentage_point_significant: false,
    difference_sum: -326,
    difference_m: 5448.574033634852,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.2544106671150269
  }, {
    id: 'SHNwQ1I=',
    sum: 7343,
    m: 1012.31862573006,
    cv: 8.38065180228766,
    percent: 0.00300781507610473,
    percent_m: 0.000414128548929169,
    variable: 'hspcr',
    variablename: 'HspCR',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 6300,
    previous_m: 912.553560071955,
    previous_cv: 8.80545723039471,
    previous_percent: 0.00277517880080559,
    previous_percent_m: 0.000401466306059216,
    previous_is_reliable: true,
    comparison_sum: 7377,
    comparison_m: 1032,
    comparison_cv: 8.5,
    comparison_percent: 0.003,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 1043,
    change_m: 1362.9170921226266,
    change_significant: false,
    change_percent: 0.16555555555555557,
    change_percent_m: 0.2330741330254307,
    change_percent_significant: false,
    change_percentage_point: 0.0002326362752991403,
    change_percentage_point_m: 0.0005767821511966292,
    change_percentage_point_significant: false,
    difference_sum: -34,
    difference_m: 1445.6185527309744,
    significant: false,
    difference_percent: 0.0007815076104730201,
    difference_percent_m: 0.0414128548929169,
    percent_significant: false
  }, {
    id: 'SHNwQ3Vi',
    sum: 39732,
    m: 2115.98038743274,
    cv: 3.23746669162528,
    percent: 0.0162748888198002,
    percent_m: 0.000859233221460106,
    variable: 'hspcub',
    variablename: 'HspCub',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 41297,
    previous_m: 2157.47977974302,
    previous_cv: 3.17586715748397,
    previous_percent: 0.0181915172915665,
    previous_percent_m: 0.000940929570177267,
    previous_is_reliable: true,
    comparison_sum: 39929,
    comparison_m: 2284,
    comparison_cv: 3.5,
    comparison_percent: 0.016,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -1565,
    change_m: 3021.9351415938763,
    change_significant: false,
    change_percent: -0.03789621522144466,
    change_percent_m: 0.07177557945955505,
    change_percent_significant: false,
    change_percentage_point: -0.0019166284717662997,
    change_percentage_point_m: 0.0012742174794338243,
    change_percentage_point_significant: false,
    difference_sum: -197,
    difference_m: 3113.523566636361,
    significant: false,
    difference_percent: 0.027488881980020086,
    difference_percent_m: 0.13184391259594475,
    percent_significant: false
  }, {
    id: 'SHNwRG9t',
    sum: 698563,
    m: 10733.0025621911,
    cv: 0.934006183887957,
    percent: 0.286143037315667,
    percent_m: 0.00391444842620607,
    variable: 'hspdom',
    variablename: 'HspDom',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 570945,
    previous_m: 9551.2220684057,
    previous_cv: 1.01694804636995,
    previous_percent: 0.251503882607294,
    previous_percent_m: 0.00377962738052929,
    previous_is_reliable: true,
    comparison_sum: 701188,
    comparison_m: 8731,
    comparison_cv: 0.8,
    comparison_percent: 0.285,
    comparison_percent_m: 0.004,
    comparison_is_reliable: true,
    change_sum: 127618,
    change_m: 14367.434948521632,
    change_significant: true,
    change_percent: 0.22352065435374685,
    change_percent_m: 0.027790822944462563,
    change_percent_significant: true,
    change_percentage_point: 0.03463915470837298,
    change_percentage_point_m: 0.005441368358884912,
    change_percentage_point_significant: true,
    difference_sum: -2625,
    difference_m: 13835.740131991519,
    significant: false,
    difference_percent: 0.11430373156670015,
    difference_percent_m: 0.5596687098760049,
    percent_significant: false
  }, {
    id: 'SHNwRWM=',
    sum: 195705,
    m: 6457.60551597881,
    cv: 2.00587417712669,
    percent: 0.0801640268921524,
    percent_m: 0.0025850335552457,
    variable: 'hspec',
    variablename: 'HspEc',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 176086,
    previous_m: 6307.11423711352,
    previous_cv: 2.17740876517457,
    previous_percent: 0.0775666879870879,
    previous_percent_m: 0.00271920460214978,
    previous_is_reliable: true,
    comparison_sum: 196728,
    comparison_m: 6097,
    comparison_cv: 1.9,
    comparison_percent: 0.08,
    comparison_percent_m: 0.002,
    comparison_is_reliable: true,
    change_sum: 19619,
    change_m: 9026.64716270665,
    change_significant: false,
    change_percent: 0.11141714843883102,
    change_percent_m: 0.05412651189672949,
    change_percent_significant: false,
    change_percentage_point: 0.0025973389050645,
    change_percentage_point_m: 0.0037518624908302236,
    change_percentage_point_significant: false,
    difference_sum: -1023,
    difference_m: 8881.107926379453,
    significant: false,
    difference_percent: 0.016402689215240684,
    difference_percent_m: 0.32683938688209263,
    percent_significant: false
  }, {
    id: 'SHNwR3VhdG0=',
    sum: 37343,
    m: 2927.59730837422,
    cv: 4.76580460162694,
    percent: 0.0152963146380197,
    percent_m: 0.00119441049675632,
    variable: 'hspguatm',
    variablename: 'HspGuatm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 27801,
    previous_m: 2405.46731426557,
    previous_cv: 5.25984734595073,
    previous_percent: 0.0122464675938407,
    previous_percent_m: 0.00105579030825643,
    previous_is_reliable: true,
    comparison_sum: 37583,
    comparison_m: 2564,
    comparison_cv: 4.1,
    comparison_percent: 0.015,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 9542,
    change_m: 3789.0762726553808,
    change_significant: false,
    change_percent: 0.3432250638466242,
    change_percent_m: 0.15683355110351915,
    change_percent_significant: false,
    change_percentage_point: 0.0030498470441790004,
    change_percentage_point_m: 0.0015941485532314378,
    change_percentage_point_significant: false,
    difference_sum: -240,
    difference_m: 3891.6477230088512,
    significant: false,
    difference_percent: 0.029631463801969904,
    difference_percent_m: 0.15577600697031874,
    percent_significant: false
  }, {
    id: 'SHNwSG5k',
    sum: 49968,
    m: 3231.00835653515,
    cv: 3.93079334486552,
    percent: 0.0204677248703256,
    percent_m: 0.00131570935763806,
    variable: 'hsphnd',
    variablename: 'HspHnd',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 44500,
    previous_m: 3086.03985716322,
    previous_cv: 4.21575746342437,
    previous_percent: 0.0196024534342617,
    previous_percent_m: 0.00135176000128747,
    previous_is_reliable: true,
    comparison_sum: 50108,
    comparison_m: 3416,
    comparison_cv: 4.1,
    comparison_percent: 0.02,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 5468,
    change_m: 4468.003692925953,
    change_significant: false,
    change_percent: 0.12287640449438202,
    change_percent_m: 0.10646875256079227,
    change_percent_significant: false,
    change_percentage_point: 0.0008652714360639011,
    change_percentage_point_m: 0.0018863579233160013,
    change_percentage_point_significant: false,
    difference_sum: -140,
    difference_m: 4701.964589403026,
    significant: false,
    difference_percent: 0.04677248703255987,
    difference_percent_m: 0.16526013172499762,
    percent_significant: false
  }, {
    id: 'SHNwTWU=',
    sum: 338987,
    m: 9318.80383954937,
    cv: 1.67113380342922,
    percent: 0.138854720033163,
    percent_m: 0.00369151764532807,
    variable: 'hspme',
    variablename: 'HspMe',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 291468,
    previous_m: 9048.13428282317,
    previous_cv: 1.88713189175598,
    previous_percent: 0.128392986462414,
    previous_percent_m: 0.00387244637466018,
    previous_is_reliable: true,
    comparison_sum: 339425,
    comparison_m: 7811,
    comparison_cv: 1.4,
    comparison_percent: 0.138,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: 47519,
    change_m: 12988.796672517436,
    change_significant: true,
    change_percent: 0.16303333470569667,
    change_percent_m: 0.048225876660344,
    change_percent_significant: true,
    change_percentage_point: 0.010461733570748977,
    change_percentage_point_m: 0.005350060135212245,
    change_percentage_point_significant: false,
    difference_sum: -438,
    difference_m: 12159.433621678276,
    significant: false,
    difference_percent: 0.08547200331629767,
    difference_percent_m: 0.47568164275877306,
    percent_significant: false
  }, {
    id: 'SHNwTmlj',
    sum: 12458,
    m: 1513.20884216291,
    cv: 7.38388019447669,
    percent: 0.00510300425141123,
    percent_m: 0.000618807040366663,
    variable: 'hspnic',
    variablename: 'HspNic',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 9970,
    previous_m: 1474.41547740113,
    previous_cv: 8.98998196657528,
    previous_percent: 0.00439183057841774,
    previous_percent_m: 0.000648684300761484,
    previous_is_reliable: true,
    comparison_sum: 12560,
    comparison_m: 1653,
    comparison_cv: 8,
    comparison_percent: 0.005,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 2488,
    change_m: 2112.7475002943484,
    change_significant: false,
    change_percent: 0.24954864593781345,
    change_percent_m: 0.23913024184203657,
    change_percent_significant: false,
    change_percentage_point: 0.0007111736729934898,
    change_percentage_point_m: 0.000896500683358225,
    change_percentage_point_significant: false,
    difference_sum: -102,
    difference_m: 2241.0287816090213,
    significant: false,
    difference_percent: 0.010300425141122949,
    difference_percent_m: 0.11759771057326537,
    percent_significant: false
  }, {
    id: 'SHNwT0NBbQ==',
    sum: 1606,
    m: 479.945830276709,
    cv: 18.1668980788877,
    percent: 0.000657844343214516,
    percent_m: 0.000196539951882481,
    variable: 'hspocam',
    variablename: 'HspOCAm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 2907,
    previous_m: 813.079946868695,
    previous_cv: 17.0028732002868,
    previous_percent: 0.00128054678951458,
    previous_percent_m: 0.000358041777700133,
    previous_is_reliable: true,
    comparison_sum: 1606,
    comparison_m: 513,
    comparison_cv: 19.4,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -1301,
    change_m: 944.1647102068575,
    change_significant: false,
    change_percent: -0.4475404196766426,
    change_percent_m: 0.22613026800018787,
    change_percent_significant: false,
    change_percentage_point: -0.000622702446300064,
    change_percentage_point_m: 0.00040843832736979935,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 702.5076512038852,
    difference_percent: 0,
    difference_percent_m: 0.0196539951882481
  }, {
    id: 'SHNwT1NBbQ==',
    sum: 2811,
    m: 646.308749747363,
    cv: 13.9769781924325,
    percent: 0.00115143240895143,
    percent_m: 0.000264616300349715,
    variable: 'hsposam',
    variablename: 'HspOSAm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 4362,
    previous_m: 806.982651610306,
    previous_cv: 11.2463769249251,
    previous_percent: 0.00192148094112921,
    previous_percent_m: 0.000355198963955087,
    previous_is_reliable: true,
    comparison_sum: 2811,
    comparison_m: 708,
    comparison_cv: 15.3,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -1551,
    change_m: 1033.8936115481129,
    change_significant: false,
    change_percent: -0.35557083906464926,
    change_percent_m: 0.19017744736278236,
    change_percent_significant: false,
    change_percentage_point: -0.0007700485321777801,
    change_percentage_point_m: 0.00044293124794434835,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 958.6339238729242,
    difference_percent: 0.01514324089514299,
    difference_percent_m: 0.0264616300349715,
    percent_significant: false
  }, {
    id: 'SHNwT3Ro',
    sum: 107922,
    m: 4092.90825697327,
    cv: 2.30545220234949,
    percent: 0.044206648324033,
    percent_m: 0.00164776431138895,
    variable: 'hspoth',
    variablename: 'HspOth',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 89947,
    previous_m: 3337.23298557353,
    previous_cv: 2.25545383519064,
    previous_percent: 0.0396220646977874,
    previous_percent_m: 0.00144093951251287,
    previous_is_reliable: true,
    comparison_sum: 108479,
    comparison_m: 3640,
    comparison_cv: 2,
    comparison_percent: 0.044,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 17975,
    change_m: 5281.005775418163,
    change_significant: true,
    change_percent: 0.19983990572225865,
    change_percent_m: 0.06365777070021227,
    change_percent_significant: true,
    change_percentage_point: 0.0045845836262456,
    change_percentage_point_m: 0.002188934422180785,
    change_percentage_point_significant: false,
    difference_sum: -557,
    difference_m: 5477.36232140982,
    significant: false,
    difference_percent: 0.02066483240330097,
    difference_percent_m: 0.1927466530419426,
    percent_significant: false
  }, {
    id: 'SHNwUGFu',
    sum: 22739,
    m: 1672.18330334925,
    cv: 4.47040241201296,
    percent: 0.0093142730512795,
    percent_m: 0.00068184892194825,
    variable: 'hsppan',
    variablename: 'HspPan',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 23380,
    previous_m: 1971.0461689164,
    previous_cv: 5.12491171088061,
    previous_percent: 0.0102989968829897,
    previous_percent_m: 0.000864949609580706,
    previous_is_reliable: true,
    comparison_sum: 22824,
    comparison_m: 1473,
    comparison_cv: 3.9,
    comparison_percent: 0.009,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -641,
    change_m: 2584.805601974746,
    change_significant: false,
    change_percent: -0.027416595380667236,
    change_percent_m: 0.10880402285543617,
    change_percent_significant: false,
    change_percentage_point: -0.0009847238317102006,
    change_percentage_point_m: 0.0011013881148241099,
    change_percentage_point_significant: false,
    difference_sum: -85,
    difference_m: 2228.435774259606,
    significant: false,
    difference_percent: 0.03142730512795,
    difference_percent_m: 0.12103379496495972,
    percent_significant: false
  }, {
    id: 'SHNwUGVydQ==',
    sum: 40105,
    m: 2559.04767442891,
    cv: 3.87894796588879,
    percent: 0.01642767583102,
    percent_m: 0.00104191206344599,
    variable: 'hspperu',
    variablename: 'HspPeru',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 39800,
    previous_m: 2720.68447270168,
    previous_cv: 4.15555661697802,
    previous_percent: 0.0175320819479465,
    previous_percent_m: 0.00119152800066071,
    previous_is_reliable: true,
    comparison_sum: 40211,
    comparison_m: 2081,
    comparison_cv: 3.1,
    comparison_percent: 0.016,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 305,
    change_m: 3735.083533202441,
    change_significant: false,
    change_percent: 0.007663316582914573,
    change_percent_m: 0.09422858710479615,
    change_percent_significant: false,
    change_percentage_point: -0.001104406116926502,
    change_percentage_point_m: 0.0015828201806626014,
    change_percentage_point_significant: false,
    difference_sum: -106,
    difference_m: 3298.376267195726,
    significant: false,
    difference_percent: 0.042767583101999795,
    difference_percent_m: 0.14441539903882417,
    percent_significant: false
  }, {
    id: 'SHNwUFI=',
    sum: 687513,
    m: 9801.64328059331,
    cv: 0.866666632235115,
    percent: 0.281616773310362,
    percent_m: 0.00349852829547627,
    variable: 'hsppr',
    variablename: 'HspPR',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 758862,
    previous_m: 10339.2095926139,
    previous_cv: 0.828244634057587,
    previous_percent: 0.334282180180466,
    previous_percent_m: 0.00383510402292657,
    previous_is_reliable: true,
    comparison_sum: 696879,
    comparison_m: 8571,
    comparison_cv: 0.7,
    comparison_percent: 0.284,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: -71349,
    change_m: 14246.805466489646,
    change_significant: true,
    change_percent: -0.0940210473050436,
    change_percent_m: 0.017866008631585654,
    change_percent_significant: true,
    change_percentage_point: -0.052665406870104015,
    change_percentage_point_m: 0.005191119638470651,
    change_percentage_point_significant: true,
    difference_sum: -9366,
    difference_m: 13020.531939978488,
    significant: false,
    difference_percent: -0.23832266896380006,
    difference_percent_m: 0.4608654926792426,
    percent_significant: false
  }, {
    id: 'SHNwUHJn',
    sum: 3666,
    m: 707.322415875533,
    cv: 11.728947941497,
    percent: 0.00150165464646601,
    percent_m: 0.000289540600845772,
    variable: 'hspprg',
    variablename: 'HspPrg',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 3489,
    previous_m: 809.347267864666,
    previous_cv: 14.1015883678651,
    previous_percent: 0.00153692045016043,
    previous_percent_m: 0.000356342210128616,
    previous_is_reliable: true,
    comparison_sum: 3666,
    comparison_m: 808,
    comparison_cv: 13.4,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 177,
    change_m: 1074.871155069295,
    change_significant: false,
    change_percent: 0.05073086844368014,
    change_percent_m: 0.3170299135917467,
    change_percent_significant: false,
    change_percentage_point: -0.00003526580369442008,
    change_percentage_point_m: 0.00045914434577535353,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 1073.857066838972,
    difference_percent: 0.05016546464660099,
    difference_percent_m: 0.028954060084577196,
    percent_significant: false
  }, {
    id: 'SHNwU2Fsdg==',
    sum: 46770,
    m: 3103.75079540868,
    cv: 4.03416420575718,
    percent: 0.0191577708170255,
    percent_m: 0.00126426684224285,
    variable: 'hspsalv',
    variablename: 'HspSalv',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 40146,
    previous_m: 3188.67307825685,
    previous_cv: 4.82838411569329,
    previous_percent: 0.017684496529705,
    previous_percent_m: 0.00139859960759029,
    previous_is_reliable: true,
    comparison_sum: 46973,
    comparison_m: 2844,
    comparison_cv: 3.7,
    comparison_percent: 0.019,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 6624,
    change_m: 4449.8207829080075,
    change_significant: false,
    change_percent: 0.16499775818263338,
    change_percent_m: 0.12057895982896397,
    change_percent_significant: false,
    change_percentage_point: 0.0014732742873205008,
    change_percentage_point_m: 0.0018853253063454117,
    change_percentage_point_significant: false,
    difference_sum: -203,
    difference_m: 4209.703671281391,
    significant: false,
    difference_percent: 0.01577708170255021,
    difference_percent_m: 0.16119462299948803,
    percent_significant: false
  }, {
    id: 'SHNwU0Ft',
    sum: 390363,
    m: 8308.28989624219,
    cv: 1.29382962928616,
    percent: 0.159899185149594,
    percent_m: 0.00321419611105115,
    variable: 'hspsam',
    variablename: 'HspSAm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 362601,
    previous_m: 8105.19407540621,
    previous_cv: 1.35884061287831,
    previous_percent: 0.159727398150938,
    previous_percent_m: 0.00337189347481753,
    previous_is_reliable: true,
    comparison_sum: 392206,
    comparison_m: 7423,
    comparison_cv: 1.2,
    comparison_percent: 0.16,
    comparison_percent_m: 0.003,
    comparison_is_reliable: true,
    change_sum: 27762,
    change_m: 11606.974282731913,
    change_significant: false,
    change_percent: 0.07656349541231271,
    change_percent_m: 0.03322800027420506,
    change_percent_significant: false,
    change_percentage_point: 0.00017178699865599834,
    change_percentage_point_m: 0.004658403400931844,
    change_percentage_point_significant: false,
    difference_sum: -1843,
    difference_m: 11141.301988546942,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.4396709751654791
  }, {
    id: 'SHNwU3BBbQ==',
    sum: 601,
    m: 244.757022371167,
    cv: 24.7568158814506,
    percent: 0.000246179607890364,
    percent_m: 0.000100241764679548,
    variable: 'hspspam',
    variablename: 'HspSpAm',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: false,
    previous_sum: 703,
    previous_m: 263.378055274163,
    previous_cv: 22.774998618527,
    previous_percent: 0.00030967471380418,
    previous_percent_m: 0.000115996914217641,
    previous_is_reliable: false,
    comparison_sum: 601,
    comparison_m: 224,
    comparison_cv: 22.7,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -102,
    change_m: 359.54693713060607,
    change_significant: false,
    change_percent: -0.14509246088193456,
    change_percent_m: 0.47307675980772024,
    change_percent_significant: false,
    change_percentage_point: -0.00006349510591381602,
    change_percentage_point_m: 0.00015330915006640875,
    change_percentage_point_significant: false,
    difference_sum: 0,
    difference_m: 331.786075657192,
    difference_percent: 0.0246179607890364,
    difference_percent_m: 0.0100241764679548,
    percent_significant: false
  }, {
    id: 'SHNwU3BucmQ=',
    sum: 24711,
    m: 1815.02066103943,
    cv: 4.46503996174975,
    percent: 0.0101220370891494,
    percent_m: 0.000740084117559954,
    variable: 'hspspnrd',
    variablename: 'HspSpnrd',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 16187,
    previous_m: 1333.57639451214,
    previous_cv: 5.00824574229476,
    previous_percent: 0.00713044749978415,
    previous_percent_m: 0.00058510461880115,
    previous_is_reliable: true,
    comparison_sum: 24966,
    comparison_m: 1807,
    comparison_cv: 4.4,
    comparison_percent: 0.01,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 8524,
    change_m: 2252.271298045599,
    change_significant: true,
    change_percent: 0.5265954160746278,
    change_percent_m: 0.1684954957144747,
    change_percent_significant: true,
    change_percentage_point: 0.0029915895893652504,
    change_percentage_point_m: 0.0009434362278431621,
    change_percentage_point_significant: true,
    difference_sum: -255,
    difference_m: 2561.1616505015863,
    significant: false,
    difference_percent: 0.012203708914940048,
    difference_percent_m: 0.1244075761786434,
    percent_significant: false
  }, {
    id: 'SHNwU3Buc2g=',
    sum: 9480,
    m: 1445.88796246459,
    cv: 9.27172202213964,
    percent: 0.00388316586156514,
    percent_m: 0.000591636688445347,
    variable: 'hspspnsh',
    variablename: 'HspSpnsh',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 14279,
    previous_m: 1420.46576868293,
    previous_cv: 6.04737745328787,
    previous_percent: 0.00628996477725446,
    previous_percent_m: 0.000624011878843161,
    previous_is_reliable: true,
    comparison_sum: 9505,
    comparison_m: 1192,
    comparison_cv: 7.6,
    comparison_percent: 0.004,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -4799,
    change_m: 2026.897876065785,
    change_significant: false,
    change_percent: -0.33608796134183067,
    change_percent_m: 0.12089479209741547,
    change_percent_significant: false,
    change_percentage_point: -0.00240679891568932,
    change_percentage_point_m: 0.0008598981312062193,
    change_percentage_point_significant: false,
    difference_sum: -25,
    difference_m: 1873.887936884168,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0591636688445347
  }, {
    id: 'SHNwVXJn',
    sum: 3000,
    m: 686.249954462658,
    cv: 13.9057741532453,
    percent: 0.0012288499561915,
    percent_m: 0.000280967974883717,
    variable: 'hspurg',
    variablename: 'HspUrg',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 2946,
    previous_m: 706.884714787355,
    previous_cv: 14.5864613661377,
    previous_percent: 0.00129772646780528,
    previous_percent_m: 0.00031123986466009,
    previous_is_reliable: true,
    comparison_sum: 3021,
    comparison_m: 707,
    comparison_cv: 14.2,
    comparison_percent: 0.001,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 54,
    change_m: 985.2030247619018,
    change_significant: false,
    change_percent: 0.018329938900203666,
    change_percent_m: 0.3375902091284391,
    change_percent_significant: false,
    change_percentage_point: -0.00006887651161378004,
    change_percentage_point_m: 0.00041930091374082194,
    change_percentage_point_significant: false,
    difference_sum: -21,
    difference_m: 985.285745355123,
    significant: false,
    difference_percent: 0.02288499561915,
    difference_percent_m: 0.0280967974883717,
    percent_significant: false
  }, {
    id: 'SHNwVm56',
    sum: 13367,
    m: 1400.47527646867,
    cv: 6.36906375142281,
    percent: 0.00547534578813726,
    percent_m: 0.000572378250486146,
    variable: 'hspvnz',
    variablename: 'HspVnz',
    base: 'Hsp2',
    category: 'hispanic_subgroup',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 10583,
    previous_m: 1175.5909152422,
    previous_cv: 6.75276323611391,
    previous_percent: 0.00466185988078184,
    previous_percent_m: 0.000516718630514109,
    previous_is_reliable: true,
    comparison_sum: 13555,
    comparison_m: 1267,
    comparison_cv: 5.7,
    comparison_percent: 0.006,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 2784,
    change_m: 1828.4816105173143,
    change_significant: false,
    change_percent: 0.26306340357176605,
    change_percent_m: 0.19286612595608443,
    change_percent_significant: false,
    change_percentage_point: 0.00081348590735542,
    change_percentage_point_m: 0.0007711128352906322,
    change_percentage_point_significant: false,
    difference_sum: -188,
    difference_m: 1888.549708109373,
    significant: false,
    difference_percent: -0.05246542118627395,
    difference_percent_m: 0.11522225746918785,
    percent_significant: false
  }, {
    id: 'TWFsZQ==',
    sum: 3995482,
    m: 14613.5002993807,
    cv: 0.222340804141632,
    percent: 0.476467435083926,
    percent_m: 0.00120551925062908,
    variable: 'male',
    variablename: 'Male',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 3804687,
    previous_m: 14684.9578140354,
    previous_cv: 0.234632337082374,
    previous_percent: 0.474427974225406,
    previous_percent_m: 0.00124401353188791,
    previous_is_reliable: true,
    comparison_sum: 4025097,
    comparison_m: 268,
    comparison_cv: 0,
    comparison_percent: 0.477,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 190795,
    change_m: 20717.20002799604,
    change_significant: true,
    change_percent: 0.05014735771957062,
    change_percent_m: 0.005584044490385807,
    change_percent_significant: true,
    change_percentage_point: 0.002039460858520026,
    change_percentage_point_m: 0.0017322951051011864,
    change_percentage_point_significant: false,
    difference_sum: -29615,
    difference_m: 14615.957546462694,
    significant: false,
    difference_percent: -0.05325649160739232,
    difference_percent_m: 0.12055192506290799,
    percent_significant: false
  }, {
    id: 'TWRBZ2U=',
    sum: 36.56930618873195,
    m: 0.07864120388986576,
    cv: 0.1307276728900403,
    percent: null,
    percent_m: null,
    variable: 'mdage',
    variablename: 'MdAge',
    base: 'median',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    codingThreshold: null,
    previous_sum: 35.40499376481176,
    previous_m: 0.07944031606732418,
    previous_cv: 0.1363987966557905,
    previous_percent: null,
    previous_percent_m: null,
    previous_is_reliable: true,
    previous_codingThreshold: null,
    comparison_sum: 36.5,
    comparison_m: 0.1,
    comparison_cv: 0.2,
    comparison_percent: null,
    comparison_percent_m: null,
    comparison_is_reliable: true,
    change_sum: 1.1643124239201867,
    change_m: 0.11178194293410632,
    change_significant: true,
    change_percent: null,
    change_percent_m: null,
    change_percentage_point: null,
    change_percentage_point_m: null,
    difference_sum: 0.06930618873195016,
    difference_m: 0.12721807634627808,
    significant: false,
    difference_percent: null,
    difference_percent_m: null
  }, {
    id: 'TWRQb3AwdDQ=',
    sum: 548641,
    m: 6328.1642677794,
    cv: 0.701170413707827,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop0t4',
    variablename: 'MdPop0t4',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 515463,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 551869,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 33178,
    change_m: 6328.1642677794,
    change_significant: true,
    change_percent: 0.06436543457047353,
    change_percent_m: 0.012276660531947783,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -3228,
    difference_m: 6328.1642677794,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AxMHQxNA==',
    sum: 461384,
    m: 5118.6851827398,
    cv: 0.674419296089545,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop10t14',
    variablename: 'MdPop10t14',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 474355,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 464704,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -12971,
    change_m: 5118.6851827398,
    change_significant: false,
    change_percent: -0.027344499372832582,
    change_percent_m: 0.010790832146261345,
    change_percent_significant: false,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -3320,
    difference_m: 5118.6851827398,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AxNXQxNw==',
    sum: 271695,
    m: 3793.07052399504,
    cv: 0.848678830202009,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop15t17',
    variablename: 'MdPop15t17',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 314770,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 273431,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -43075,
    change_m: 3793.07052399504,
    change_significant: true,
    change_percent: -0.13684595101184993,
    change_percent_m: 0.012050292353130983,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1736,
    difference_m: 3793.07052399504,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AxOHQxOQ==',
    sum: 181494,
    m: 2994.64321748017,
    cv: 1.00303690697175,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop18t19',
    variablename: 'MdPop18t19',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 219190,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 182243,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -37696,
    change_m: 2994.64321748017,
    change_significant: true,
    change_percent: -0.17197864866097906,
    change_percent_m: 0.01366231679127775,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -749,
    difference_m: 2994.64321748017,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AyMA==',
    sum: 104859,
    m: 2477.06196934998,
    cv: 1.43603576929516,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop20',
    variablename: 'MdPop20',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 121255,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 105547,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -16396,
    change_m: 2477.06196934998,
    change_significant: true,
    change_percent: -0.1352191662199497,
    change_percent_m: 0.020428534653003833,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -688,
    difference_m: 2477.06196934998,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AyMQ==',
    sum: 104847,
    m: 2493.75299498567,
    cv: 1.44587758111199,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop21',
    variablename: 'MdPop21',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 116568,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 105415,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -11721,
    change_m: 2493.75299498567,
    change_significant: true,
    change_percent: -0.10055075149269097,
    change_percent_m: 0.021393118136930116,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -568,
    difference_m: 2493.75299498567,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AyMnQyNA==',
    sum: 358884,
    m: 4764.85561166338,
    cv: 0.807104457398377,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop22t24',
    variablename: 'MdPop22t24',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 376865,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 360439,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -17981,
    change_m: 4764.85561166338,
    change_significant: true,
    change_percent: -0.047712045427407694,
    change_percent_m: 0.012643401779585211,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1555,
    difference_m: 4764.85561166338,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AyNXQyOQ==',
    sum: 782099,
    m: 7365.60472737982,
    cv: 0.572506966873104,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop25t29',
    variablename: 'MdPop25t29',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 717607,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 785805,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 64492,
    change_m: 7365.60472737982,
    change_significant: true,
    change_percent: 0.08987091820453257,
    change_percent_m: 0.01026412051078072,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -3706,
    difference_m: 7365.60472737982,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AzMHQzNA==',
    sum: 714521,
    m: 6576.95993601907,
    cv: 0.559556953090211,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop30t34',
    variablename: 'MdPop30t34',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 640099,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 718474,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 74422,
    change_m: 6576.95993601907,
    change_significant: true,
    change_percent: 0.11626639004279025,
    change_percent_m: 0.010274910499811857,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -3953,
    difference_m: 6576.95993601907,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3AzNXQzOQ==',
    sum: 607168,
    m: 5809.3858539436,
    cv: 0.581641580674426,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop35t39',
    variablename: 'MdPop35t39',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 587793,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 610524,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 19375,
    change_m: 5809.3858539436,
    change_significant: true,
    change_percent: 0.03296228434159645,
    change_percent_m: 0.009883387270592878,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -3356,
    difference_m: 5809.3858539436,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A0MHQ0NA==',
    sum: 542941,
    m: 5422.48494695928,
    cv: 0.607127373471886,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop40t44',
    variablename: 'MdPop40t44',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 581056,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 545892,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -38115,
    change_m: 5422.48494695928,
    change_significant: true,
    change_percent: -0.06559608712413262,
    change_percent_m: 0.009332121081202637,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -2951,
    difference_m: 5422.48494695928,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A0NXQ0OQ==',
    sum: 538086,
    m: 5346.12616760959,
    cv: 0.603978680350561,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop45t49',
    variablename: 'MdPop45t49',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 561152,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 542513,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: -23066,
    change_m: 5346.12616760959,
    change_significant: true,
    change_percent: -0.041104727417883215,
    change_percent_m: 0.009527055356854452,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -4427,
    difference_m: 5346.12616760959,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A1MHQ1NA==',
    sum: 539417,
    m: 5282.36471667756,
    cv: 0.59530269930427,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop50t54',
    variablename: 'MdPop50t54',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 522588,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 544447,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 16829,
    change_m: 5282.36471667756,
    change_significant: true,
    change_percent: 0.03220318874524482,
    change_percent_m: 0.010108086516869043,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -5030,
    difference_m: 5282.36471667756,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A1NXQ1OQ==',
    sum: 511020,
    m: 5062.51202467708,
    cv: 0.602229836135236,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop55t59',
    variablename: 'MdPop55t59',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 460564,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 515825,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 50456,
    change_m: 5062.51202467708,
    change_significant: true,
    change_percent: 0.10955263546434371,
    change_percent_m: 0.010991983795253386,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -4805,
    difference_m: 5062.51202467708,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A1dDk=',
    sum: 473827,
    m: 5263.32128983212,
    cv: 0.675264898419104,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop5t9',
    variablename: 'MdPop5t9',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 465980,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 476567,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 7847,
    change_m: 5263.32128983212,
    change_significant: false,
    change_percent: 0.016839778531267436,
    change_percent_m: 0.011295165650526032,
    change_percent_significant: false,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -2740,
    difference_m: 5263.32128983212,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A2MHQ2MQ==',
    sum: 202236,
    m: 3274.22586270404,
    cv: 0.98420205046908,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop60t61',
    variablename: 'MdPop60t61',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 171002,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 203884,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 31234,
    change_m: 3274.22586270404,
    change_significant: true,
    change_percent: 0.1826528344697723,
    change_percent_m: 0.019147295719956728,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1648,
    difference_m: 3274.22586270404,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A2MnQ2NA==',
    sum: 264409,
    m: 3639.59846686417,
    cv: 0.836780088557922,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop62t64',
    variablename: 'MdPop62t64',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 211026,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 266773,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 53383,
    change_m: 3639.59846686417,
    change_significant: true,
    change_percent: 0.25296882848558944,
    change_percent_m: 0.017247156591435036,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -2364,
    difference_m: 3639.59846686417,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A2NXQ2Ng==',
    sum: 167072,
    m: 2884.82893773617,
    cv: 1.04966445776619,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop65t66',
    variablename: 'MdPop65t66',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 124278,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 168701,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 42794,
    change_m: 2884.82893773617,
    change_significant: true,
    change_percent: 0.34434091311414733,
    change_percent_m: 0.02321270810389747,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1629,
    difference_m: 2884.82893773617,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A2N3Q2OQ==',
    sum: 213980,
    m: 3196.4040733299,
    cv: 0.90807682032889,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop67t69',
    variablename: 'MdPop67t69',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 159593,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 215921,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 54387,
    change_m: 3196.4040733299,
    change_significant: true,
    change_percent: 0.34078562342959906,
    change_percent_m: 0.020028472886216186,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1941,
    difference_m: 3196.4040733299,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A3MHQ3NA==',
    sum: 277729,
    m: 3670.34521537144,
    cv: 0.80337771600457,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop70t74',
    variablename: 'MdPop70t74',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 224456,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 280196,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 53273,
    change_m: 3670.34521537144,
    change_significant: true,
    change_percent: 0.23734273086930177,
    change_percent_m: 0.016352181342318493,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -2467,
    difference_m: 3670.34521537144,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A3NXQ3OQ==',
    sum: 209285,
    m: 3264.99310872167,
    cv: 0.948370997627156,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop75t79',
    variablename: 'MdPop75t79',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 179888,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 211102,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 29397,
    change_m: 3264.99310872167,
    change_significant: true,
    change_percent: 0.1634183491950547,
    change_percent_m: 0.018150144026959386,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1817,
    difference_m: 3264.99310872167,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A4MHQ4NA==',
    sum: 147359,
    m: 2727.26456362415,
    cv: 1.12508336024896,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop80t84',
    variablename: 'MdPop80t84',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 142179,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 148988,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 5180,
    change_m: 2727.26456362415,
    change_significant: false,
    change_percent: 0.03643294720036011,
    change_percent_m: 0.019181908464851703,
    change_percent_significant: false,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1629,
    difference_m: 2727.26456362415,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3A4NXBs',
    sum: 162682,
    m: 2910.38760305221,
    cv: 1.08754046879315,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop85pl',
    variablename: 'MdPop85pl',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 131798,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 164453,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 30884,
    change_m: 2910.38760305221,
    change_significant: true,
    change_percent: 0.2343282902623712,
    change_percent_m: 0.022082183364331858,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -1771,
    difference_m: 2910.38760305221,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TWRQb3BfMw==',
    sum: 8385635,
    m: 22148.0008127145,
    cv: 0.160558267576461,
    percent: 0,
    percent_m: 0,
    variable: 'mdpop_3',
    variablename: 'MdPop_3',
    base: 'MdAge',
    category: 'demographic__special_variable',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 8019525,
    previous_m: 0,
    previous_cv: null,
    previous_percent: 0,
    previous_percent_m: 0,
    previous_is_reliable: false,
    comparison_sum: 8443713,
    comparison_m: 0,
    comparison_cv: null,
    comparison_percent: 0,
    comparison_percent_m: 0,
    comparison_is_reliable: false,
    change_sum: 366110,
    change_m: 22148.0008127145,
    change_significant: true,
    change_percent: 0.04565232978262428,
    change_percent_m: 0.002761759681865759,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -58078,
    difference_m: 22148.0008127145,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'TVBvcDB0NQ==',
    sum: 280508,
    m: 4552.91774579774,
    cv: 0.986685282580402,
    percent: 0.0334510147412808,
    percent_m: 0.000535705892513806,
    variable: 'mpop0t5',
    variablename: 'MPop0t5',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 263368,
    previous_m: 4062.6573815669,
    previous_cv: 0.937737514013444,
    previous_percent: 0.0328408478058239,
    previous_percent_m: 0.00049798361085419,
    previous_is_reliable: true,
    comparison_sum: 282195,
    comparison_m: 189,
    comparison_cv: 0,
    comparison_percent: 0.033,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 17140,
    change_m: 6101.98697147085,
    change_significant: false,
    change_percent: 0.06508004009598736,
    change_percent_m: 0.02384921448499543,
    change_percent_significant: false,
    change_percentage_point: 0.0006101669354569017,
    change_percentage_point_m: 0.0007314153949387384,
    change_percentage_point_significant: false,
    difference_sum: -1687,
    difference_m: 4556.838926273342,
    significant: false,
    difference_percent: 0.045101474128079744,
    difference_percent_m: 0.0535705892513806,
    percent_significant: false
  }, {
    id: 'TVBvcDEwdDE0',
    sum: 235995,
    m: 3649.51065212858,
    cv: 0.940082420768557,
    percent: 0.0281427703447622,
    percent_m: 0.00042881534586918,
    variable: 'mpop10t14',
    variablename: 'MPop10t14',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 242221,
    previous_m: 3732.71991984397,
    previous_cv: 0.93680178447,
    previous_percent: 0.0302039085856083,
    previous_percent_m: 0.000457525299505431,
    previous_is_reliable: true,
    comparison_sum: 237611,
    comparison_m: 2370,
    comparison_cv: 0.6,
    comparison_percent: 0.028,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -6226,
    change_m: 5220.356884351869,
    change_significant: false,
    change_percent: -0.025703799422841126,
    change_percent_m: 0.021270615465282396,
    change_percent_significant: false,
    change_percentage_point: -0.0020611382408461006,
    change_percentage_point_m: 0.0006270661851355396,
    change_percentage_point_significant: true,
    difference_sum: -1616,
    difference_m: 4351.531684361264,
    significant: false,
    difference_percent: 0.01427703447621953,
    difference_percent_m: 0.042881534586918,
    percent_significant: false
  }, {
    id: 'TVBvcDE1dDE5',
    sum: 227588,
    m: 3391.89519295629,
    cv: 0.905997841071928,
    percent: 0.0271402225353238,
    percent_m: 0.000398086438313698,
    variable: 'mpop15t19',
    variablename: 'MPop15t19',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 270970,
    previous_m: 3924.66610044727,
    previous_cv: 0.880472103417559,
    previous_percent: 0.0337887842484436,
    previous_percent_m: 0.00047994082747752,
    previous_is_reliable: true,
    comparison_sum: 228974,
    comparison_m: 203,
    comparison_cv: 0.1,
    comparison_percent: 0.027,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -43382,
    change_m: 5187.2880197652385,
    change_significant: true,
    change_percent: -0.1600989039377053,
    change_percent_m: 0.01745496949677717,
    change_percent_significant: true,
    change_percentage_point: -0.0066485617131198,
    change_percentage_point_m: 0.0006235511288171102,
    change_percentage_point_significant: true,
    difference_sum: -1386,
    difference_m: 3397.964390631542,
    significant: false,
    difference_percent: 0.014022253532379736,
    difference_percent_m: 0.039808643831369804,
    percent_significant: false
  }, {
    id: 'TVBvcDIwdDI0',
    sum: 276017,
    m: 4113.96183745061,
    cv: 0.906063269648309,
    percent: 0.0329154560149589,
    percent_m: 0.000482832209276199,
    variable: 'mpop20t24',
    variablename: 'MPop20t24',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 299585,
    previous_m: 4513.9119397702,
    previous_cv: 0.915940189353165,
    previous_percent: 0.0373569506922168,
    previous_percent_m: 0.000552831485595879,
    previous_is_reliable: true,
    comparison_sum: 277492,
    comparison_m: 3174,
    comparison_cv: 0.7,
    comparison_percent: 0.033,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -23568,
    change_m: 6107.379388903229,
    change_significant: true,
    change_percent: -0.07866882520820469,
    change_percent_m: 0.01952640303529914,
    change_percent_significant: true,
    change_percentage_point: -0.0044414946772579,
    change_percentage_point_m: 0.0007339956360774101,
    change_percentage_point_significant: true,
    difference_sum: -1475,
    difference_m: 5196.052155242478,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0482832209276199
  }, {
    id: 'TVBvcDI1dDI5',
    sum: 378465,
    m: 5226.22674594205,
    cv: 0.839453459508034,
    percent: 0.0451325391577382,
    percent_m: 0.000611729723221384,
    variable: 'mpop25t29',
    variablename: 'MPop25t29',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 342434,
    previous_m: 5068.08898895826,
    previous_cv: 0.899707727755115,
    previous_percent: 0.0427000352265253,
    previous_percent_m: 0.000620289072426995,
    previous_is_reliable: true,
    comparison_sum: 380518,
    comparison_m: 179,
    comparison_cv: 0,
    comparison_percent: 0.045,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 36031,
    change_m: 7280.039285608285,
    change_significant: true,
    change_percent: 0.10522027602399295,
    change_percent_m: 0.022371755905026297,
    change_percent_significant: true,
    change_percentage_point: 0.002432503931212894,
    change_percentage_point_m: 0.0008711898688832722,
    change_percentage_point_significant: false,
    difference_sum: -2053,
    difference_m: 5229.291252167929,
    significant: false,
    difference_percent: 0.013253915773820069,
    difference_percent_m: 0.061172972322138405,
    percent_significant: false
  }, {
    id: 'TVBvcDMwdDM0',
    sum: 349671,
    m: 4761.60540154264,
    cv: 0.827804693344898,
    percent: 0.0416988099291228,
    percent_m: 0.000557045822628318,
    variable: 'mpop30t34',
    variablename: 'MPop30t34',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 308697,
    previous_m: 4307.60780480303,
    previous_cv: 0.848277297242707,
    previous_percent: 0.0384931775884482,
    previous_percent_m: 0.000525959529370975,
    previous_is_reliable: true,
    comparison_sum: 351613,
    comparison_m: 226,
    comparison_cv: 0,
    comparison_percent: 0.042,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 40974,
    change_m: 6420.932253185672,
    change_significant: true,
    change_percent: 0.1327320965218321,
    change_percent_m: 0.022085425238307893,
    change_percent_significant: true,
    change_percentage_point: 0.0032056323406745996,
    change_percentage_point_m: 0.0007661158365702912,
    change_percentage_point_significant: true,
    difference_sum: -1942,
    difference_m: 4766.965701575798,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.05570458226283181
  }, {
    id: 'TVBvcDM1dDM5',
    sum: 293613,
    m: 4214.65431559932,
    cv: 0.872611188163441,
    percent: 0.0350138063485949,
    percent_m: 0.000494022970800278,
    variable: 'mpop35t39',
    variablename: 'MPop35t39',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 287019,
    previous_m: 4069.94115436575,
    previous_cv: 0.862008564351686,
    previous_percent: 0.0357900249703068,
    previous_percent_m: 0.00049727769488365,
    previous_is_reliable: true,
    comparison_sum: 295382,
    comparison_m: 3094,
    comparison_cv: 0.6,
    comparison_percent: 0.035,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 6594,
    change_m: 5858.987284505744,
    change_significant: false,
    change_percent: 0.022974088823388,
    change_percent_m: 0.020640867126235012,
    change_percent_significant: false,
    change_percentage_point: -0.0007762186217118994,
    change_percentage_point_m: 0.0007009592010289393,
    change_percentage_point_significant: false,
    difference_sum: -1769,
    difference_m: 5228.39812944653,
    significant: false,
    difference_percent: 0.0013806348594891382,
    difference_percent_m: 0.0494022970800278,
    percent_significant: false
  }, {
    id: 'TVBvcDQwdDQ0',
    sum: 263848,
    m: 3885.0827533014,
    cv: 0.895118565847713,
    percent: 0.0314642838616277,
    percent_m: 0.00045578809275092,
    variable: 'mpop40t44',
    variablename: 'MPop40t44',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 280076,
    previous_m: 3977.45408521582,
    previous_cv: 0.863303253037098,
    previous_percent: 0.0349242629706872,
    previous_percent_m: 0.000486007605824112,
    previous_is_reliable: true,
    comparison_sum: 265474,
    comparison_m: 3036,
    comparison_cv: 0.7,
    comparison_percent: 0.031,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -16228,
    change_m: 5560.036780453885,
    change_significant: false,
    change_percent: -0.057941415901398195,
    change_percent_m: 0.01927182988473643,
    change_percent_significant: false,
    change_percentage_point: -0.0034599791090594995,
    change_percentage_point_m: 0.0006662928623453854,
    change_percentage_point_significant: true,
    difference_sum: -1626,
    difference_m: 4930.635253189997,
    significant: false,
    difference_percent: 0.04642838616277034,
    difference_percent_m: 0.045578809275092,
    percent_significant: false
  }, {
    id: 'TVBvcDQ1dDQ5',
    sum: 258209,
    m: 3820.79088671442,
    cv: 0.899530702665377,
    percent: 0.030791824352002,
    percent_m: 0.000448318437866481,
    variable: 'mpop45t49',
    variablename: 'MPop45t49',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 267830,
    previous_m: 3816.82983639564,
    previous_cv: 0.866318671814493,
    previous_percent: 0.0333972398614631,
    previous_percent_m: 0.000466447949268184,
    previous_is_reliable: true,
    comparison_sum: 260855,
    comparison_m: 252,
    comparison_cv: 0.1,
    comparison_percent: 0.031,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -9621,
    change_m: 5400.614131744642,
    change_significant: false,
    change_percent: -0.035922040100063474,
    change_percent_m: 0.01980585132799429,
    change_percent_significant: false,
    change_percentage_point: -0.002605415509461098,
    change_percentage_point_m: 0.0006469645362054524,
    change_percentage_point_significant: true,
    difference_sum: -2646,
    difference_m: 3829.0921900628045,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0448318437866481
  }, {
    id: 'TVBvcDUwdDU0',
    sum: 256749,
    m: 3764.71698803509,
    cv: 0.891369296723427,
    percent: 0.0306177170840372,
    percent_m: 0.000441605193394192,
    variable: 'mpop50t54',
    variablename: 'MPop50t54',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 244581,
    previous_m: 3474.4684485544,
    previous_cv: 0.86357438823995,
    previous_percent: 0.0304981903541669,
    previous_percent_m: 0.000424552996333189,
    previous_is_reliable: true,
    comparison_sum: 259245,
    comparison_m: 174,
    comparison_cv: 0,
    comparison_percent: 0.031,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 12168,
    change_m: 5122.989849687388,
    change_significant: false,
    change_percent: 0.049750389441534704,
    change_percent_m: 0.021431600097817593,
    change_percent_significant: false,
    change_percentage_point: 0.00011952672987030136,
    change_percentage_point_m: 0.0006125850092258303,
    change_percentage_point_significant: false,
    difference_sum: -2496,
    difference_m: 3768.735862328375,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0441605193394192
  }, {
    id: 'TVBvcDU1dDU5',
    sum: 240201,
    m: 3546.93233654097,
    cv: 0.897660655133689,
    percent: 0.0286443423783649,
    percent_m: 0.000416156257192582,
    variable: 'mpop55t59',
    variablename: 'MPop55t59',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 210242,
    previous_m: 3195.38323210222,
    previous_cv: 0.923926811942843,
    previous_percent: 0.0262162659259744,
    previous_percent_m: 0.00039147092469606,
    previous_is_reliable: true,
    comparison_sum: 242832,
    comparison_m: 2266,
    comparison_cv: 0.6,
    comparison_percent: 0.029,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 29959,
    change_m: 4774.013301196386,
    change_significant: true,
    change_percent: 0.14249769313457825,
    change_percent_m: 0.024210369074857307,
    change_percent_significant: true,
    change_percentage_point: 0.0024280764523905013,
    change_percentage_point_m: 0.0005713453555275712,
    change_percentage_point_significant: true,
    difference_sum: -2631,
    difference_m: 4208.976716495351,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0416156257192582
  }, {
    id: 'TVBvcDV0OQ==',
    sum: 241198,
    m: 3724.63098843362,
    cv: 0.938736377096389,
    percent: 0.0287632361771053,
    percent_m: 0.000437623072169051,
    variable: 'mpop5t9',
    variablename: 'MPop5t9',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 237016,
    previous_m: 3666.76301388568,
    previous_cv: 0.940457718803712,
    previous_percent: 0.0295548676511389,
    previous_percent_m: 0.000449501815291838,
    previous_is_reliable: true,
    comparison_sum: 242518,
    comparison_m: 2329,
    comparison_cv: 0.6,
    comparison_percent: 0.029,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 4182,
    change_m: 5226.664997873883,
    change_significant: false,
    change_percent: 0.017644378438586423,
    change_percent_m: 0.022244301034886137,
    change_percent_significant: false,
    change_percentage_point: -0.0007916314740335981,
    change_percentage_point_m: 0.0006273482567484635,
    change_percentage_point_significant: false,
    difference_sum: -1320,
    difference_m: 4392.848392558069,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0437623072169051
  }, {
    id: 'TVBvcDYwdDY0',
    sum: 212127,
    m: 3346.18065860168,
    cv: 0.958931383581372,
    percent: 0.0252964742681979,
    percent_m: 0.000393404108403922,
    variable: 'mpop60t64',
    variablename: 'MPop60t64',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 169860,
    previous_m: 2876.47509984008,
    previous_cv: 1.02944606262196,
    previous_percent: 0.0211808055963414,
    previous_percent_m: 0.000353631785326789,
    previous_is_reliable: true,
    comparison_sum: 214089,
    comparison_m: 2789,
    comparison_cv: 0.8,
    comparison_percent: 0.025,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 42267,
    change_m: 4412.599460635417,
    change_significant: true,
    change_percent: 0.2488343341575415,
    change_percent_m: 0.028901972784457785,
    change_percent_significant: true,
    change_percentage_point: 0.0041156686718565,
    change_percentage_point_m: 0.0005289822606690105,
    change_percentage_point_significant: true,
    difference_sum: -1962,
    difference_m: 4356.0814960236885,
    significant: false,
    difference_percent: 0.029647426819789935,
    difference_percent_m: 0.0393404108403922,
    percent_significant: false
  }, {
    id: 'TVBvcDY1dDY5',
    sum: 168269,
    m: 2861.78930042028,
    cv: 1.03387406137104,
    percent: 0.0200663396391567,
    percent_m: 0.000337132400626961,
    variable: 'mpop65t69',
    variablename: 'MPop65t69',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 121711,
    previous_m: 2341.54905991739,
    previous_cv: 1.16951966459657,
    previous_percent: 0.0151768340394225,
    previous_percent_m: 0.000288799622447225,
    previous_is_reliable: true,
    comparison_sum: 169978,
    comparison_m: 2612,
    comparison_cv: 0.9,
    comparison_percent: 0.02,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 46558,
    change_m: 3697.660070909711,
    change_significant: true,
    change_percent: 0.3825291058326692,
    change_percent_m: 0.035500846262695916,
    change_percent_significant: true,
    change_percentage_point: 0.004889505599734199,
    change_percentage_point_m: 0.0004439183229808806,
    change_percentage_point_significant: true,
    difference_sum: -1709,
    difference_m: 3874.5815257908816,
    significant: false,
    difference_percent: 0.006633963915669838,
    difference_percent_m: 0.0337132400626961,
    percent_significant: false
  }, {
    id: 'TVBvcDcwdDc0',
    sum: 117892,
    m: 2384.07214655933,
    cv: 1.22933191367635,
    percent: 0.0140588041334973,
    percent_m: 0.000281869051677817,
    variable: 'mpop70t74',
    variablename: 'MPop70t74',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 95850,
    previous_m: 2059.93616405946,
    previous_cv: 1.30645887242095,
    previous_percent: 0.0119520794560775,
    previous_percent_m: 0.000254624752693064,
    previous_is_reliable: true,
    comparison_sum: 119097,
    comparison_m: 2119,
    comparison_cv: 1.1,
    comparison_percent: 0.014,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 22042,
    change_m: 3150.7359457752113,
    change_significant: true,
    change_percent: 0.22996348461137195,
    change_percent_m: 0.03629588002941402,
    change_percent_significant: true,
    change_percentage_point: 0.0021067246774198,
    change_percentage_point_m: 0.00037984724163504975,
    change_percentage_point_significant: true,
    difference_sum: -1205,
    difference_m: 3189.6647159223508,
    significant: false,
    difference_percent: 0.005880413349729885,
    difference_percent_m: 0.0281869051677817,
    percent_significant: false
  }, {
    id: 'TVBvcDc1dDc5',
    sum: 85858,
    m: 2092.30590497661,
    cv: 1.48142104785629,
    percent: 0.0102386998718642,
    percent_m: 0.000248040962094608,
    variable: 'mpop75t79',
    variablename: 'MPop75t79',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 72080,
    previous_m: 1770.54341940547,
    previous_cv: 1.49322723097729,
    previous_percent: 0.008988063507502,
    previous_percent_m: 0.000219306564895758,
    previous_is_reliable: true,
    comparison_sum: 86722,
    comparison_m: 1618,
    comparison_cv: 1.1,
    comparison_percent: 0.01,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 13778,
    change_m: 2740.90641941676,
    change_significant: true,
    change_percent: 0.19114872364039956,
    change_percent_m: 0.04121506005939022,
    change_percent_significant: true,
    change_percentage_point: 0.0012506363643621995,
    change_percentage_point_m: 0.00033108864112680773,
    change_percentage_point_significant: true,
    difference_sum: -864,
    difference_m: 2644.9325133167367,
    significant: false,
    difference_percent: 0.02386998718641986,
    difference_percent_m: 0.0248040962094608,
    percent_significant: false
  }, {
    id: 'TVBvcDgwdDg0',
    sum: 57111,
    m: 1712.86222446524,
    cv: 1.82321029555385,
    percent: 0.00681057546625867,
    percent_m: 0.000203467897279676,
    variable: 'mpop80t84',
    variablename: 'MPop80t84',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 51141,
    previous_m: 1460.25169063419,
    previous_cv: 1.73577168484388,
    previous_percent: 0.00637706098553219,
    previous_percent_m: 0.000181189065131316,
    previous_is_reliable: true,
    comparison_sum: 57753,
    comparison_m: 1509,
    comparison_cv: 1.6,
    comparison_percent: 0.007,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 5970,
    change_m: 2250.829180546587,
    change_significant: false,
    change_percent: 0.11673608259517804,
    change_percent_m: 0.04624430688470344,
    change_percent_significant: false,
    change_percentage_point: 0.00043351448072647975,
    change_percentage_point_m: 0.00027244937611705603,
    change_percentage_point_significant: false,
    difference_sum: -642,
    difference_m: 2282.7566668394616,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0203467897279676
  }, {
    id: 'TVBvcDg1cGw=',
    sum: 52163,
    m: 1633.44207121036,
    cv: 1.90359815093332,
    percent: 0.00622051878003276,
    percent_m: 0.000194096396384717,
    variable: 'mpop85pl',
    variablename: 'MPop85pl',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 40006,
    previous_m: 1345.89561259408,
    previous_cv: 2.04512729259924,
    previous_percent: 0.00498857475972704,
    previous_percent_m: 0.000167231549989205,
    previous_is_reliable: true,
    comparison_sum: 52749,
    comparison_m: 1354,
    comparison_cv: 1.6,
    comparison_percent: 0.006,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 12157,
    change_m: 2116.498995983694,
    change_significant: true,
    change_percent: 0.3038794180872869,
    change_percent_m: 0.059927208389028326,
    change_percent_significant: true,
    change_percentage_point: 0.0012319440203057202,
    change_percentage_point_m: 0.0002562026588490548,
    change_percentage_point_significant: true,
    difference_sum: -586,
    difference_m: 2121.6618486460065,
    significant: false,
    difference_percent: 0.022051878003276015,
    difference_percent_m: 0.019409639638471702,
    percent_significant: false
  }, {
    id: 'TkhQSU5I',
    sum: 2762,
    m: 621.775683024031,
    cv: 13.6849796747441,
    percent: 0.000329372790492312,
    percent_m: 0.0000741426126655074,
    variable: 'nhpinh',
    variablename: 'NHPINH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 1758,
    previous_m: 471.288658891767,
    previous_cv: 16.2967955051079,
    previous_percent: 0.000219214978443237,
    previous_percent_m: 0.0000587643727287116,
    previous_is_reliable: true,
    comparison_sum: 2794,
    comparison_m: 488,
    comparison_cv: 10.6,
    comparison_percent: 0,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 1004,
    change_m: 780.203819524104,
    change_significant: false,
    change_percent: 0.571103526734926,
    change_percent_m: 0.5499898905879932,
    change_percent_significant: false,
    change_percentage_point: 0.000110157812049075,
    change_percentage_point_m: 0.00009460644013525929,
    change_percentage_point_significant: false,
    difference_sum: -32,
    difference_m: 790.4106527622209,
    significant: false,
    difference_percent: 0.0329372790492312,
    difference_percent_m: 0.10027447965523768,
    percent_significant: false
  }, {
    id: 'TkhzcA==',
    sum: 5944328,
    m: 19832.3005725508,
    cv: 0.202817034499907,
    percent: 0.70887034792237,
    percent_m: 0.001445003255849,
    variable: 'nhsp',
    variablename: 'NHsp',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 5749401,
    previous_m: 20243.932251418,
    previous_cv: 0.214045633522084,
    previous_percent: 0.716925378996886,
    previous_percent_m: 0.00149974266590289,
    previous_is_reliable: true,
    comparison_sum: 5986576,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 0.709,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 194927,
    change_m: 28339.67076378977,
    change_significant: true,
    change_percent: 0.03390387972590536,
    change_percent_m: 0.0050151227413496036,
    change_percent_significant: true,
    change_percentage_point: -0.008055031074515995,
    change_percentage_point_m: 0.0020826095345368315,
    change_percentage_point_significant: true,
    difference_sum: -42248,
    difference_m: 19832.3005725508,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.1445003255849
  }, {
    id: 'T3RoTkg=',
    sum: 71403,
    m: 3274.99557251609,
    cv: 2.78822846027056,
    percent: 0.00851491866745929,
    percent_m: 0.000389900251403463,
    variable: 'othnh',
    variablename: 'OthNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 68534,
    previous_m: 3387.28932924248,
    previous_cv: 3.00455605887228,
    previous_percent: 0.00854589268067622,
    previous_percent_m: 0.000421686220853914,
    previous_is_reliable: true,
    comparison_sum: 71758,
    comparison_m: 3677,
    comparison_cv: 3.1,
    comparison_percent: 0.008,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 2869,
    change_m: 4711.615964825653,
    change_significant: false,
    change_percent: 0.04186243324481279,
    change_percent_m: 0.07025080081913983,
    change_percent_significant: false,
    change_percentage_point: -0.00003097401321692887,
    change_percentage_point_m: 0.0005743182696924586,
    change_percentage_point_significant: false,
    difference_sum: -355,
    difference_m: 4924.015129952384,
    significant: false,
    difference_percent: 0.05149186674592898,
    difference_percent_m: 0.10733229737802522,
    percent_significant: false
  }, {
    id: 'UG9w',
    sum: 8385635,
    m: 22148.0008127145,
    cv: 0.160558267576461,
    percent: 1,
    percent_m: 0,
    variable: 'pop',
    variablename: 'Pop',
    base: 'Pop',
    category: 'poppyramid_only',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 8019525,
    previous_m: 22713.4050507624,
    previous_cv: 0.172174051010419,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 8443713,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 366110,
    change_m: 31724.323617691225,
    change_significant: true,
    change_percent: 0.04565232978262428,
    change_percent_m: 0.004049465307645153,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -58078,
    difference_m: 22148.0008127145,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'UG9wMTB0MTQ=',
    sum: 461384,
    m: 5239.50722873821,
    cv: 0.690338368332768,
    percent: 0.0550207587141582,
    percent_m: 0.000607685258607869,
    variable: 'pop10t14',
    variablename: 'Pop10t14',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 474355,
    previous_m: 5347.77804326245,
    previous_cv: 0.685336699661499,
    previous_percent: 0.0591500120019577,
    previous_percent_m: 0.000645458085029908,
    previous_is_reliable: true,
    comparison_sum: 464704,
    comparison_m: 3300,
    comparison_cv: 0.4,
    comparison_percent: 0.055,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -12971,
    change_m: 7486.732665188461,
    change_significant: false,
    change_percent: -0.027344499372832582,
    change_percent_m: 0.015564267578312068,
    change_percent_significant: false,
    change_percentage_point: -0.004129253287799499,
    change_percentage_point_m: 0.000886508608564964,
    change_percentage_point_significant: true,
    difference_sum: -3320,
    difference_m: 6192.126936683385,
    significant: false,
    difference_percent: 0.0020758714158199965,
    difference_percent_m: 0.060768525860786904,
    percent_significant: false
  }, {
    id: 'UG9wMTV0MTk=',
    sum: 453189,
    m: 5130.88277005039,
    cv: 0.688250966321591,
    percent: 0.0540434922340407,
    percent_m: 0.000594983466360034,
    variable: 'pop15t19',
    variablename: 'Pop15t19',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 533960,
    previous_m: 6176.24343755976,
    previous_cv: 0.703152910553477,
    previous_percent: 0.0665824970930323,
    previous_percent_m: 0.000746706185837184,
    previous_is_reliable: true,
    comparison_sum: 455674,
    comparison_m: 405,
    comparison_cv: 0.1,
    comparison_percent: 0.054,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -80771,
    change_m: 8029.442135042756,
    change_significant: true,
    change_percent: -0.15126788523484905,
    change_percent_m: 0.013737245365058859,
    change_percent_significant: true,
    change_percentage_point: -0.012539004858991593,
    change_percentage_point_m: 0.0009547646061775211,
    change_percentage_point_significant: true,
    difference_sum: -2485,
    difference_m: 5146.842041485241,
    significant: false,
    difference_percent: 0.004349223404069846,
    difference_percent_m: 0.0594983466360034,
    percent_significant: false
  }, {
    id: 'UG9wMjB0MjQ=',
    sum: 568590,
    m: 6162.03318718749,
    cv: 0.658808074555834,
    percent: 0.0678052407480173,
    percent_m: 0.000712675442714888,
    variable: 'pop20t24',
    variablename: 'Pop20t24',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 614688,
    previous_m: 7085.21079432362,
    previous_cv: 0.70070003382284,
    previous_percent: 0.0766489287083711,
    previous_percent_m: 0.000856408487302242,
    previous_is_reliable: true,
    comparison_sum: 571401,
    comparison_m: 453,
    comparison_cv: 0,
    comparison_percent: 0.068,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -46098,
    change_m: 9389.934238321372,
    change_significant: true,
    change_percent: -0.07499414337029517,
    change_percent_m: 0.014634681456300333,
    change_percent_significant: true,
    change_percentage_point: -0.0088436879603538,
    change_percentage_point_m: 0.0011141551883701731,
    change_percentage_point_significant: true,
    difference_sum: -2811,
    difference_m: 6178.6618292313115,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0712675442714888
  }, {
    id: 'UG9wMjV0Mjk=',
    sum: 782099,
    m: 7365.60472737982,
    cv: 0.572506966873104,
    percent: 0.0932665206630148,
    percent_m: 0.000843110615797337,
    variable: 'pop25t29',
    variablename: 'Pop25t29',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 717607,
    previous_m: 7004.2198709064,
    previous_cv: 0.593344883760037,
    previous_percent: 0.0894824818178134,
    previous_percent_m: 0.000835816683050245,
    previous_is_reliable: true,
    comparison_sum: 785805,
    comparison_m: 271,
    comparison_cv: 0,
    comparison_percent: 0.093,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 64492,
    change_m: 10164.213152034938,
    change_significant: true,
    change_percent: 0.08987091820453257,
    change_percent_m: 0.014782187092260216,
    change_percent_significant: true,
    change_percentage_point: 0.0037840388452013984,
    change_percentage_point_m: 0.0011871920813984898,
    change_percentage_point_significant: true,
    difference_sum: -3706,
    difference_m: 7370.5884432655685,
    significant: false,
    difference_percent: 0.026652066301480204,
    difference_percent_m: 0.0843110615797337,
    percent_significant: false
  }, {
    id: 'UG9wMzB0MzQ=',
    sum: 714521,
    m: 6576.95993601907,
    cv: 0.559556953090211,
    percent: 0.0852077391873126,
    percent_m: 0.00075133162984552,
    variable: 'pop30t34',
    variablename: 'Pop30t34',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 640099,
    previous_m: 6016.24941304797,
    previous_cv: 0.571363879027619,
    previous_percent: 0.0798175702426266,
    previous_percent_m: 0.000715328786733071,
    previous_is_reliable: true,
    comparison_sum: 718474,
    comparison_m: 335,
    comparison_cv: 0,
    comparison_percent: 0.085,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 74422,
    change_m: 8913.56600917949,
    change_significant: true,
    change_percent: 0.11626639004279025,
    change_percent_m: 0.014685022760819717,
    change_percent_significant: true,
    change_percentage_point: 0.005390168944685997,
    change_percentage_point_m: 0.0010373979425154713,
    change_percentage_point_significant: true,
    difference_sum: -3953,
    difference_m: 6585.48608684279,
    significant: false,
    difference_percent: 0.02077391873126011,
    difference_percent_m: 0.075133162984552,
    percent_significant: false
  }, {
    id: 'UG9wMzV0Mzk=',
    sum: 607168,
    m: 5809.3858539436,
    cv: 0.581641580674426,
    percent: 0.0724057271751036,
    percent_m: 0.000665860523829658,
    variable: 'pop35t39',
    variablename: 'Pop35t39',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 587793,
    previous_m: 5609.23123074811,
    previous_cv: 0.580113579027536,
    previous_percent: 0.0732952388077848,
    previous_percent_m: 0.000667930878452893,
    previous_is_reliable: true,
    comparison_sum: 610524,
    comparison_m: 4398,
    comparison_cv: 0.4,
    comparison_percent: 0.072,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 19375,
    change_m: 8075.421908482551,
    change_significant: false,
    change_percent: 0.03296228434159645,
    change_percent_m: 0.013958872963986242,
    change_percent_significant: false,
    change_percentage_point: -0.0008895116326811947,
    change_percentage_point_m: 0.0009431340814463021,
    change_percentage_point_significant: false,
    difference_sum: -3356,
    difference_m: 7286.3823671284235,
    significant: false,
    difference_percent: 0.04057271751036051,
    difference_percent_m: 0.12014034448072416,
    percent_significant: false
  }, {
    id: 'UG9wNDB0NDQ=',
    sum: 542941,
    m: 5422.48494695928,
    cv: 0.607127373471886,
    percent: 0.0647465576548466,
    percent_m: 0.000623617985427276,
    variable: 'pop40t44',
    variablename: 'Pop40t44',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 581056,
    previous_m: 5527.04812716517,
    previous_cv: 0.57824162836082,
    previous_percent: 0.0724551641150816,
    previous_percent_m: 0.00065793858103679,
    previous_is_reliable: true,
    comparison_sum: 545892,
    comparison_m: 4362,
    comparison_cv: 0.5,
    comparison_percent: 0.065,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -38115,
    change_m: 7742.84211384941,
    change_significant: true,
    change_percent: -0.06559608712413262,
    change_percent_m: 0.012887480717071843,
    change_percent_significant: true,
    change_percentage_point: -0.007708606460234996,
    change_percentage_point_m: 0.0009065222380973779,
    change_percentage_point_significant: true,
    difference_sum: -2951,
    difference_m: 6959.194421770381,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.11785157579550536
  }, {
    id: 'UG9wNDV0NDk=',
    sum: 538086,
    m: 5346.12616760959,
    cv: 0.603978680350561,
    percent: 0.064167591363087,
    percent_m: 0.000614594571713349,
    variable: 'pop45t49',
    variablename: 'Pop45t49',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 561152,
    previous_m: 5343.49333301727,
    previous_cv: 0.578867083178918,
    previous_percent: 0.0699732216060178,
    previous_percent_m: 0.000636155079998307,
    previous_is_reliable: true,
    comparison_sum: 542513,
    comparison_m: 317,
    comparison_cv: 0,
    comparison_percent: 0.064,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -23066,
    change_m: 7558.70266646334,
    change_significant: true,
    change_percent: -0.041104727417883215,
    change_percent_m: 0.013196174446211378,
    change_percent_significant: true,
    change_percentage_point: -0.005805630242930793,
    change_percentage_point_m: 0.0008845449527226795,
    change_percentage_point_significant: true,
    difference_sum: -4427,
    difference_m: 5355.516221616736,
    significant: false,
    difference_percent: 0.01675913630869985,
    difference_percent_m: 0.0614594571713349,
    percent_significant: false
  }, {
    id: 'UG9wNTB0NTQ=',
    sum: 539417,
    m: 5282.36471667756,
    cv: 0.59530269930427,
    percent: 0.0643263151806631,
    percent_m: 0.000606586233914986,
    variable: 'pop50t54',
    variablename: 'Pop50t54',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 522588,
    previous_m: 4947.06306812436,
    previous_cv: 0.575469236207504,
    previous_percent: 0.0651644579947067,
    previous_percent_m: 0.00058862055976601,
    previous_is_reliable: true,
    comparison_sum: 544447,
    comparison_m: 267,
    comparison_cv: 0,
    comparison_percent: 0.064,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 16829,
    change_m: 7237.182462809682,
    change_significant: false,
    change_percent: 0.03220318874524482,
    change_percent_m: 0.014058879623549445,
    change_percent_significant: false,
    change_percentage_point: -0.0008381428140435965,
    change_percentage_point_m: 0.000845234300389198,
    change_percentage_point_significant: false,
    difference_sum: -5030,
    difference_m: 5289.108242416674,
    significant: false,
    difference_percent: 0.03263151806631015,
    difference_percent_m: 0.0606586233914986,
    percent_significant: false
  }, {
    id: 'UG9wNTV0NTk=',
    sum: 511020,
    m: 5341.62662491492,
    cv: 0.635432945410796,
    percent: 0.0609399288187478,
    percent_m: 0.000616327417157446,
    variable: 'pop55t59',
    variablename: 'Pop55t59',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 460564,
    previous_m: 4899.95969371178,
    previous_cv: 0.646750267487786,
    previous_percent: 0.0574303340908595,
    previous_percent_m: 0.00058895500092291,
    previous_is_reliable: true,
    comparison_sum: 515825,
    comparison_m: 3495,
    comparison_cv: 0.4,
    comparison_percent: 0.061,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 50456,
    change_m: 7248.626076712745,
    change_significant: true,
    change_percent: 0.10955263546434371,
    change_percent_m: 0.016548773708432496,
    change_percent_significant: true,
    change_percentage_point: 0.003509594727888303,
    change_percentage_point_m: 0.00085248312490751,
    change_percentage_point_significant: true,
    difference_sum: -4805,
    difference_m: 6383.416013389693,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0616327417157446
  }, {
    id: 'UG9wNXQ5',
    sum: 473827,
    m: 5394.26204406126,
    cv: 0.692064118955817,
    percent: 0.0565046057931212,
    percent_m: 0.000625723033912761,
    variable: 'pop5t9',
    variablename: 'Pop5t9',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 465980,
    previous_m: 5309.76261239615,
    previous_cv: 0.692694797472445,
    previous_percent: 0.0581056858105686,
    previous_percent_m: 0.000641325757827508,
    previous_is_reliable: true,
    comparison_sum: 476567,
    comparison_m: 3308,
    comparison_cv: 0.4,
    comparison_percent: 0.056,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 7847,
    change_m: 7569.124255817179,
    change_significant: false,
    change_percent: 0.016839778531267436,
    change_percent_m: 0.016378632827778704,
    change_percent_significant: false,
    change_percentage_point: -0.0016010800174474024,
    change_percentage_point_m: 0.0008960067202995844,
    change_percentage_point_significant: false,
    difference_sum: -2740,
    difference_m: 6327.790056567929,
    significant: false,
    difference_percent: 0.050460579312119336,
    difference_percent_m: 0.06257230339127609,
    percent_significant: false
  }, {
    id: 'UG9wNjB0NjQ=',
    sum: 466645,
    m: 5069.1647240941,
    cv: 0.660364753247707,
    percent: 0.0556481411365985,
    percent_m: 0.000586365925677483,
    variable: 'pop60t64',
    variablename: 'Pop60t64',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 382028,
    previous_m: 4464.0557792214,
    previous_cv: 0.710343671116104,
    previous_percent: 0.0476372353724192,
    previous_percent_m: 0.000540049736147731,
    previous_is_reliable: true,
    comparison_sum: 470657,
    comparison_m: 3485,
    comparison_cv: 0.5,
    comparison_percent: 0.056,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 84617,
    change_m: 6754.570674735738,
    change_significant: true,
    change_percent: 0.2214942360245846,
    change_percent_m: 0.019488387693366253,
    change_percent_significant: true,
    change_percentage_point: 0.008010905764179302,
    change_percentage_point_m: 0.0007971691892621324,
    change_percentage_point_significant: true,
    difference_sum: -4012,
    difference_m: 6151.557201229622,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.058636592567748294
  }, {
    id: 'UG9wNjVwbDE=',
    sum: 1178107,
    m: 6963.95175169961,
    cv: 0.359339628758859,
    percent: 0.140491089822059,
    percent_m: 0.000742953347591594,
    variable: 'pop65pl1',
    variablename: 'Pop65pl1',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 962192,
    previous_m: 6360.88594458351,
    previous_cv: 0.401874050741657,
    previous_percent: 0.119981170954639,
    previous_percent_m: 0.000716693784902195,
    previous_is_reliable: true,
    comparison_sum: 1189361,
    comparison_m: 179,
    comparison_cv: 0,
    comparison_percent: 0.141,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 215915,
    change_m: 9431.72804951458,
    change_significant: true,
    change_percent: 0.2243990804330113,
    change_percent_m: 0.010858189468486315,
    change_percent_significant: true,
    change_percentage_point: 0.020509918867419985,
    change_percentage_point_m: 0.0010322933972543803,
    change_percentage_point_significant: true,
    difference_sum: -11254,
    difference_m: 6966.251861654161,
    significant: false,
    difference_percent: -0.05089101779409866,
    difference_percent_m: 0.0742953347591594,
    percent_significant: false
  }, {
    id: 'UG9wNjVwbDI=',
    sum: 1178107,
    m: 6963.95175169961,
    cv: 0.359339628758859,
    percent: 1,
    percent_m: 0,
    variable: 'pop65pl2',
    variablename: 'Pop65pl2',
    base: 'Pop65pl2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 962192,
    previous_m: 6360.88594458351,
    previous_cv: 0.401874050741657,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 1189361,
    comparison_m: 179,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 215915,
    change_m: 9431.72804951458,
    change_significant: true,
    change_percent: 0.2243990804330113,
    change_percent_m: 0.010858189468486315,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -11254,
    difference_m: 6966.251861654161,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'UG9wNjVwbEY=',
    sum: 696814,
    m: 5092.04143738049,
    cv: 0.444231304104142,
    percent: 0.591469195921932,
    percent_m: 0.00254122251916324,
    variable: 'pop65plf',
    variablename: 'Pop65plF',
    base: 'Pop65pl2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 581404,
    previous_m: 4578.4757288862,
    previous_cv: 0.478714959012246,
    previous_percent: 0.604249463724496,
    previous_percent_m: 0.00258562210039476,
    previous_is_reliable: true,
    comparison_sum: 703062,
    comparison_m: 98,
    comparison_cv: 0,
    comparison_percent: 0.591,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 115410,
    change_m: 6847.724147481409,
    change_significant: true,
    change_percent: 0.19850224628657526,
    change_percent_m: 0.01287564786129553,
    change_percent_significant: true,
    change_percentage_point: -0.012780267802563916,
    change_percentage_point_m: 0.003625362538830037,
    change_percentage_point_significant: true,
    difference_sum: -6248,
    difference_m: 5092.984390315757,
    significant: false,
    difference_percent: 0.04691959219320552,
    difference_percent_m: 0.254122251916324,
    percent_significant: false
  }, {
    id: 'UG9wNjVwbE0=',
    sum: 481293,
    m: 4386.36238812983,
    cv: 0.554024615964028,
    percent: 0.408530804078068,
    percent_m: 0.002833863618798,
    variable: 'pop65plm',
    variablename: 'Pop65plM',
    base: 'Pop65pl2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 380788,
    previous_m: 3882.60930303321,
    previous_cv: 0.619832772154994,
    previous_percent: 0.395750536275504,
    previous_percent_m: 0.00307211654271043,
    previous_is_reliable: true,
    comparison_sum: 486299,
    comparison_m: 131,
    comparison_cv: 0,
    comparison_percent: 0.409,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 100505,
    change_m: 5857.8861375072875,
    change_significant: true,
    change_percent: 0.26393951490067963,
    change_percent_m: 0.01728518104531231,
    change_percent_significant: true,
    change_percentage_point: 0.012780267802564027,
    change_percentage_point_m: 0.0041795553665362525,
    change_percentage_point_significant: true,
    difference_sum: -5006,
    difference_m: 4388.318128850736,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.2833863618798
  }, {
    id: 'UG9wNjV0Njk=',
    sum: 381052,
    m: 4305.3118353959,
    cv: 0.686838237874609,
    percent: 0.0454410429263854,
    percent_m: 0.000499190081616838,
    variable: 'pop65t69',
    variablename: 'Pop65t69',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 283871,
    previous_m: 3548.8701018775,
    previous_cv: 0.759981767453042,
    previous_percent: 0.0353974830180092,
    previous_percent_m: 0.000431022739852125,
    previous_is_reliable: true,
    comparison_sum: 384622,
    comparison_m: 3868,
    comparison_cv: 0.6,
    comparison_percent: 0.046,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 97181,
    change_m: 5579.443431024283,
    change_significant: true,
    change_percent: 0.34234212018839544,
    change_percent_m: 0.02261949470963222,
    change_percent_significant: true,
    change_percentage_point: 0.010043559908376201,
    change_percentage_point_m: 0.0006595235703553422,
    change_percentage_point_significant: true,
    difference_sum: -3570,
    difference_m: 5787.670861408759,
    significant: false,
    difference_percent: -0.05589570736145966,
    difference_percent_m: 0.0499190081616838,
    percent_significant: false
  }, {
    id: 'UG9wNzB0NzQ=',
    sum: 277729,
    m: 3670.34521537144,
    cv: 0.80337771600457,
    percent: 0.0331196146743807,
    percent_m: 0.000428864183712061,
    variable: 'pop70t74',
    variablename: 'Pop70t74',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 224456,
    previous_m: 3105.44570070063,
    previous_cv: 0.841059689469707,
    previous_percent: 0.0279886901032168,
    previous_percent_m: 0.000379034929048844,
    previous_is_reliable: true,
    comparison_sum: 280196,
    comparison_m: 3421,
    comparison_cv: 0.7,
    comparison_percent: 0.033,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 53273,
    change_m: 4807.829759881276,
    change_significant: true,
    change_percent: 0.23734273086930177,
    change_percent_m: 0.02367403330886052,
    change_percent_significant: true,
    change_percentage_point: 0.005130924571163903,
    change_percentage_point_m: 0.0005723565021121666,
    change_percentage_point_significant: true,
    difference_sum: -2467,
    difference_m: 5017.437094772591,
    significant: false,
    difference_percent: 0.011961467438069917,
    difference_percent_m: 0.0428864183712061,
    percent_significant: false
  }, {
    id: 'UG9wNzV0Nzk=',
    sum: 209285,
    m: 3264.99310872167,
    cv: 0.948370997627156,
    percent: 0.0249575613534336,
    percent_m: 0.000383735050148414,
    variable: 'pop75t79',
    variablename: 'Pop75t79',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 179888,
    previous_m: 2756.29715379166,
    previous_cv: 0.931446555530418,
    previous_percent: 0.0224312537213862,
    previous_percent_m: 0.000337775532270369,
    previous_is_reliable: true,
    comparison_sum: 211102,
    comparison_m: 2454,
    comparison_cv: 0.7,
    comparison_percent: 0.025,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 29397,
    change_m: 4272.862506563954,
    change_significant: true,
    change_percent: 0.1634183491950547,
    change_percent_m: 0.025440176205790675,
    change_percent_significant: true,
    change_percentage_point: 0.0025263076320474025,
    change_percentage_point_m: 0.0005112190322287864,
    change_percentage_point_significant: true,
    difference_sum: -1817,
    difference_m: 4084.396650669471,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0383735050148414
  }, {
    id: 'UG9wODB0ODQ=',
    sum: 147359,
    m: 2727.26456362415,
    cv: 1.12508336024896,
    percent: 0.0175727896575513,
    percent_m: 0.000321901752603271,
    variable: 'pop80t84',
    variablename: 'Pop80t84',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 142179,
    previous_m: 2489.47002392075,
    previous_cv: 1.06440166103419,
    previous_percent: 0.0177291049033453,
    previous_percent_m: 0.000306338017391424,
    previous_is_reliable: true,
    comparison_sum: 148988,
    comparison_m: 2294,
    comparison_cv: 0.9,
    comparison_percent: 0.018,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 5180,
    change_m: 3692.618718470674,
    change_significant: false,
    change_percent: 0.03643294720036011,
    change_percent_m: 0.02640589094137642,
    change_percent_significant: false,
    change_percentage_point: -0.00015631524579400036,
    change_percentage_point_m: 0.0004443688999337891,
    change_percentage_point_significant: false,
    difference_sum: -1629,
    difference_m: 3563.763179561743,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.0321901752603271
  }, {
    id: 'UG9wODVwbA==',
    sum: 162682,
    m: 3002.20685496519,
    cv: 1.12185107132766,
    percent: 0.0194000812103079,
    percent_m: 0.00035433220906207,
    variable: 'pop85pl',
    variablename: 'Pop85pl',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 131798,
    previous_m: 2592.14274298311,
    previous_cv: 1.19559527794612,
    previous_percent: 0.0164346392086813,
    previous_percent_m: 0.000319859841247412,
    previous_is_reliable: true,
    comparison_sum: 164453,
    comparison_m: 2242,
    comparison_cv: 0.8,
    comparison_percent: 0.019,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 30884,
    change_m: 3966.415258139266,
    change_significant: true,
    change_percent: 0.2343282902623712,
    change_percent_m: 0.03328978937382951,
    change_percent_significant: true,
    change_percentage_point: 0.0029654420016266003,
    change_percentage_point_m: 0.00047734854396093646,
    change_percentage_point_significant: true,
    difference_sum: -1771,
    difference_m: 3746.9734453289066,
    significant: false,
    difference_percent: 0.0400081210307901,
    difference_percent_m: 0.035433220906207,
    percent_significant: false
  }, {
    id: 'UG9wXzE=',
    sum: 8385635,
    m: 22148.0008127145,
    cv: 0.160558267576461,
    percent: 1,
    percent_m: 0,
    variable: 'pop_1',
    variablename: 'Pop_1',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 8019525,
    previous_m: 22713.4050507624,
    previous_cv: 0.172174051010419,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 8443713,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 366110,
    change_m: 31724.323617691225,
    change_significant: true,
    change_percent: 0.04565232978262428,
    change_percent_m: 0.004049465307645153,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -58078,
    difference_m: 22148.0008127145,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'UG9wXzI=',
    sum: 8385635,
    m: 22148.0008127145,
    cv: 0.160558267576461,
    percent: 1,
    percent_m: 0,
    variable: 'pop_2',
    variablename: 'Pop_2',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 8019525,
    previous_m: 22713.4050507624,
    previous_cv: 0.172174051010419,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 8443713,
    comparison_m: 0,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 366110,
    change_m: 31724.323617691225,
    change_significant: true,
    change_percent: 0.04565232978262428,
    change_percent_m: 0.004049465307645153,
    change_percent_significant: true,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -58078,
    difference_m: 22148.0008127145,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'UG9wVTE4MQ==',
    sum: 1755547,
    m: 11396.2051139842,
    cv: 0.394622545783177,
    percent: 0.209351706817671,
    percent_m: 0.00124144419499496,
    variable: 'popu18',
    variablename: 'PopU18',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 1770568,
    previous_m: 10712.5201049986,
    previous_cv: 0.367801195808064,
    previous_percent: 0.220782153556476,
    previous_percent_m: 0.00118040584557022,
    previous_is_reliable: true,
    comparison_sum: 1766571,
    comparison_m: 170,
    comparison_cv: 0,
    comparison_percent: 0.209,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -15021,
    change_m: 15640.702605701537,
    change_significant: false,
    change_percent: -0.00848371821923812,
    change_percent_m: 0.008798643757444742,
    change_percent_significant: false,
    change_percentage_point: -0.011430446738805,
    change_percentage_point_m: 0.0017130503931709162,
    change_percentage_point_significant: true,
    difference_sum: -11024,
    difference_m: 11397.473009399919,
    significant: false,
    difference_percent: 0.03517068176710225,
    difference_percent_m: 0.124144419499496,
    percent_significant: false
  }, {
    id: 'UG9wVTE4XzI=',
    sum: 1755547,
    m: 11396.2051139842,
    cv: 0.394622545783177,
    percent: 1,
    percent_m: 0,
    variable: 'popu18_2',
    variablename: 'PopU18_2',
    base: 'PopU18_2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 1770568,
    previous_m: 10719.0047579055,
    previous_cv: 0.368023838386108,
    previous_percent: 1,
    previous_percent_m: 0,
    previous_is_reliable: true,
    comparison_sum: 1766571,
    comparison_m: 170,
    comparison_cv: 0,
    comparison_percent: 1,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -15021,
    change_m: 15645.144742059769,
    change_significant: false,
    change_percent: -0.00848371821923812,
    change_percent_m: 0.008801120081327034,
    change_percent_significant: false,
    change_percentage_point: 0,
    change_percentage_point_m: 0,
    difference_sum: -11024,
    difference_m: 11397.473009399919,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0
  }, {
    id: 'UG9wVTE4Rg==',
    sum: 860196,
    m: 7571.11253383543,
    cv: 0.535052478803511,
    percent: 0.489987451204667,
    percent_m: 0.00291236823982334,
    variable: 'popu18f',
    variablename: 'PopU18F',
    base: 'PopU18_2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 867571,
    previous_m: 7503.10162532802,
    previous_cv: 0.525738643089605,
    previous_percent: 0.489995865733482,
    previous_percent_m: 0.00302625562157007,
    previous_is_reliable: true,
    comparison_sum: 865584,
    comparison_m: 268,
    comparison_cv: 0,
    comparison_percent: 0.49,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -7375,
    change_m: 10659.187539395296,
    change_significant: false,
    change_percent: -0.008500745183967652,
    change_percent_m: 0.012234603862276795,
    change_percent_significant: false,
    change_percentage_point: -0.000008414528814948419,
    change_percentage_point_m: 0.004200013315623672,
    change_percentage_point_significant: false,
    difference_sum: -5388,
    difference_m: 7575.85434126079,
    significant: false,
    difference_percent: 0,
    difference_percent_m: 0.29123682398233397
  }, {
    id: 'UG9wVTE4TQ==',
    sum: 895351,
    m: 7662.65410938012,
    cv: 0.520259473073683,
    percent: 0.510012548795333,
    percent_m: 0.00284438227370825,
    variable: 'popu18m',
    variablename: 'PopU18M',
    base: 'PopU18_2',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 902997,
    previous_m: 7646.69013364606,
    previous_cv: 0.514779545252173,
    previous_percent: 0.510004134266518,
    previous_percent_m: 0.00301973680855108,
    previous_is_reliable: true,
    comparison_sum: 900987,
    comparison_m: 291,
    comparison_cv: 0,
    comparison_percent: 0.51,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: -7646,
    change_m: 10825.347015223117,
    change_significant: false,
    change_percent: -0.008467359249255535,
    change_percent_m: 0.011937701867646693,
    change_percent_significant: false,
    change_percentage_point: 0.000008414528814948419,
    change_percentage_point_m: 0.004148411854180341,
    change_percentage_point_significant: false,
    difference_sum: -5636,
    difference_m: 7668.177684430639,
    significant: false,
    difference_percent: 0.001254879533298947,
    difference_percent_m: 0.284438227370825,
    percent_significant: false
  }, {
    id: 'UG9wVTU=',
    sum: 548641,
    m: 6522.08018963275,
    cv: 0.722656598547046,
    percent: 0.0654262915092298,
    percent_m: 0.000758328781942126,
    variable: 'popu5',
    variablename: 'PopU5',
    base: 'Pop_1',
    category: 'sex_and_age',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 515463,
    previous_m: 5877.57220287424,
    previous_cv: 0.693161724622766,
    previous_percent: 0.0642760013841219,
    previous_percent_m: 0.000709938628735747,
    previous_is_reliable: true,
    comparison_sum: 551869,
    comparison_m: 129,
    comparison_cv: 0,
    comparison_percent: 0.065,
    comparison_percent_m: 0,
    comparison_is_reliable: true,
    change_sum: 33178,
    change_m: 8779.714403099904,
    change_significant: true,
    change_percent: 0.06436543457047353,
    change_percent_m: 0.01753248250739133,
    change_percent_significant: true,
    change_percentage_point: 0.0011502901251078945,
    change_percentage_point_m: 0.0010387855399903395,
    change_percentage_point_significant: false,
    difference_sum: -3228,
    difference_m: 6523.355808171126,
    significant: false,
    difference_percent: 0.0426291509229797,
    difference_percent_m: 0.0758328781942126,
    percent_significant: false
  }, {
    id: 'UmMycGxOSA==',
    sum: 162143,
    m: 4497.06626591159,
    cv: 1.68602954494035,
    percent: 0.019335804623025,
    percent_m: 0.000533844965359631,
    variable: 'rc2plnh',
    variablename: 'Rc2plNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 101278,
    previous_m: 3361.55544354098,
    previous_cv: 2.01771238558516,
    previous_percent: 0.0126289275237623,
    previous_percent_m: 0.000417642517826483,
    previous_is_reliable: true,
    comparison_sum: 162601,
    comparison_m: 4044,
    comparison_cv: 1.5,
    comparison_percent: 0.019,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: 60865,
    change_m: 5614.593484839307,
    change_significant: true,
    change_percent: 0.6009696084045894,
    change_percent_m: 0.06924832068125678,
    change_percent_significant: true,
    change_percentage_point: 0.0067068770992627005,
    change_percentage_point_m: 0.0006778021243226299,
    change_percentage_point_significant: true,
    difference_sum: -458,
    difference_m: 6047.936920967348,
    significant: false,
    difference_percent: 0.033580462302500225,
    difference_percent_m: 0.11335741912375324,
    percent_significant: false
  }, {
    id: 'V3ROSA==',
    sum: 2678827,
    m: 13497.8004134007,
    cv: 0.306303833557761,
    percent: 0.319454281041328,
    percent_m: 0.00137077634228441,
    variable: 'wtnh',
    variablename: 'WtNH',
    base: 'Pop_2',
    category: 'mutually_exclusive_race_hispanic_origin',
    profile: 'demographic',
    is_reliable: true,
    previous_sum: 2683464,
    previous_m: 13606.9459100858,
    previous_cv: 0.308247088143889,
    previous_percent: 0.33461632702685,
    previous_percent_m: 0.001407375944386,
    previous_is_reliable: true,
    comparison_sum: 2713930,
    comparison_m: 1659,
    comparison_cv: 0,
    comparison_percent: 0.321,
    comparison_percent_m: 0.001,
    comparison_is_reliable: true,
    change_sum: -4637,
    change_m: 19166.105316417335,
    change_significant: false,
    change_percent: -0.0017279903885425703,
    change_percent_m: 0.007136082202704842,
    change_percent_significant: false,
    change_percentage_point: -0.01516204598552201,
    change_percentage_point_m: 0.0019646207851397204,
    change_percentage_point_significant: true,
    difference_sum: -35103,
    difference_m: 13599.371198698862,
    significant: false,
    difference_percent: -0.15457189586719977,
    difference_percent_m: 0.1696769807771999,
    percent_significant: false
  }];
  _exports.default = _default;
});