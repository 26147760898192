define("labs-nyc-factfinder/chart-config/acs/economic/occupation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'mgbsciart',
    label: 'Management, business, science, and arts'
  }, {
    property: 'srvc',
    label: 'Service'
  }, {
    property: 'salesoff',
    label: 'Sales and office'
  }, {
    property: 'nrcnstmnt',
    label: 'Natural resources, construction, and maintenance'
  }, {
    property: 'prdtrnsmm',
    label: 'Production, transportation, and material moving'
  }];
  _exports.default = _default;
});