define("labs-nyc-factfinder/chart-config/census/household-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'hh1person',
    label: '1-person household'
  }, {
    property: 'hh2ppl',
    label: '2-person household'
  }, {
    property: 'hh3ppl',
    label: '3-person household'
  }, {
    property: 'hh4ppl',
    label: '4-person household'
  }, {
    property: 'hh5plppl',
    label: '5-or-more person household'
  }];
  _exports.default = _default;
});