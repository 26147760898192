define("labs-nyc-factfinder/chart-config/acs/social/educational-attainment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'ea_lthsgr',
    label: 'Less than high school graduate'
  }, {
    property: 'ea_hscgrd',
    label: 'High school graduate (includes equivalency)'
  }, {
    property: 'ea_bchdh',
    label: 'Bachelor\'s degree or higher '
  }];
  _exports.default = _default;
});