define("labs-nyc-factfinder/templates/components/select-geography-list-boroughs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S1XSSWwJ",
    "block": "[[[10,1],[14,0,\"comma-separated-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sortedLabels\"]]],null]],null],null,[[[41,[30,1,[\"features\",\"length\"]],[[[1,\"      \"],[1,[30,1,[\"label\"]]],[1,[52,[51,[28,[37,4],[[30,0,[\"sortedLabels\",\"lastObject\",\"label\"]],[30,1,[\"label\"]]],null]],\",\"]],[1,\"\\n\"]],[]],null]],[1]],null],[13],[1,\"\\n\"]],[\"boro\"],false,[\"each\",\"-track-array\",\"if\",\"unless\",\"eq\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/select-geography-list-boroughs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});