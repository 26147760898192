define("labs-nyc-factfinder/helpers/render-top-bottom-coding-suffix", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderTopBottomCodingSuffix = renderTopBottomCodingSuffix;
  _exports.default = void 0;

  function renderTopBottomCodingSuffix(_ref) {
    let [direction] = _ref;
    const symbol = direction === 'upper' ? '+' : '-';
    return direction ? symbol : '';
  }

  var _default = (0, _helper.helper)(renderTopBottomCodingSuffix);

  _exports.default = _default;
});