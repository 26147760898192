define("labs-nyc-factfinder/templates/components/selection-details-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oLIrvuDH",
    "block": "[[[10,\"strong\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"cities\"],null],[[[1,\"    New York City\\n\"]],[]],[[[1,\"    \"],[1,[30,2]],[1,\"\\n    \"],[1,[52,[28,[37,1],[[30,1],\"tracts\"],null],\"Tract\"]],[1,[52,[28,[37,1],[[30,1],\"blocks\"],null],\"Block\"]],[1,[52,[28,[37,1],[[30,1],\"ccds\"],null],\"CCD\"]],[1,[52,[28,[37,1],[[30,1],\"cdtas\"],null],\"CDTA\"]],[1,[52,[28,[37,1],[[30,1],\"districts\"],null],\"CD\"]],[1,[52,[28,[37,1],[[30,1],\"ntas\"],null],\"NTA\"]],[1,[52,[28,[37,1],[[30,1],\"boroughs\"],null],\"Borough\"]],[1,[52,[51,[28,[37,1],[[30,2],1],null]],\"s\"]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[41,[51,[28,[37,1],[[30,1],\"cities\"],null]],[[[1,\"   | \\n\"]],[]],null]],[\"@selectedType\",\"@selectedCount\"],false,[\"if\",\"eq\",\"unless\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/selection-details-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});