define("labs-nyc-factfinder/sources/choropleths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    type: 'geojson',
    data: '/data/choropleth.geojson'
  };
  _exports.default = _default;
});