define("labs-nyc-factfinder/table-config/acs/housing/housing-occupancy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total housing units',
    highlight: true,
    data: 'hu1'
  }, {
    title: 'Occupied housing units',
    data: 'ochu1'
  }, {
    title: 'Vacant housing units',
    data: 'vachu'
  }, {
    divider: true
  }, {
    title: 'Homeowner vacancy rate',
    tooltip: 'Number of vacant units “for sale only,” divided by sum of owner-occupied units, vacant units that are “for sale only,” and vacant units that have been sold but not yet occupied. Quotient is multiplied by 100.',
    data: 'hovacrt',
    special: true,
    decimal: 1
  }, {
    title: 'Rental vacancy rate',
    tooltip: 'Number of vacant units “for rent,” divided by sum of renter-occupied units, vacant units that are “for rent,” and vacant units that have been rented but not yet occupied. Quotient is multiplied by 100.',
    data: 'rntvacrt',
    special: true,
    decimal: 1
  }];
  _exports.default = _default;
});