define("labs-nyc-factfinder/table-config/census/household-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    data: 'hh_1',
    title: 'Total households',
    highlight: true
  }, {
    data: 'fam',
    title: 'Family households (families)'
  }, {
    data: 'nfamhh',
    title: 'Nonfamily households'
  }, {
    data: 'nfmhhu65',
    title: 'Householder under 65 years',
    indent: 1
  }, {
    data: 'nfmlvgaln',
    title: 'Householder living alone',
    indent: 1
  }, {
    data: 'nflva65pl',
    title: 'Householder 65 years and over',
    indent: 2
  }, {
    divider: true
  }, {
    data: 'hh_2',
    title: 'Total households',
    highlight: true
  }, {
    data: 'mrdcplhh',
    title: 'Married couple household'
  }, {
    data: 'mrdch18',
    title: 'With own children under 18',
    indent: 1
  }, {
    data: 'cocplhh',
    title: 'Cohabiting couple household'
  }, {
    data: 'cocplch18',
    title: 'With own children under 18',
    indent: 1
  }, {
    data: 'malehhns',
    title: 'Male householder, no spouse or partner present'
  }, {
    data: 'mnspchu18',
    title: 'With own children under 18',
    indent: 1
  }, {
    data: 'femhhns',
    title: 'Female householder, no spouse or partner present'
  }, {
    data: 'fnspchu18',
    title: 'With own children under 18',
    indent: 1
  }, {
    divider: true
  }, {
    data: 'hhwu18',
    title: 'Households with individuals under 18 years'
  }, {
    data: 'hhw65pl',
    title: 'Households with individuals 65 years and over'
  }];
  _exports.default = _default;
});