define("labs-nyc-factfinder/components/tooltip-renderer", ["exports", "@ember/component", "@ember/object", "mustache"], function (_exports, _component, _object, _mustache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    renderedText: (0, _object.computed)('feature', 'template', function () {
      const properties = this.get('feature.properties');
      const template = this.get('template');
      return _mustache.default.render(template, properties);
    }),
    feature: {},
    template: ''
  });

  _exports.default = _default;
});