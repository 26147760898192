define("labs-nyc-factfinder/components/explorer/topic-select-dropdown", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    * @param { Object[] } topics - array of Topic objects.
    * If explorer selected source is acs, then `topics` has
    * TWO levels of nesting of Topic objects (Topics and Subtopics).
    * If explorer selected source is decennial, then `topics` is a
    * 1D array of subtopics.
    * See controller for an example.
  */
  let TopicSelectDropdownComponent = (_dec = (0, _service.inject)(), (_class = class TopicSelectDropdownComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "metrics", _descriptor, this);

      _initializerDefineProperty(this, "open", _descriptor2, this);
    }

    get numSelected() {
      return this.args.topics.reduce((prev, cur) => {
        if (cur.type === 'subtopic' && cur.selected === "selected") {
          return prev += 1;
        }

        return prev += cur.children.filter(child => child.selected === "selected").length;
      }, 0);
    }

    get isAllTopicsSelected() {
      const {
        topics
      } = this.args;

      if (topics.every(topic => topic.selected === "selected")) {
        return "selected";
      }

      if (topics.every(topic => topic.selected === "unselected")) {
        return "unselected";
      }

      return "indeterminate";
    }

    toggleOpen() {
      this.open = !this.open;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'toggle_select_topics',
        'toggle': this.open ? 'Opened' : 'Closed'
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Select Topics',
        eventAction: 'Toggle Menu',
        eventLabel: this.open ? 'Opened' : 'Closed'
      });
    }

    closeMenu() {
      if (this.open) {
        window.dataLayer = window.dataLayer || [];

        if (this.isAllTopicsSelected === "selected") {
          window.dataLayer.push({
            'event': 'toggle_select_topics',
            'toggle': 'Closed',
            'selected_topics': 'All Topics Selected'
          });
          this.metrics.trackEvent('GoogleAnalytics', {
            eventCategory: 'Select Topics',
            eventAction: 'Toggle Menu',
            eventLabel: 'All Topics Selected'
          });
        } else if (this.isAllTopicsSelected === "unselected") {
          window.dataLayer.push({
            'event': 'toggle_select_topics',
            'toggle': 'Closed',
            'selected_topics': 'No Topics Selected'
          });
          this.metrics.trackEvent('GoogleAnalytics', {
            eventCategory: 'Select Topics',
            eventAction: 'Toggle Menu',
            eventLabel: 'No Topics Selected'
          });
        } else {
          var selectedTopics = [];
          this.args.topics.forEach(topic => {
            if (topic.type === "subtopic" && topic.selected === "selected") {
              selectedTopics.push(topic.id);
            }

            topic.children.forEach(subtopic => {
              if (subtopic.selected === "selected") {
                selectedTopics.push(subtopic.id);
              }
            });
          });
          window.dataLayer.push({
            'event': 'toggle_select_topics',
            'toggle': 'Closed',
            'selected_topics': selectedTopics
          });
          this.metrics.trackEvent('GoogleAnalytics', {
            eventCategory: 'Select Topics',
            eventAction: 'Toggle Menu',
            eventLabel: selectedTopics.join(',')
          });
        }
      }

      this.open = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype)), _class));
  _exports.default = TopicSelectDropdownComponent;
});