define("labs-nyc-factfinder/table-config/acs/demographic/mutually-exclusive-race-hispanic-origin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total population',
    highlight: true,
    data: 'pop_2'
  }, {
    title: 'Hispanic/Latino (of any race)',
    data: 'hsp1'
  }, {
    title: 'Not Hispanic/Latino',
    data: 'nhsp'
  }, {
    title: 'White alone',
    indent: 1,
    data: 'wtnh'
  }, {
    title: 'Black or African American Alone',
    indent: 1,
    data: 'blnh'
  }, {
    title: 'American Indian and Alaska Native alone',
    indent: 1,
    data: 'aiannh'
  }, {
    title: 'Asian alone',
    indent: 1,
    data: 'asnnh'
  }, {
    title: 'Native Hawaiian and Other Pacific Islander alone',
    indent: 1,
    data: 'nhpinh'
  }, {
    title: 'Some other race alone',
    indent: 1,
    data: 'othnh'
  }, {
    title: 'Two or more races',
    indent: 1,
    data: 'rc2plnh'
  }];
  _exports.default = _default;
});