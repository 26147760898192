define("labs-nyc-factfinder/helpers/sanitize-style", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitizeStyle = sanitizeStyle;
  _exports.default = void 0;

  function sanitizeStyle(_ref) {
    let [styleObject] = _ref;
    return (0, _string.htmlSafe)(Object.keys(styleObject).reduce((acc, key) => acc.concat(`${key}:${styleObject[key]};`), ''));
  }

  var _default = (0, _helper.helper)(sanitizeStyle);

  _exports.default = _default;
});