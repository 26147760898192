define("labs-nyc-factfinder/templates/components/switch-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8HqINC/1",
    "block": "[[[10,0],[14,0,\"switch tiny\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,0,\"switch-input\"],[14,3,\"exampleSwitch\"],[15,\"checked\",[30,0,[\"checked\"]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n  \"],[10,\"label\"],[14,0,\"switch-paddle\"],[14,\"for\",\"exampleSwitch\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"show-for-sr\"],[12],[1,\"Toggle Layer Group\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/switch-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});