define("labs-nyc-factfinder/models/decennial-row", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The Row model represents a row in the results of getting
   * all decennial data for a given geography selection.
   * It contains all the data necessary
   * to display a row in a table.
   *
   * See Ember Data documentation: https://api.emberjs.com/ember-data/release/classes/DS.Model
   */
  var _default = _emberData.default.Model.extend({
    /* =============================================
    =            Row MetaData                      =
    ============================================= */

    /**
     * Number of geoids, which compose an aggregate geography
     */
    numGeoids: _emberData.default.attr('number'),

    /**
     * Survey Type: 'acs' or 'decennial'
     */
    survey: _emberData.default.attr('string'),

    /**
     * Category name, used to group together rows
     * e.g. sexAndAge (as part of domain "demographic"), placeOfBirth (as part of domain "social")
     */
    category: _emberData.default.attr('string'),

    /**
     * Raw variable name, used to group together rows.
     * e.g. Pop10t14 (as part of category "sexAndAge")
     */
    variablename: _emberData.default.attr('string'),

    /**
     * Lower-cased version of "variablename"
     */
    variable: _emberData.default.attr('string'),

    /**
     * U.S. Census-specified "universe" for any given variable; not inferred from aggregating data,
     * but rather an explicit value.
     * e.g. "Pop_1" (total population), or "fb2" (total foreign-born population)
     */
    base: _emberData.default.attr('string'),

    /**
     * Geotype is the Census(ish) "summary level", a pre-aggregated geography at which
     * data are published. A "selection" is aggregated and composed from many of one type
     * of these geographies.
     *
     * One of:
     *   NTA2010 // DCP-created summary level
     *   CT2010 // official Census sum level
     *   PUMA2010 // official Census sum level
     *   Boro2010 // official Census sum level
     */
    geotype: _emberData.default.attr('string'),

    /**
     * Flag for whether the variable is 'special'; indicated by 'base' value
     * of 'mean', 'median', or 'rate' (the type of special calculation required for the variable).
     * Used in templates for display purposes.
     */
    isSpecial: (0, _object.computed)('base', function () {
      const base = this.get('base'); // base = mean, median, or rate indicates variable is special

      return ['mean', 'median', 'rate', 'ratio'].includes(base);
    }),

    /* =====  End of Row Metadata  ====== */

    /* =============================================
    =               Main Values                    =
    ============================================= */

    /**
     * Sum is the aggregate estimated value. Most of the time this is an actual "sum",
     * for special calculations, it could be a rate, median, or mean.
     */
    sum: _emberData.default.attr('number'),

    /**
     * The direction sum was coded, either 'upper' or 'lower',
     * or undefined if the value was not top- or bottom-coded
     * NOTE: only median values are subject to coding
     */
    codingThreshold: _emberData.default.attr('string'),

    /**
     * M is margin or error for the aggregate.
     */
    marginOfError: _emberData.default.attr('number'),

    /**
     * correlationCoefficient is the coefficient of variation.
     */
    correlationCoefficient: _emberData.default.attr('number'),

    /**
     * Percent is the aggregate "sum" divided by the "base", which is the U.S. Census published
     * universe for a given given (in other words, not inferred by summing the total)
     */
    percent: _emberData.default.attr('number'),

    /**
     * Percent_M is the percentage margin of error.
     */
    percentMarginOfError: _emberData.default.attr('number'),
    isReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /* =====  End of Main Values  ====== */

    /* =============================================
    =                  PREVIOUS_                   =
    = previous refers to values from previous      =
    = year(s)                                      =
    ============================================= */

    /**
     * previousSum is the previous year estimate
     */
    previousSum: _emberData.default.attr('number'),

    /**
     * See "codingThreshold"
     */
    previousCodingThreshold: _emberData.default.attr('string'),

    /**
     * previousMarginOfError is the previous year margin of error
     */
    previousMarginOfError: _emberData.default.attr('number'),

    /**
     * previousCorrelationCoefficient is the previous year coefficient of variation
     */
    previousCorrelationCoefficient: _emberData.default.attr('number'),

    /**
     * previousPercent is the previous year percentage
     */
    previousPercent: _emberData.default.attr('number'),

    /**
     * previousPercentMarginOfError is the previous year percentage margin of error
     */
    previousPercentMarginOfError: _emberData.default.attr('number'),
    previousIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    // previous comparison
    previousComparisonSum: _emberData.default.attr('number'),
    previousComparisonCodingThreshold: _emberData.default.attr('string'),
    previousComparisonMarginOfError: _emberData.default.attr('number'),
    previousComparisonCorrelationCoefficient: _emberData.default.attr('number'),
    previousComparisonPercent: _emberData.default.attr('number'),
    previousComparisonPercentMarginOfError: _emberData.default.attr('number'),
    previousComparisonIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    // previous difference
    previousDifferenceSum: _emberData.default.attr('number'),
    previousDifferenceMarginOfError: _emberData.default.attr('number'),
    previousDifferenceIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    previousDifferencePercent: _emberData.default.attr('number'),
    previousDifferencePercentMarginOfError: _emberData.default.attr('number'),
    previousDifferencePercentIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /* =====  End of PREVIOUS_  ====== */

    /* =============================================
    =                    CHANGE_                   =
    = refers to changes in values from previous year
    = (ACS y2006-2010) to current year (ACS y2012-2016)
    = (current year - previous year = change)
    ============================================= */

    /**
     * changeSum is change in estimates from previous year to
     * current year
     */
    changeSum: _emberData.default.attr('number'),

    /**
     * changeMarginOfError is change in margin of error from previous year to
     * current year
     */
    changeMarginOfError: _emberData.default.attr('number'),
    changeIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /**
     * changePercent is change in percentage from previous year to
     * current year
     */
    changePercent: _emberData.default.attr('number'),

    /**
     * changePercentMarginOfError is change in percentage margin of error from
     * previous year to current year
     */
    changePercentMarginOfError: _emberData.default.attr('number'),
    changePercentIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /**
     * changePercentagePoint is the percentage point change as opposed to percent change
     */
    changePercentagePoint: _emberData.default.attr('number'),

    /**
     * changePercentagePointMarginOfError is the margin of error in the percent point calculation
     */
    changePercentagePointMarginOfError: _emberData.default.attr('number'),
    changePercentagePointIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /* =====  End of CHANGE_  ====== */

    /* =============================================
    =                  COMPARISON_                 =
    = comparison refers to the geography that      =
    = a user will compare to their chosen area     =
    = ( e.g. Flushing vs. Queens (comparison area))=
    ============================================= */

    /**
     * comparisonSum: estimate of comparison area
     */
    comparisonSum: _emberData.default.attr('number'),

    /**
     * See "codingThreshold"
     */
    comparisonCodingThreshold: _emberData.default.attr('string'),

    /**
     * comparisonMarginOfError: margin of error of comparison area
     */
    comparisonMarginOfError: _emberData.default.attr('number'),

    /**
     * comparisonCorrelationCoefficient: coefficient of variation of comparison area
     */
    comparisonCorrelationCoefficient: _emberData.default.attr('number'),

    /**
     * comparisonPercent: percentage of comparison area
     */
    comparisonPercent: _emberData.default.attr('number'),

    /**
     * comparisonPercentMarginOfError: percentage margin of error of comparison area
     */
    comparisonPercentMarginOfError: _emberData.default.attr('number'),
    comparisonIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /* =====  End of COMPARISON_  ====== */

    /* =============================================
    =                   DIFFERENCE_                =
    = difference refers to the differnce in values =
    = between the chosen area and the comparison   =
    = area (Flushing - Queens = difference)        =
    ============================================= */

    /**
     * See "sum"
     */
    differenceSum: _emberData.default.attr('number'),

    /**
     * See "m"
     */
    differenceMarginOfError: _emberData.default.attr('number'),
    differenceIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /**
     * See "percent"
     */
    differencePercent: _emberData.default.attr('number'),

    /**
     * See "percentMarginOfError"
     */
    differencePercentMarginOfError: _emberData.default.attr('number'),
    differencePercentIsReliable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),

    /* =====  End of DIFFERENCE_  ====== */

    /**
     * Selection is a computed property for convenience of grouping
     * all un-prefix selection-specific aggregate values.
     * @returns {Object} selection
     */
    selection: (0, _object.computed)('sum', 'marginOfError', 'cv', 'percent', 'percentMarginOfError', 'isReliable', 'codingThreshold', function () {
      const {
        sum,
        marginOfError,
        correlationCoefficient,
        percent,
        percentMarginOfError,
        isReliable,
        codingThreshold: direction
      } = this.getProperties('sum', 'marginOfError', 'cv', 'percent', 'percentMarginOfError', 'isReliable', 'codingThreshold');
      return {
        sum,
        marginOfError,
        correlationCoefficient,
        percent,
        percentMarginOfError,
        isReliable,
        direction
      };
    }),

    /**
     * Comparison is a computed property that nests all 'comparison'-prefixed
     * properties, and aliases them to their unprefixed names
     * @returns {Object} comparison
     */
    comparison: (0, _object.computed)('comparisonSum', 'comparisonMarginOfError', 'comparisonCorrelationCoefficient', 'comparisonPercent', 'comparisonPercentMarginOfError', 'comparisonIsReliable', 'comparisonCodingThreshold', function () {
      const {
        comparisonSum: sum,
        comparisonMarginOfError: marginOfError,
        comparisonCorrelationCoefficient: correlationCoefficient,
        comparisonPercent: percent,
        comparisonPercentMarginOfError: percentMarginOfError,
        comparisonIsReliable: isReliable,
        comparisonCodingThreshold: direction
      } = this.getProperties('comparisonSum', 'comparisonMarginOfError', 'comparisonCorrelationCoefficient', 'comparisonPercent', 'comparisonPercentMarginOfError', 'comparisonIsReliable', 'comparisonCodingThreshold');
      return {
        sum,
        marginOfError,
        correlationCoefficient,
        percent,
        percentMarginOfError,
        isReliable,
        direction
      };
    }),

    /**
     * Previous is a computed property that nests all 'previous'-prefixed
     * properties, and aliases them to their unprefixed names
     * @returns {Object} previous
     */
    previous: (0, _object.computed)('previousSum', 'previousCodingThreshold', 'previousMarginOfError', 'previousCorrelationCoefficient', 'previousPercent', 'previousPercentMarginOfError', 'previousIsReliable', 'previousDifferenceSum', 'previousDifferenceMarginOfError', 'previousDifferenceIsReliable', 'previousDifferencePercent', 'previousDifferencePercentMarginOfError', 'previousDifferencePercentIsReliable', 'previousChangePercentIsReliable', 'previousChangePercentagePoint', 'previousChangePercentagePointMarginOfError', 'previousChangePercentagePointIsReliable', function () {
      const {
        previousSum: sum,
        previousCodingThreshold: direction,
        // TODO: fix naming
        previousMarginOfError: marginOfError,
        previousCorrelationCoefficient: correlationCoefficient,
        previousPercent: percent,
        previousPercentMarginOfError: percentMarginOfError,
        previousIsReliable: isReliable,
        previousDifferenceSum: differenceSum,
        previousDifferenceMarginOfError: differenceMarginOfError,
        previousDifferenceIsReliable: differenceIsReliable,
        previousDifferencePercent: differencePercent,
        previousDifferencePercentMarginOfError: differencePercentMarginOfError,
        previousDifferencePercentIsReliable: differencePercentIsReliable,
        previousChangePercentIsReliable: changePercentIsReliable,
        previousChangePercentagePoint: changePercentagePoint,
        previousChangePercentagePointMarginOfError: changePercentagePointMarginOfError,
        previousChangePercentagePointIsReliable: changePercentagePointIsReliable
      } = this.getProperties('previousSum', 'previousCodingThreshold', 'previousMarginOfError', 'previousCorrelationCoefficient', 'previousPercent', 'previousPercentMarginOfError', 'previousIsReliable', 'previousDifferenceSum', 'previousDifferenceMarginOfError', 'previousDifferenceIsReliable', 'previousDifferencePercent', 'previousDifferencePercentMarginOfError', 'previousDifferencePercentIsReliable', 'previousChangePercentIsReliable', 'previousChangePercentagePoint', 'previousChangePercentagePointMarginOfError', 'previousChangePercentagePointIsReliable');
      return {
        sum,
        direction,
        marginOfError,
        correlationCoefficient,
        percent,
        percentMarginOfError,
        isReliable,
        differenceSum,
        differenceMarginOfError,
        differenceIsReliable,
        differencePercent,
        differencePercentMarginOfError,
        differencePercentIsReliable,
        changePercentIsReliable,
        changePercentagePoint,
        changePercentagePointMarginOfError,
        changePercentagePointIsReliable
      };
    }),
    previousComparison: (0, _object.computed)('previousComparisonSum', 'previousComparisonCodingThreshold', 'previousComparisonMarginOfError', 'previousComparisonCorrelationCoefficient', 'previousComparisonPercent', 'previousComparisonPercentMarginOfError', 'previousComparisonIsReliable', function () {
      const {
        previousComparisonSum: sum,
        previousComparisonCodingThreshold: direction,
        // TODO: fix namespace conflict
        previousComparisonMarginOfError: marginOfError,
        previousComparisonCorrelationCoefficient: correlationCoefficient,
        previousComparisonPercent: percent,
        previousComparisonPercentMarginOfError: percentMarginOfError,
        previousComparisonIsReliable: isReliable
      } = this.getProperties('previousComparisonSum', 'previousComparisonCodingThreshold', 'previousComparisonMarginOfError', 'previousComparisonCorrelationCoefficient', 'previousComparisonPercent', 'previousComparisonPercentMarginOfError', 'previousComparisonIsReliable');
      return {
        sum,
        direction,
        marginOfError,
        correlationCoefficient,
        percent,
        percentMarginOfError,
        isReliable
      };
    }),

    /**
     * shouldHideDeltaPercent is a special exception to displaying the percentage change.
     * @returns {Boolean}
     */
    shouldHideDeltaPercent: (0, _object.computed)('isSpecial', 'estimate', 'comparisonSum', function () {
      const {
        isSpecial,
        sum,
        comparisonSum
      } = this.getProperties('isSpecial', 'estimate', 'comparisonSum');
      return isSpecial || sum + comparisonSum === 0;
    })
  });

  _exports.default = _default;
});