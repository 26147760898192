define("labs-nyc-factfinder/table-config/acs/social/language-spoken-at-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population 5 years and over',
    data: 'pop5pl1',
    highlight: true
  }, {
    title: 'English only',
    data: 'engonly1'
  }, {
    title: 'Language other than English',
    data: 'lgoeng1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgoenlep1',
    indent: 2
  }, {
    divider: true
  }, {
    title: 'Spanish',
    data: 'lgsp1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgsplep1'
  }, {
    title: 'French, Haitian, or Cajun',
    data: 'lgfr1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgfrlep1'
  }, {
    title: 'German or other West Germanic languages',
    data: 'lggrm1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lggrmlep1'
  }, {
    title: 'Russian, Polish, or other Slavic languages',
    data: 'lgruspol1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgrsplep1'
  }, {
    title: 'Other Indo-European languages',
    data: 'lgoieu1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgoielep1'
  }, {
    title: 'Korean',
    data: 'lgkor1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgkorlep1'
  }, {
    title: 'Chinese (incl. Mandarin, Cantonese)',
    data: 'lgchi1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgchilep1'
  }, {
    title: 'Vietnamese',
    data: 'lgviet1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgvielep1'
  }, {
    title: 'Tagalog',
    data: 'lgtag1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgtaglep1'
  }, {
    title: 'Other Asian and Pacific Island languages',
    data: 'lgoasn1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgoanlep1'
  }, {
    title: 'Arabic',
    data: 'lgarab1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgarblep1'
  }, {
    title: 'Other and unspecified languages',
    data: 'lgolg1',
    highlight: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgolglep1'
  }, {
    divider: true
  }, {
    title: 'Population 5 years and over',
    data: 'pop5pl2',
    highlight: true
  }, {
    title: 'English only',
    data: 'engonly2'
  }, {
    title: 'Language other than English',
    data: 'lgoeng2'
  }, {
    title: 'Spanish',
    data: 'lgsp2',
    indent: 1
  }, {
    title: 'French, Haitian, or Cajun',
    data: 'lgfr2',
    indent: 1
  }, {
    title: 'German or other West Germanic languages',
    data: 'lggrm2',
    indent: 1
  }, {
    title: 'Russian, Polish, or other Slavic languages',
    data: 'lgruspol2',
    indent: 1
  }, {
    title: 'Other Indo-European languages',
    data: 'lgoieu2',
    indent: 1
  }, {
    title: 'Korean',
    data: 'lgkor2',
    indent: 1
  }, {
    title: 'Chinese (incl. Mandarin, Cantonese)',
    data: 'lgchi2',
    indent: 1
  }, {
    title: 'Vietnamese',
    data: 'lgviet2',
    indent: 1
  }, {
    title: 'Tagalog',
    data: 'lgtag2',
    indent: 1
  }, {
    title: 'Other Asian and Pacific Island languages',
    data: 'lgoasn2',
    indent: 1
  }, {
    title: 'Arabic',
    data: 'lgarab2',
    indent: 1
  }, {
    title: 'Other and unspecified languages',
    data: 'lgolg2',
    indent: 1
  }, {
    divider: true
  }, {
    title: 'Speak English less than "very well"',
    data: 'lgoenlep2',
    highlight: true
  }, {
    title: 'Spanish',
    data: 'lgsplep2'
  }, {
    title: 'French, Haitian, or Cajun',
    data: 'lgfrlep2'
  }, {
    title: 'German or other West Germanic languages',
    data: 'lggrmlep2'
  }, {
    title: 'Russian, Polish, or other Slavic languages',
    data: 'lgrsplep2'
  }, {
    title: 'Other Indo-European languages',
    data: 'lgoielep2'
  }, {
    title: 'Korean',
    data: 'lgkorlep2'
  }, {
    title: 'Chinese (incl. Mandarin, Cantonese)',
    data: 'lgchilep2'
  }, {
    title: 'Vietnamese',
    data: 'lgvielep2'
  }, {
    title: 'Tagalog',
    data: 'lgtaglep2'
  }, {
    title: 'Other Asian and Pacific Island languages',
    data: 'lgoanlep2'
  }, {
    title: 'Arabic',
    data: 'lgarblep2'
  }, {
    title: 'Other and unspecified languages',
    data: 'lgolglep2'
  }];
  _exports.default = _default;
});