define("labs-nyc-factfinder/components/data-table-column-group", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    reliabilityMode: false,
    model: null,
    actions: {
      logModel() {
        window.logModel = this.get('model');
      }

    }
  });

  _exports.default = _default;
});