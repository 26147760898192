define("labs-nyc-factfinder/table-config/acs/social/residence-1-year-ago", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population 1 year and over',
    highlight: true,
    data: 'pop1pl'
  }, {
    title: 'Same house',
    data: 'smhs'
  }, {
    title: 'Lived in a different house',
    data: 'dfhs1'
  }, {
    divider: true
  }, {
    title: 'Population 1 year and over that lived in a different house',
    highlight: true,
    data: 'dfhs2'
  }, {
    title: 'Different house in the U.S.',
    data: 'dfhsus'
  }, {
    indent: 1,
    title: 'Same county',
    data: 'dfhssmcnt'
  }, {
    indent: 1,
    title: 'Different county',
    data: 'dfhsdfcnt'
  }, {
    indent: 2,
    title: 'Within New York City',
    data: 'dhdfcntnyc'
  }, {
    indent: 2,
    title: 'Outside New York City',
    data: 'dhnonyc'
  }, {
    title: 'Abroad',
    data: 'abroad'
  }];
  _exports.default = _default;
});