define("labs-nyc-factfinder/services/scroller", ["exports", "ember", "ember-scroll-to/services/scroller"], function (_exports, _ember, _scroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RSVP
  } = _ember.default;

  var _default = _scroller.default.extend({
    scrollVertical(target) {
      let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new RSVP.Promise((resolve, reject) => {
        // workaround for issue https://github.com/NYCPlanning/labs-nyc-factfinder/issues/304
        const scrollable = _ember.default.$('#profile-content');

        scrollable.animate({
          scrollTop: scrollable.scrollTop() - scrollable.offset().top + this.getVerticalCoord(target, opts.offset)
        }, opts.duration || this.get('duration'), opts.easing || this.get('easing'), opts.complete).promise().then(resolve, reject);
      });
    }

  });

  _exports.default = _default;
});