define("labs-nyc-factfinder/table-config/acs/social/marital-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Males 15 years and over',
    highlight: true,
    data: 'ms_m15pl'
  }, {
    title: 'Never married',
    data: 'ms_mnvmrd'
  }, {
    title: 'Now married, except separated',
    data: 'ms_mmrdsp'
  }, {
    title: 'Separated',
    data: 'ms_msp'
  }, {
    title: 'Widowed',
    data: 'ms_mwd'
  }, {
    title: 'Divorced',
    data: 'ms_mdvcd'
  }, {
    divider: true
  }, {
    title: 'Females 15 years and over',
    highlight: true,
    data: 'ms_f15pl'
  }, {
    title: 'Never married',
    data: 'ms_fnvmrd'
  }, {
    title: 'Now married, except separated',
    data: 'ms_fmrdsp'
  }, {
    title: 'Separated',
    data: 'ms_fsp'
  }, {
    title: 'Widowed',
    data: 'ms_fwd'
  }, {
    title: 'Divorced',
    data: 'ms_fdvcd'
  }];
  _exports.default = _default;
});