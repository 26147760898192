define("labs-nyc-factfinder/chart-config/acs/housing/gross-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'gru500',
    label: 'Less than $500'
  }, {
    property: 'gr500t999',
    label: '$500 to $999'
  }, {
    property: 'gr1kt14k',
    label: '$1,000 to $1499'
  }, {
    property: 'gr15kt19k',
    label: '$1,500 to $1,999'
  }, {
    property: 'gr20kt24k',
    label: '$2,000 to $2,499'
  }, {
    property: 'gr25kt29k',
    label: '$2,500 to $2,999'
  }, {
    property: 'gr3kpl',
    label: '$3,000 or more'
  }];
  _exports.default = _default;
});