define("labs-nyc-factfinder/table-config/acs/economic/health-insurance-coverage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Civilian noninstitutionalized population',
    highlight: true,
    data: 'cvnipop2'
  }, {
    title: 'With health insurance coverage',
    data: 'hins'
  }, {
    indent: 1,
    title: 'With private health insurance',
    data: 'pvhins'
  }, {
    indent: 1,
    title: 'With public coverage',
    data: 'pbhins'
  }, {
    title: 'No health insurance coverage',
    data: 'nhins'
  }, {
    divider: true
  }, {
    title: 'Civilian noninstitutionalized population under 18 years',
    highlight: true,
    data: 'cvniu18_2'
  }, {
    title: 'No health insurance coverage',
    data: 'u18nhins'
  }, {
    divider: true
  }, {
    title: 'Civilian noninstitutionalized population 18 to 64 years',
    data: 'cni1864_2',
    special: true
  }, {
    indent: 1,
    title: 'In labor force',
    data: 'cvlf18t64',
    special: true
  }, {
    indent: 3,
    title: 'Employed',
    highlight: true,
    data: 'cvlfem'
  }, {
    indent: 3,
    title: 'With health insurance coverage',
    data: 'emhins'
  }, {
    indent: 4,
    title: 'With private health insurance',
    data: 'empvhins'
  }, {
    indent: 4,
    title: 'With public coverage',
    data: 'empbhins'
  }, {
    indent: 3,
    title: 'No health insurance coverage',
    data: 'emnhins'
  }, {
    indent: 3,
    title: 'Unemployed',
    highlight: true,
    data: 'uem'
  }, {
    indent: 3,
    title: 'With health insurance coverage',
    data: 'uemhins'
  }, {
    indent: 4,
    title: 'With private health insurance',
    data: 'uempvhins'
  }, {
    indent: 4,
    title: 'With public coverage',
    data: 'uempbhins'
  }, {
    indent: 3,
    title: 'No health insurance coverage',
    data: 'uemnhins'
  }, {
    indent: 1,
    title: 'Not in labor force',
    highlight: true,
    data: 'nlf2'
  }, {
    indent: 1,
    title: 'With health insurance coverage',
    data: 'nlfhins'
  }, {
    indent: 2,
    title: 'With private health insurance',
    data: 'nlfpvhins'
  }, {
    indent: 2,
    title: 'With public coverage',
    data: 'nlfpbhins'
  }, {
    indent: 1,
    title: 'No health insurance coverage',
    data: 'nlfnhins'
  }];
  _exports.default = _default;
});