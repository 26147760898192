define("labs-nyc-factfinder/chart-config/census/housing-tenure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'oochu_1',
    label: 'Owner-occupied housing units'
  }, {
    property: 'rochu_1',
    label: 'Renter-occupied housing units'
  }];
  _exports.default = _default;
});