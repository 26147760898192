define("labs-nyc-factfinder/layers/selected-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const selectedFeatures = {
    fill: {
      id: 'selected-fill',
      type: 'fill',
      source: 'selected-features',
      paint: {
        'fill-opacity': 0.4,
        'fill-color': 'rgba(59, 101, 216, 1)'
      }
    }
  };
  var _default = selectedFeatures;
  _exports.default = _default;
});