define("labs-nyc-factfinder/templates/tutorial", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tvEebe0i",
    "block": "[[[10,0],[14,0,\"grid-container\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"grid-x grid-padding-y\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"cell\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"responsive-embed widescreen\"],[12],[1,\"\\n\\t\\t\\t    \"],[10,\"iframe\"],[14,\"src\",\"https://www.youtube.com/embed/iSeJ-APuiH8\"],[14,\"height\",\"315\"],[14,\"width\",\"560\"],[14,\"allowfullscreen\",\"\"],[14,\"frameborder\",\"0\"],[12],[1,\"\\n\\t\\t\\t    \"],[13],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "labs-nyc-factfinder/templates/tutorial.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});