define("labs-nyc-factfinder/utils/carto", ["exports", "rsvp", "fetch", "labs-nyc-factfinder/config/environment"], function (_exports, _rsvp, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.buildSqlUrl = void 0;
  let {
    cartoUsername = 'planninglabs'
  } = _environment.default;

  const buildTemplate = function (config) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'mvt';
    // eslint-disable-line
    const protocol = location.protocol.slice(0, -1); // eslint-disable-line

    const {
      layergroupid
    } = config;
    const {
      subdomains,
      url
    } = config.cdn_url.templates[protocol];
    const subdomain = subdomains[Math.floor(Math.random() * subdomains.length)];
    const renderedUrl = url.replace('{s}', subdomain);
    return `${renderedUrl}/${cartoUsername}/api/v1/map/${layergroupid}/{z}/{x}/{y}.${type}`;
  };

  const buildSqlUrl = function (cleanedQuery) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
    let method = arguments.length > 2 ? arguments[2] : undefined;
    // eslint-disable-line
    let url = `https://${cartoUsername}.carto.com/api/v2/sql`;
    url += method === 'get' ? `?q=${cleanedQuery}&format=${format}` : '';
    return url;
  };

  _exports.buildSqlUrl = buildSqlUrl;
  const carto = {
    SQL(query) {
      let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
      let method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'get';
      const cleanedQuery = query.replace('\n', '');
      const url = buildSqlUrl(cleanedQuery, format, method);
      let fetchOptions = {};

      if (method === 'post') {
        fetchOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          },
          body: `q=${cleanedQuery}&format=${format}`
        };
      }

      return (0, _fetch.default)(url, fetchOptions).then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Not found');
      }).then(d => {
        // eslint-disable-line
        return format === 'json' ? d.rows : d;
      });
    },

    getVectorTileTemplate(sourceLayers) {
      const CartoCSS = '#layer { polygon-fill: #FFF; }';
      const layers = sourceLayers.map(sourceLayer => {
        const {
          id,
          sql
        } = sourceLayer;
        return {
          id,
          type: 'mapnik',
          options: {
            cartocss_version: '2.3.0',
            cartocss: CartoCSS,
            sql
          }
        };
      });
      const params = {
        version: '1.3.0',
        layers
      };
      return new _rsvp.Promise((resolve, reject) => {
        (0, _fetch.default)(`https://${cartoUsername}.carto.com/api/v1/map`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        }).catch(err => reject(err)).then(response => response.json()).then(json => {
          resolve(buildTemplate(json));
        });
      });
    }

  };
  var _default = carto;
  _exports.default = _default;
});