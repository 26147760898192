define("labs-nyc-factfinder/chart-config/acs/demographic/race-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'hsp1',
    label: 'Hispanic'
  }, {
    property: 'wtnh',
    label: 'White nonhispanic'
  }, {
    property: 'blnh',
    label: 'Black nonhispanic'
  }, {
    property: 'asnnh',
    label: 'Asian nonhispanic'
  }];
  _exports.default = _default;
});