define("labs-nyc-factfinder/templates/components/layer-menu-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gDkJIt4y",
    "block": "[[[10,0],[14,0,\"layer-group-toggle-title\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"label\",\"active\",\"icon\",\"hideIcon\"],[[30,0,[\"model\",\"legend\",\"label\"]],[30,0,[\"model\",\"visible\"]],[30,0,[\"model\",\"legend\",\"icon\"]],true]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"icon\",\"transform\"],[\"question-circle\",\"shrink-2\"]]]],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"side\",\"text\"],[\"bottom-end\",\"Neighborhood Tabulation Area maps for selected variables\"]]]],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"visible\"]],[[[1,\"    \"],[10,0],[14,0,\"layer-menu-item-content\"],[12],[18,1,[[28,[37,5],null,[[\"model\",\"updatePaintFor\"],[[30,0,[\"model\"]],[28,[37,6],[[30,0],\"updatePaintFor\"],null]]]]]],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"labs-ui/layer-group-toggle\",\"fa-icon\",\"ember-tooltip\",\"if\",\"yield\",\"hash\",\"action\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/layer-menu-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});