define("labs-nyc-factfinder/routes/data", ["exports", "@ember/routing/route", "labs-nyc-factfinder/layer-groups"], function (_exports, _route, _layerGroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The data route is responsible for statically importing layer groups
   * and displaying their information through the model hook.
   *
   * See See https://api.emberjs.com/ember/release/classes/Route
   */
  var _default = _route.default.extend({
    /**
     * Used to statically pull in layer groups, iterated over in the template.
     * TODO: this should be using the real layer group data.
     */
    model() {
      return Object.keys(_layerGroups.default).map(key => _layerGroups.default[key]);
    }

  });

  _exports.default = _default;
});