define("labs-nyc-factfinder/table-config/acs/economic/occupation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Civilian employed population 16 years and over',
    highlight: true,
    data: 'cvem16pl2'
  }, {
    title: 'Management, business, science, and arts',
    data: 'mgbsciart'
  }, {
    title: 'Service',
    data: 'srvc'
  }, {
    title: 'Sales and office',
    data: 'salesoff'
  }, {
    title: 'Natural resources, construction, and maintenance',
    data: 'nrcnstmnt'
  }, {
    title: 'Production, transportation, and material moving',
    data: 'prdtrnsmm'
  }];
  _exports.default = _default;
});