define("labs-nyc-factfinder/table-config/acs/social/veteran-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Civilian population 18 years and over',
    highlight: true,
    data: 'cvpop18pl'
  }, {
    title: 'Veterans',
    data: 'cvvet18pl'
  }];
  _exports.default = _default;
});