define("labs-nyc-factfinder/services/selection", ["exports", "@ember/service", "@ember/object", "labs-nyc-factfinder/utils/carto", "labs-nyc-factfinder/layers/point-layer", "labs-nyc-factfinder/layers/search-result-layer", "labs-nyc-factfinder/queries/summary-levels", "ember-concurrency", "labs-nyc-factfinder/config/environment", "labs-nyc-factfinder/utils/fetch-selection-summary"], function (_exports, _service, _object, _carto, _pointLayer, _searchResultLayer, _summaryLevels, _emberConcurrency, _environment, _fetchSelectionSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    DEFAULT_SELECTION
  } = _environment.default;
  const EMPTY_GEOJSON = {
    type: 'FeatureCollection',
    features: []
  };
  const SUM_LEVEL_DICT = {
    blocks: {
      sql: _summaryLevels.default.blocks(false),
      id: 'bctcb2020',
      tracts: 'boroct2020'
    },
    tracts: {
      sql: _summaryLevels.default.tracts(false),
      id: 'boroct2020',
      ntas: 'ntacode',
      blocks: 'boroct2020'
    },
    cdtas: {
      sql: _summaryLevels.default.cdtas(false),
      id: 'cdta2020',
      cdtas: 'cdta2020',
      ntas: 'nta2020',
      blocks: 'boroct2020'
    },
    districts: {
      sql: _summaryLevels.default.districts(false),
      districts: 'borocd'
    },
    ccds: {
      sql: _summaryLevels.default.ccds(false),
      id: 'coundist'
    },
    boroughs: {
      sql: _summaryLevels.default.boroughs(false),
      id: 'borocode',
      boroughs: 'borocode'
    },
    cities: {
      sql: _summaryLevels.default.cities(false),
      cities: 'id'
    },
    ntas: {
      sql: _summaryLevels.default.ntas(false),
      id: 'nta2020',
      tracts: 'ntacode'
    },
    pumas: {
      sql: _summaryLevels.default.pumas(false)
    }
  };

  const findUniqueBy = function (collection, id) {
    return collection.uniqBy(`properties.${id}`).mapBy(`properties.${id}`);
  };

  const findUniqueByGeoId = function (collection) {
    return collection.uniqBy(`properties.geoid`).mapBy(`properties.geoid`);
  };

  var _default = _service.default.extend({
    current: DEFAULT_SELECTION,
    summaryLevel: 'tracts',
    // tracts, blocks, ntas, pumas
    comparator: '0',
    reliability: false,
    metrics: (0, _service.inject)('metrics'),
    store: (0, _service.inject)(),
    currentMapInstance: null,
    selectedCount: (0, _object.computed)('current.[]', function () {
      const currentSelected = this.get('current');
      return currentSelected.features.length;
    }),
    selectedSummary: (0, _object.computed)('current.[]', function () {
      const currentSelectedSummary = this.get('current'); // City level returns 'New York City' but database id is 0

      const output = currentSelectedSummary.features.map(_ref => {
        let {
          properties
        } = _ref;
        return properties.geoid;
      });
      return output[0] === "New York City" ? ['0'] : output;
    }),
    sortedLabels: (0, _object.computed)('current', function () {
      const currentSelected = this.get('current');
      const {
        features
      } = currentSelected;
      const bronx = features.filter(d => d.properties.borocode === '2');
      const brooklyn = features.filter(d => d.properties.borocode === '3');
      const manhattan = features.filter(d => d.properties.borocode === '1');
      const queens = features.filter(d => d.properties.borocode === '4');
      const statenisland = features.filter(d => d.properties.borocode === '5');
      return [{
        label: 'Bronx',
        features: bronx
      }, {
        label: 'Brooklyn',
        features: brooklyn
      }, {
        label: 'Manhattan',
        features: manhattan
      }, {
        label: 'Queens',
        features: queens
      }, {
        label: 'Staten Island',
        features: statenisland
      }];
    }),
    pointLayer: _pointLayer.default,
    currentAddress: null,
    searchResultFeature: null,
    searchResultLayer: _searchResultLayer.default,
    addressSource: (0, _object.computed)('currentAddress', function () {
      const currentAddress = this.get('currentAddress');
      return {
        type: 'geojson',
        data: {
          type: 'Point',
          coordinates: currentAddress
        }
      };
    }),
    searchResultSource: (0, _object.computed)('searchResultFeature', function () {
      const feature = this.get('searchResultFeature');
      return {
        type: 'geojson',
        data: feature
      };
    }),
    handleSelectedSummaryDataExplode: (0, _emberConcurrency.task)(function* () {
      yield (0, _fetchSelectionSummary.default)('decennial', 'pop1,hunits', this.selectedSummary).then(selectionSummary => this.set('current', { ...this.get('current'),
        selectionSummary: selectionSummary
      }));
    }).restartable(),

    // methods
    handleSummaryLevelToggle(toLevel) {
      const fromLevel = this.get('summaryLevel');

      if (fromLevel !== toLevel) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'selection_geotype',
          'selection_geotype': toLevel
        });
        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory: 'Geotype Selection',
          eventAction: 'Geotype Selection',
          eventLabel: toLevel
        });
      }

      this.set('summaryLevel', toLevel);

      const layerGroupIdMap = level => {
        switch (level) {
          case 'tracts':
            return 'factfinder--census-tracts-2020';

          case 'blocks':
            return 'factfinder--census-blocks';

          case 'cdtas':
            return 'factfinder--cdtas';

          case 'ccds':
            return 'factfinder--ccds';

          case 'districts':
            return 'factfinder--districts';

          case 'ntas':
            return 'factfinder--ntas';

          case 'boroughs':
            return 'factfinder--boroughs';

          case 'cities':
            return 'factfinder--cities';

          default:
            return null;
        }
      };

      const fromLayerGroup = this.get('store').peekRecord('layer-group', layerGroupIdMap(fromLevel));
      const toLayerGroup = this.get('store').peekRecord('layer-group', layerGroupIdMap(toLevel));
      fromLayerGroup.set('visible', false);
      toLayerGroup.set('visible', true); // remove mapbox neighborhood labels if current Level is NTAs

      const map = this.get('currentMapInstance');

      if (map) {
        if (toLevel === 'ntas' || toLevel === 'cdtas') {
          map.setLayoutProperty('subdued_nta_labels', 'visibility', 'none');
        } else {
          map.setLayoutProperty('subdued_nta_labels', 'visibility', 'visible');
        }
      }

      if (this.get('selectedCount')) {
        if (toLevel === 'cities') {
          // If to cities, select all cities
          this.explodeToCity();
        } else if (fromLevel === 'cities') {
          // If from cities, select all items of toLevel
          this.explodeFromCity(toLevel);
        } else if (fromLevel === 'districts' && toLevel === 'districts') {// This is to maintain the selection of districts when switching back to the map page from the data explorer page
        } else if (fromLevel === 'districts' || toLevel === 'districts') {
          // District transitions should clear selection
          this.clearSelection();
        } else if (fromLevel === 'ccds' && toLevel === 'ccds') {// This is to maintain the selection of ccds when switching back to the map page from the data explorer page
        } else if (fromLevel === 'ccds' || toLevel === 'ccds') {
          // CCD transitions should clear selection
          this.clearSelection();
        } else if (toLevel === 'blocks' && ['cdtas', 'ntas'].includes(fromLevel)) {
          this.explodeToBlocks(fromLevel);
        } else if (fromLevel === 'blocks' && ['cdtas', 'ntas'].includes(toLevel)) {
          this.explodeFromBlocks(toLevel);
        } else {
          this.explode(fromLevel, toLevel);
        }
      } else {
        this.clearSelection();
      }
    },

    explodeToCity() {
      const sqlQuery = `SELECT * FROM (${SUM_LEVEL_DICT['cities'].sql}) a`;

      _carto.default.SQL(sqlQuery, 'geojson').then(json => {
        this.clearSelection();
        this.set('current', json);
      }).then(() => {
        this.handleSelectedSummaryDataExplode.perform();
      });
    },

    explodeFromCity(toLevel) {
      const sqlQuery = `SELECT * FROM (${SUM_LEVEL_DICT[toLevel].sql}) a`;

      _carto.default.SQL(sqlQuery, 'geojson').then(json => {
        this.clearSelection();
        this.set('current', json);
      }).then(() => {
        this.handleSelectedSummaryDataExplode.perform();
      });
    },

    // transition between geometry levels using attributes
    explode(fromLevel, toLevel) {
      if (fromLevel !== toLevel) {
        const crossWalkFromColumn = SUM_LEVEL_DICT[fromLevel].id;
        var crossWalkToTable = SUM_LEVEL_DICT['tracts'].sql;

        if (fromLevel === 'blocks' || toLevel === 'blocks') {
          crossWalkToTable = SUM_LEVEL_DICT['blocks'].sql;
        }

        var filterFromLevelIds = findUniqueByGeoId(this.get('current.features')).join("','");

        if (fromLevel === 'blocks') {
          filterFromLevelIds = findUniqueBy(this.get('current.features'), 'bctcb2020').join("','");
        }

        const sqlQuery = `SELECT * FROM (${SUM_LEVEL_DICT[toLevel].sql}) a WHERE ${SUM_LEVEL_DICT[toLevel].id} IN (SELECT ${SUM_LEVEL_DICT[toLevel].id}  FROM (${crossWalkToTable}) a WHERE ${crossWalkFromColumn} IN ('${filterFromLevelIds}'))`;

        _carto.default.SQL(sqlQuery, 'geojson').then(json => {
          this.clearSelection();
          this.set('current', json);
        }).then(() => {
          this.handleSelectedSummaryDataExplode.perform();
        });
      }
    },

    // transition between geometry levels using attributes
    explodeToBlocks(fromLevel) {
      if (fromLevel !== 'blocks') {
        const crossWalkFromColumn = SUM_LEVEL_DICT[fromLevel].id;
        const crossWalkToTable = SUM_LEVEL_DICT['tracts'].sql;
        const filterFromLevelIds = findUniqueByGeoId(this.get('current.features')).join("','");
        const sqlQuery = `SELECT * FROM (${SUM_LEVEL_DICT['blocks'].sql}) a WHERE ${SUM_LEVEL_DICT['tracts'].id} IN (SELECT ${SUM_LEVEL_DICT['tracts'].id}  FROM (${crossWalkToTable}) a WHERE ${crossWalkFromColumn} IN ('${filterFromLevelIds}'))`;

        _carto.default.SQL(sqlQuery, 'geojson').then(json => {
          this.clearSelection();
          this.set('current', json);
        }).then(() => {
          this.handleSelectedSummaryDataExplode.perform();
        });
      }
    },

    // transition between geometry levels using attributes
    explodeFromBlocks(toLevel) {
      if ('blocks' !== toLevel) {
        const crossWalkFromColumn = SUM_LEVEL_DICT['blocks'].id;
        const crossWalkToTable = SUM_LEVEL_DICT['tracts'].sql;
        const tractGeoidColumn = SUM_LEVEL_DICT['tracts'].id;
        const filterFromLevelIds = findUniqueBy(this.get('current.features'), 'bctcb2020').join("','");
        const sqlQuery = `SELECT * FROM (${SUM_LEVEL_DICT[toLevel].sql}) a WHERE ${SUM_LEVEL_DICT[toLevel].id} IN (SELECT ${SUM_LEVEL_DICT[toLevel].id}  FROM (${crossWalkToTable}) a WHERE ${tractGeoidColumn} IN (SELECT ${tractGeoidColumn} FROM (${SUM_LEVEL_DICT['blocks'].sql}) a WHERE ${crossWalkFromColumn} IN ('${filterFromLevelIds}')))`;

        _carto.default.SQL(sqlQuery, 'geojson').then(json => {
          this.clearSelection();
          this.set('current', json);
        }).then(() => {
          this.handleSelectedSummaryDataExplode.perform();
        });
      }
    },

    getEntireGeoTask: (0, _emberConcurrency.task)(function* (sqlQuery, onTaskComplete) {
      yield _carto.default.SQL(sqlQuery, 'geojson', 'post').then(json => onTaskComplete(json));
    }).restartable(),
    handleSelectedFeatures: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let features = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return function* () {
        let getGeoTasks = [];
        features.forEach(feature => {
          const {
            type,
            geometry,
            properties
          } = feature;

          const inSelection = _this.get('current').features.find(selectedFeature => selectedFeature.properties.geoid === properties.geoid);

          if (inSelection === undefined) {
            if (['boroughs', 'ccds', 'cdtas', 'districts', 'cities'].includes(_this.get('summaryLevel'))) {
              const currentGeographyTable = SUM_LEVEL_DICT[_this.get('summaryLevel')].sql; // Temporary patch: ensure entire geography geojson is used, not just the geometry within clicked tile. 


              const sqlQuery = `
            SELECT * FROM (${currentGeographyTable}) a WHERE geoid = '${properties.geoid}'
          `;
              getGeoTasks.push(_this.getEntireGeoTask.perform(sqlQuery, json => {
                _this.set('current', { ..._this.get('current'),
                  features: _this.get('current').features.concat(json.features)
                });
              }));
            } else {
              _this.get('current').features.push({
                type,
                geometry,
                properties
              });
            }
          } else {
            const newFeatures = _this.get('current').features.filter(selectedFeature => selectedFeature.properties.geoid !== properties.geoid);

            _this.set('current.features', newFeatures);
          }
        });
        yield (0, _emberConcurrency.all)(getGeoTasks);
        yield (0, _fetchSelectionSummary.default)('decennial', 'pop1,hunits', _this.selectedSummary).then(selectionSummary => _this.set('current', {
          type: 'FeatureCollection',
          features: _this.get('current').features,
          selectionSummary: selectionSummary
        }));
      }();
    }).restartable(),

    clearSelection() {
      // not sure why we have to do both of these lines, but it works
      this.set('current', EMPTY_GEOJSON);
      this.set('current.features', []);
    }

  });

  _exports.default = _default;
});