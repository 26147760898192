define("labs-nyc-factfinder/templates/explorer-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "my2xY6l5",
    "block": "[[[10,0],[14,0,\"loading-wrapper\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[10,0],[14,5,\"display:grid;place-items:center center;\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Loading in Progress\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"This may take a few moments...\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-load-animation\"]]",
    "moduleName": "labs-nyc-factfinder/templates/explorer-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});