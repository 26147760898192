define("labs-nyc-factfinder/components/population-pyramid", ["exports", "@ember/object", "d3-selection", "d3-array", "d3-scale", "d3-axis", "d3-transition", "d3-format", "numeral", "labs-nyc-factfinder/components/horizontal-bar"], function (_exports, _object, _d3Selection, _d3Array, _d3Scale, _d3Axis, _d3Transition, _d3Format, _numeral, _horizontalBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line
  const translation = (x, y) => `translate(${x},${y})`;

  var _default = _horizontalBar.default.extend({
    classNames: ['population-pyramid callout'],
    margin: {
      top: 10,
      right: 10,
      bottom: 123,
      left: 10,
      middle: 28
    },
    height: 375,

    createChart() {
      let svg = this.get('svg');
      const margin = this.get('margin');
      const el = this.$();
      const height = this.get('height') - margin.top - margin.bottom;

      if (!svg) {
        svg = (0, _d3Selection.select)(el.get(0)).append('svg').attr('class', 'age-chart').attr('width', '100%').attr('height', margin.top + height + margin.bottom).append('g').attr('class', 'padding-group').attr('transform', translation(margin.left, 15));
        svg.append('g').attr('class', 'male');
        svg.append('g').attr('class', 'female');
        svg.append('g').attr('class', 'axis x-axis-left');
        svg.append('g').attr('class', 'axis x-axis-right');
        svg.append('g').attr('class', 'axis y-axis-left');
      }

      this.set('svg', svg);
      this.updateChart();
    },

    updateChart() {
      const svg = this.get('svg');
      const data = this.get('data');
      const isPrevious = this.get('mode') === 'previous';

      function getByMode(row, maleFemale, variable) {
        const variableAsSuffix = variable[0].toUpperCase() + variable.slice(1, variable.length);
        const variableFullname = isPrevious ? 'previous' + variableAsSuffix : variable;
        return row[maleFemale][variableFullname];
      } // get the largest of largest (percent + percentMarginOfError)


      const maxValue = (0, _d3Array.max)([(0, _d3Array.max)([(0, _d3Array.max)(data, row => getByMode(row, 'male', 'percent') + getByMode(row, 'male', 'percentMarginOfError')), (0, _d3Array.max)(data, row => getByMode(row, 'female', 'percent') + getByMode(row, 'female', 'percentMarginOfError'))]), (0, _d3Array.max)([(0, _d3Array.max)(data, row => getByMode(row, 'male', 'comparisonPercent') + getByMode(row, 'male', 'comparisonPercentMarginOfError')), (0, _d3Array.max)(data, row => getByMode(row, 'female', 'comparisonPercent') + getByMode(row, 'female', 'comparisonPercentMarginOfError'))])]);

      const yAxisFormat = variable => {
        if (variable === 'pop0t5') return 'Under 5';
        if (variable === 'pop85pl') return '85 & Over';
        const range = variable.split('pop')[1].split('t');
        return `${range[0]}-${range[1]}`;
      }; // tooltip renderer


      const toolTip = (d, type) => {
        const percent = getByMode(d, type, 'percent');
        const percentM = getByMode(d, type, 'percentMarginOfError');
        const estimate = getByMode(d, type, 'sum');
        const moe = getByMode(d, type, 'marginOfError');
        return `
        The ${type} population aged ${yAxisFormat((0, _object.get)(d, 'group'))}
        is estimated at ${(0, _numeral.default)(percent).format('0.0%')} <small>(±${(0, _numeral.default)(percentM).format('0.0%')})</small> of the total population,
        or ${(0, _numeral.default)(estimate).format('0,0')} <small>(±${(0, _numeral.default)(moe).format('0,0')})</small> people.
      `;
      };

      let timer; // mouse event handlers

      const handleMouseOver = (d, type) => {
        clearTimeout(timer);
        (0, _d3Selection.selectAll)('.age-chart-tooltip').html(toolTip(d, type));
        (0, _d3Selection.selectAll)(`.bar.${type}.${(0, _object.get)(d, 'group')}`).classed('highlight', true);
      };

      const handleMouseOut = d => {
        (0, _d3Selection.selectAll)(`.bar.${(0, _object.get)(d, 'group')}`).classed('highlight', false);
        timer = setTimeout(() => {
          (0, _d3Selection.selectAll)('.age-chart-tooltip').html('Hover over bars for details about each age group');
        }, 400);
      };

      const el = this.$();
      const elWidth = el.width();
      const margin = this.get('margin');
      const height = this.get('height') - margin.top - margin.bottom;
      const width = elWidth - margin.left - margin.right;
      const regionWidth = width / 2 - margin.middle;
      const pointA = regionWidth;
      const pointB = width - regionWidth;
      svg.attr('width', margin.left + width + margin.right).attr('height', margin.top + height + margin.bottom); // set x and y scale

      const xScale = (0, _d3Scale.scaleLinear)().domain([0, maxValue]).range([0, regionWidth]).nice();
      const yScale = (0, _d3Scale.scaleBand)().domain(data.map(d => (0, _object.get)(d, 'group'))).range([height, 0]).paddingInner(0.2); // DRAW AXES

      const yAxisLeft = (0, _d3Axis.axisRight)().scale(yScale).tickSize(4, 0).tickPadding(margin.middle - 4).tickFormat(yAxisFormat);
      const xAxisRight = (0, _d3Axis.axisBottom)().scale(xScale).ticks(4).tickFormat((0, _d3Format.format)('.0%'));
      const xAxisLeft = (0, _d3Axis.axisBottom)().scale(xScale.copy().range([pointA, 0])).ticks(4).tickFormat((0, _d3Format.format)('.0%'));
      svg.select('.y-axis-left').attr('transform', translation(pointA, 0)).call(yAxisLeft).selectAll('text').style('text-anchor', 'middle');
      svg.select('.x-axis-left').attr('transform', translation(0, height)).call(xAxisLeft);
      svg.select('.x-axis-right').attr('transform', translation(pointB, height)).call(xAxisRight); // draw main bars

      const leftBars = svg.select('.male').attr('transform', translation(pointA, 0)).selectAll('.bar.male').data(data, d => (0, _object.get)(d, 'group'));
      const rightBars = svg.select('.female').attr('transform', translation(pointB, 0)).selectAll('.bar.female').data(data, d => (0, _object.get)(d, 'group'));

      const handleBars = (selection, type) => {
        const widthFunction = d => xScale(getByMode(d, type, 'percent'));

        selection.enter().append('rect').attr('class', d => `bar ${type} ${(0, _object.get)(d, 'group')}`).attr('x', 0).attr('y', d => yScale((0, _object.get)(d, 'group'))).attr('height', yScale.bandwidth()).attr('width', widthFunction).attr('rx', 2).attr('ry', 2).on('mouseover', d => {
          handleMouseOver(d, type);
        }).on('mouseout', handleMouseOut);
        selection.transition().duration(300).attr('width', widthFunction);
        selection.exit().remove();
      };

      handleBars(leftBars, 'male');
      handleBars(rightBars, 'female'); // end draw main bars
      // margin of error bars

      const leftMOEs = svg.select('.male').selectAll('.moe.male').data(data, d => (0, _object.get)(d, 'group'));
      const rightMOEs = svg.select('.female').selectAll('.moe.female').data(data, d => (0, _object.get)(d, 'group'));

      const handleMOEs = (selection, type) => {
        const xFunction = d => {
          if (getByMode(d, type, 'percentMarginOfError') > getByMode(d, type, 'percent')) return 0;
          return xScale(getByMode(d, type, 'percent')) - xScale(getByMode(d, type, 'percentMarginOfError'));
        };

        const widthFunction = d => {
          const defaultWidth = xScale(getByMode(d, type, 'percentMarginOfError')) * 2;

          if (getByMode(d, type, 'percentMarginOfError') > getByMode(d, type, 'percent')) {
            const newWidth = defaultWidth - xScale(getByMode(d, type, 'percentMarginOfError') - getByMode(d, type, 'percent'));
            return newWidth;
          }

          return defaultWidth;
        };

        selection.enter().append('rect').attr('alighnment-baseline', 'middle').attr('class', d => `moe ${type} ${(0, _object.get)(d, 'group')}`).attr('x', xFunction).attr('y', d => yScale((0, _object.get)(d, 'group')) + yScale.bandwidth() / 2 + -3).attr('height', 6).attr('width', widthFunction);
        selection.transition().duration(300).attr('x', xFunction).attr('width', widthFunction);
        selection.exit().remove();
      };

      handleMOEs(leftMOEs, 'male');
      handleMOEs(rightMOEs, 'female'); // end margin of error bars
      // comparison MOE bars

      const leftComparisonMOEs = svg.select('.male').selectAll('.comparisonmoe.male').data(data, d => (0, _object.get)(d, 'group'));
      const rightComparisonMOEs = svg.select('.female').selectAll('.comparisonmoe.female').data(data, d => (0, _object.get)(d, 'group'));

      const handleComparisonMOEs = (selection, type) => {
        const xFunction = d => {
          // eslint-disable-line
          return xScale(getByMode(d, type, 'comparisonPercent')) - xScale(getByMode(d, type, 'comparisonPercentMarginOfError'));
        };

        const widthFunction = d => xScale(getByMode(d, type, 'comparisonPercentMarginOfError')) * 2;

        selection.enter().append('rect').attr('class', d => `comparisonmoe ${type} ${(0, _object.get)(d, 'group')}`).attr('x', xFunction).attr('y', d => yScale((0, _object.get)(d, 'group')) + yScale.bandwidth() / 2 + -0.5).attr('height', 1).attr('width', widthFunction);
        selection.transition().duration(300).attr('x', xFunction).attr('width', widthFunction);
        selection.exit().remove();
      };

      handleComparisonMOEs(leftComparisonMOEs, 'male');
      handleComparisonMOEs(rightComparisonMOEs, 'female'); // end comparison MOE bars
      // comparison dots

      const leftComparisons = svg.select('.male').attr('transform', `${translation(pointA, 0)}scale(-1,1)`).selectAll('.comparison.male').data(data, d => (0, _object.get)(d, 'group'));
      const rightComparisons = svg.select('.female').selectAll('.comparison.female').data(data, d => (0, _object.get)(d, 'group'));

      const handleComparisons = (selection, type) => {
        const cxFunction = d => xScale(getByMode(d, type, 'comparisonPercent'));

        selection.enter().append('circle').attr('class', d => `comparison ${type} ${(0, _object.get)(d, 'group')}`).attr('cx', cxFunction).attr('cy', d => yScale((0, _object.get)(d, 'group')) + yScale.bandwidth() / 2) // yScale.step()
        .attr('r', 2.5);
        selection.transition().duration(300).attr('cx', cxFunction);
        selection.exit().remove();
      };

      handleComparisons(leftComparisons, 'male');
      handleComparisons(rightComparisons, 'female'); // end comparison dots
    }

  });

  _exports.default = _default;
});