define("labs-nyc-factfinder/utils/nest-survey", ["exports", "@ember/object", "d3-collection"], function (_exports, _object, _d3Collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = nestSurvey;

  function nestSurvey(data) {
    for (var _len = arguments.length, keys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      keys[_key - 1] = arguments[_key];
    }

    const {
      length
    } = keys;
    let allKeys = ['dataset', 'category', 'variable'];

    if (length) {
      allKeys = keys;
    }

    return allKeys.reduce((nesting, currentKey) => nesting.key(d => (0, _object.get)(d, currentKey)), (0, _d3Collection.nest)()).rollup(d => d[0]).object(data);
  }
});