define("labs-nyc-factfinder/components/toolbar", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'div',
    classNames: ['toolbar']
  });

  _exports.default = _default;
});