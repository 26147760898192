define("labs-nyc-factfinder/table-config/acs/social/grandparents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Number of grandparents living with own grandchildren under 18 years',
    highlight: true,
    data: 'gplgcu18'
  }, {
    title: 'Responsible for grandchildren',
    data: 'gprgcu18'
  }];
  _exports.default = _default;
});