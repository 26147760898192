define("labs-nyc-factfinder/table-config/census/mutually-exclusive-race---hispanic-origin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    data: 'pop2',
    title: 'Total population',
    highlight: true
  }, {
    data: 'hsp1',
    title: 'Hispanic'
  }, {
    data: 'wnh',
    title: 'White non-Hispanic'
  }, {
    data: 'bnh',
    title: 'Black non-Hispanic'
  }, {
    data: 'anh',
    title: 'Asian non-Hispanic'
  }, {
    data: 'onh',
    title: 'Some other race non-Hispanic'
  }, {
    data: 'twoplnh',
    title: 'Non-Hispanic of two or more races'
  }];
  _exports.default = _default;
});