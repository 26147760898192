define("labs-nyc-factfinder/table-config/acs/economic/index", ["exports", "labs-nyc-factfinder/table-config/acs/economic/class-of-worker", "labs-nyc-factfinder/table-config/acs/economic/commute-to-work", "labs-nyc-factfinder/table-config/acs/economic/earnings", "labs-nyc-factfinder/table-config/acs/economic/employment-status", "labs-nyc-factfinder/table-config/acs/economic/health-insurance-coverage", "labs-nyc-factfinder/table-config/acs/economic/income-and-benefits", "labs-nyc-factfinder/table-config/acs/economic/income_in_past_12_months_is_below_the_poverty_level", "labs-nyc-factfinder/table-config/acs/economic/industry", "labs-nyc-factfinder/table-config/acs/economic/occupation", "labs-nyc-factfinder/table-config/acs/economic/ratio-of-income-to-poverty-level"], function (_exports, _classOfWorker, _commuteToWork, _earnings, _employmentStatus, _healthInsuranceCoverage, _incomeAndBenefits, _income_in_past_12_months_is_below_the_poverty_level, _industry, _occupation, _ratioOfIncomeToPovertyLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    classOfWorker: _classOfWorker.default,
    commuteToWork: _commuteToWork.default,
    earnings: _earnings.default,
    employmentStatus: _employmentStatus.default,
    healthInsuranceCoverage: _healthInsuranceCoverage.default,
    incomeAndBenefits: _incomeAndBenefits.default,
    incomeInPast12MonthsIsBelowThePovertyLevel: _income_in_past_12_months_is_below_the_poverty_level.default,
    industry: _industry.default,
    occupation: _occupation.default,
    ratioOfIncomeToPovertyLevel: _ratioOfIncomeToPovertyLevel.default
  };
  _exports.default = _default;
});