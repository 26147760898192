define("labs-nyc-factfinder/components/comparison-area-selector", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "d3-collection"], function (_exports, _component, _object, _service, _d3Collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ComparisonAreaSelectorComponent = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)(), (_class = class ComparisonAreaSelectorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selection", _descriptor, this);

      _initializerDefineProperty(this, "metrics", _descriptor2, this);
    }

    get nestedComparisonGeoOptions() {
      return (0, _d3Collection.nest)().key(d => d.typelabel).entries(this.args.comparisonGeoOptions).map(d => ({
        groupName: d.key,
        options: d.values
      }));
    }

    updateProperty(_ref) {
      let {
        geoid,
        label
      } = _ref;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'select_comparison_area',
        'select_comparison_area': label
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Profile Settings',
        eventAction: 'Selected Comparison Area',
        eventLabel: label
      });
      this.args.onSelect(geoid);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selection", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProperty"), _class.prototype)), _class));
  _exports.default = ComparisonAreaSelectorComponent;
});