define("labs-nyc-factfinder/components/labs-ui/layer-group-toggle", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      this.get('didInit')(this);
      this.set('icon', []);
      this.set('active', false);
    },

    metrics: (0, _service.inject)('metrics'),
    classNames: ['layer-group-toggle'],
    classNameBindings: ['active'],
    label: null,
    tooltip: '',
    infoLink: '',
    infoLinkIcon: 'external-link-alt',
    tooltipIcon: 'info-circle',
    active: true,
    activeTooltip: '',
    activeTooltipIcon: 'exclamation-triangle',

    didInit() {},

    willDestroyHook() {},

    willDestroy() {
      this.get('willDestroyHook')(this);
    },

    click() {
      this.toggleProperty('active');
      window.dataLayer = window.dataLayer || [];

      if (this.get('label') === "Thematic Maps (NTAs)") {
        window.dataLayer.push({
          'event': 'toggle_thematic_map',
          'toggle': this.get('active')
        });
        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory: 'Thematic Map',
          eventAction: 'Toggle Thematic Map',
          eventLabel: this.get('active')
        });
      } else if (["Subways", "ZIP Codes", "Neighborhood Tabulation Areas (NTAs)", "Community Districts (CDs)", "Community District Tabulation Areas (CDTAs)", "City Council Districts (CCDs)"].includes(this.get('label'))) {
        // Only count toggle on, not toggle off
        if (this.get('active')) {
          window.dataLayer.push({
            'event': 'toggle_map_layer',
            'toggle_map_layer': this.get('label')
          });
          this.get('metrics').trackEvent('GoogleAnalytics', {
            eventCategory: 'Add Map Layers',
            eventAction: 'Toggle Map Layer',
            eventLabel: this.get('label')
          });
        }
      } else {
        // This should not happen, but if it does, the data will be recorded
        window.dataLayer.push({
          'event': 'uncategorized_event',
          'uncatogorized_event': this.get('label')
        });
        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory: 'Uncategorized Event',
          eventAction: 'Uncategorized Event',
          eventLabel: this.get('label')
        });
      }
    }

  });

  _exports.default = _default;
});