define("labs-nyc-factfinder/utils/munge-bar-chart-data", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (config, data, mode) => {
    const isPrevious = mode === 'previous';

    if (isPrevious) {
      return config.map(_ref => {
        let {
          property,
          label
        } = _ref;
        return {
          percent: (0, _object.get)(data, `${property}.previous.percent`),
          sum: (0, _object.get)(data, `${property}.previous.sum`),
          moe: (0, _object.get)(data, `${property}.previous.marginOfError`),
          percentMarginOfError: (0, _object.get)(data, `${property}.previous.percentMarginOfError`),
          comparisonPercent: (0, _object.get)(data, `${property}.previousComparison.percent`),
          comparisonPercentMarginOfError: (0, _object.get)(data, `${property}.previousComparison.percentMarginOfError`),
          group: label,
          classValue: property
        };
      });
    }

    return config.map(_ref2 => {
      let {
        property,
        label
      } = _ref2;
      return {
        percent: (0, _object.get)(data, `${property}.percent`),
        sum: (0, _object.get)(data, `${property}.sum`),
        moe: (0, _object.get)(data, `${property}.marginOfError`),
        percentMarginOfError: (0, _object.get)(data, `${property}.percentMarginOfError`),
        comparisonPercent: (0, _object.get)(data, `${property}.comparisonPercent`),
        comparisonPercentMarginOfError: (0, _object.get)(data, `${property}.comparisonPercentMarginOfError`),
        group: label,
        classValue: property
      };
    });
  };

  _exports.default = _default;
});