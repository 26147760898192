define("labs-nyc-factfinder/chart-config/acs/economic/ratio-of-income-to-poverty-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'pvu50',
    label: 'Under .50'
  }, {
    property: 'pv50t74',
    label: '.50 to .74'
  }, {
    property: 'pv75t99',
    label: '.75 to .99'
  }, {
    property: 'pv100t124',
    label: '1.00 to 1.24'
  }, {
    property: 'pv125t149',
    label: '1.25 to 1.49'
  }, {
    property: 'pv150t174',
    label: '1.50 to 1.74'
  }, {
    property: 'pv175t184',
    label: '1.75 to 1.84'
  }, {
    property: 'pv185t199',
    label: '1.85 to 1.99'
  }, {
    property: 'pv200t299',
    label: '2.00 to 2.99'
  }, {
    property: 'pv300t399',
    label: '3.00 to 3.99'
  }, {
    property: 'pv400t499',
    label: '4.00 to 4.99'
  }, {
    property: 'pv500pl',
    label: '5.00 and over'
  }];
  _exports.default = _default;
});