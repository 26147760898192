define("labs-nyc-factfinder/table-config/acs/housing/rooms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total housing units',
    highlight: true,
    data: 'hu4'
  }, {
    title: '1 room',
    data: 'rms1'
  }, {
    title: '2 rooms',
    data: 'rms2'
  }, {
    title: '3 rooms',
    data: 'rms3'
  }, {
    title: '4 rooms',
    data: 'rms4'
  }, {
    title: '5 rooms',
    data: 'rms5'
  }, {
    title: '6 rooms',
    data: 'rms6'
  }, {
    title: '7 rooms',
    data: 'rms7'
  }, {
    title: '8 rooms',
    data: 'rms8'
  }, {
    title: '9 rooms or more',
    data: 'rms9pl'
  }, {
    title: 'Median rooms',
    tooltip: 'Medians are calculated using linear interpolation, which may result in top-coded values',
    data: 'mdrms',
    special: true,
    decimal: 1
  }];
  _exports.default = _default;
});