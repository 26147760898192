define("labs-nyc-factfinder/table-config/acs/demographic/asian-subgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Asian Alone',
    highlight: true,
    data: 'asn1rc'
  }, {
    title: 'East Asian',
    data: 'asneast'
  }, {
    indent: 1,
    title: 'Chinese, except Taiwanese',
    data: 'asnchinot'
  }, {
    indent: 1,
    title: 'Hmong',
    data: 'asnhmng'
  }, {
    indent: 1,
    title: 'Japanese',
    data: 'asnjpn'
  }, {
    indent: 1,
    title: 'Korean',
    data: 'asnkor'
  }, {
    indent: 1,
    title: 'Mongolian',
    data: 'asnmgol'
  }, {
    indent: 1,
    title: 'Okinawan',
    data: 'asnokinw'
  }, {
    indent: 1,
    title: 'Taiwanese',
    data: 'asntwn'
  }, {
    indent: 1,
    title: 'Other East Asian',
    data: 'asnoeast'
  }, {
    title: 'Southeast Asian',
    data: 'asnseast'
  }, {
    indent: 1,
    title: 'Burmese',
    data: 'asnburm'
  }, {
    indent: 1,
    title: 'Cambodian',
    data: 'asncmb'
  }, {
    indent: 1,
    title: 'Filipino',
    data: 'asnflp'
  }, {
    indent: 1,
    title: 'Indonesian',
    data: 'asnindnsn'
  }, {
    indent: 1,
    title: 'Laotian',
    data: 'asnlao'
  }, {
    indent: 1,
    title: 'Malaysian',
    data: 'asnmalsn'
  }, {
    indent: 1,
    title: 'Mien',
    data: 'asnmien'
  }, {
    indent: 1,
    title: 'Singaporean',
    data: 'asnsingpr'
  }, {
    indent: 1,
    title: 'Thai',
    data: 'asnthai'
  }, {
    indent: 1,
    title: 'Vietnamese',
    data: 'asnvtn'
  }, {
    indent: 1,
    title: 'Other Southeast Asian',
    data: 'asnoseast'
  }, {
    title: 'South Asian',
    data: 'asnsouth'
  }, {
    indent: 1,
    title: 'Asian Indian',
    data: 'asnind'
  }, {
    indent: 1,
    title: 'Bangladeshi',
    data: 'asnbng'
  }, {
    indent: 1,
    title: 'Bhutanese',
    data: 'asnbhutn'
  }, {
    indent: 1,
    title: 'Nepalese',
    data: 'asnnepal'
  }, {
    indent: 1,
    title: 'Pakistani',
    data: 'asnpak'
  }, {
    indent: 1,
    title: 'Sikh',
    data: 'asnsikh'
  }, {
    indent: 1,
    title: 'Sri Lankan',
    data: 'asnsril'
  }, {
    indent: 1,
    title: 'Other South Asian',
    data: 'asnosouth'
  }, {
    title: 'Central Asian',
    data: 'asncntrl'
  }, {
    indent: 1,
    title: 'Kazakh',
    data: 'asnkazakh'
  }, {
    indent: 1,
    title: 'Uzbek',
    data: 'asnuzbek'
  }, {
    indent: 1,
    title: 'Other Central Asian',
    data: 'asnocntrl'
  }, {
    title: 'Other Asian',
    data: 'asnoasn'
  }, {
    title: 'Two or more Asian',
    data: 'asn2pl'
  }];
  _exports.default = _default;
});