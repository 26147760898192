define("labs-nyc-factfinder/components/explorer/source-select-dropdown", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    * @param { fn(Source POJO) } setSource - (Required) Ember Action function that accepts a Source POJO. It will
    * set the selected Explorer source to the passed Source.
    * @param { [] } sources - array of Source objects
  */
  let SourceSelectDropdownComponent = (_dec = (0, _service.inject)(), (_class = class SourceSelectDropdownComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "metrics", _descriptor, this);

      _initializerDefineProperty(this, "open", _descriptor2, this);
    }

    toggleOpen() {
      this.open = !this.open;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'toggle_data_source',
        'toggle': this.open ? 'Opened' : 'Closed'
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Data Source',
        eventAction: 'Toggle Menu',
        eventLabel: this.open ? 'Opened' : 'Closed'
      });
    }

    closeMenu() {
      if (this.open) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'toggle_data_source',
          'toggle': 'Closed'
        });
        this.metrics.trackEvent('GoogleAnalytics', {
          eventCategory: 'Data Source',
          eventAction: 'Toggle Menu',
          eventLabel: 'Closed'
        });
      }

      this.open = false;
    }

    get source() {
      return this.args.sources.find(source => source.selected);
    }

    get censusSources() {
      return this.args.sources.filter(source => source.type === 'census');
    }

    get acsSources() {
      return this.args.sources.filter(source => source.type === 'acs');
    }

    get showACS() {
      if (['districts', 'blocks'].includes(this.args.geotype)) {
        return false;
      }

      return true;
    }

    get showDistrictACSWarning() {
      return this.args.geotype === 'districts';
    }

    get showCCDACSWarning() {
      return this.args.geotype === 'ccds';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype)), _class));
  _exports.default = SourceSelectDropdownComponent;
});