define("labs-nyc-factfinder/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    small: '(max-width: 639px)',
    // medium: '(min-width: 640px) and (max-width: 1023px)',
    medium: '(min-width: 640px)',
    // large: '(min-width: 1024px) and (max-width: 1199px)',
    large: '(min-width: 1024px)',
    // xlarge: '(min-width: 1200px) and (max-width: 1439px)',
    xlarge: '(min-width: 1200px)',
    xxlarge: '(min-width: 1440px)'
  };
  _exports.default = _default;
});