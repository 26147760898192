define("labs-nyc-factfinder/templates/components/horizontal-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S8cksqvH",
    "block": "[[[41,[28,[37,1],[[30,0,[\"data\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"spinner-wrapper loading\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"is-pending\",\"page-load-spinner\",\"yield\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/horizontal-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});