define("labs-nyc-factfinder/templates/components/labs-ui/layer-group-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gcB4AcZs",
    "block": "[[[10,0],[15,0,[29,[\"layer-group-toggle-header layer-\",[28,[37,0],[[30,0,[\"label\"]]],null]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"switch tiny\"],[12],[1,\"\\n    \"],[10,\"input\"],[14,0,\"switch-input\"],[15,\"checked\",[52,[30,0,[\"active\"]],\"checked\"]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n    \"],[10,\"label\"],[14,0,\"switch-paddle\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"show-for-sr\"],[12],[1,\"Toggle Layer Group\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"layer-group-toggle-label\"],[14,\"role\",\"button\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"layer-group-toggle-content\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,0,[\"hideIcon\"]]],null],[30,0,[\"active\"]],[30,0,[\"icon\"]],[30,0,[\"icon\",\"layers\"]],[30,0,[\"icon\",\"layers\"]]],null],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"icon\",\"layers\"]]],null]],null],null,[[[41,[28,[37,7],[[30,2],0],null],[[[1,\"      \"],[10,1],[14,0,\"icon polygon legend-icon\"],[15,5,[29,[\"background-color:\",[30,1,[\"stroke\"]],\";\"]]],[12],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[1,2]],null]],[]],null],[13],[1,\"\\n\\n\"]],[\"layerIcon\",\"index\",\"&default\"],false,[\"dasherize\",\"if\",\"yield\",\"and\",\"not\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/labs-ui/layer-group-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});