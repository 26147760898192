define("labs-nyc-factfinder/chart-config/acs/housing/gross-rent-grapi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'grpiu15',
    label: 'Less than 15.0 percent'
  }, {
    property: 'grpi15t19',
    label: '15.0 to 19.9 percent'
  }, {
    property: 'grpi20t24',
    label: '20.0 to 24.9 percent'
  }, {
    property: 'grpi25t29',
    label: '25.0 to 29.9 percent'
  }, {
    property: 'grpi30pl',
    label: '30.0 percent or more'
  }];
  _exports.default = _default;
});