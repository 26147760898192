define("labs-nyc-factfinder/mirage/config", ["exports", "labs-nyc-factfinder/mirage/static/profile-all-nta", "labs-nyc-factfinder/mirage/static/selection-all-nta", "labs-nyc-factfinder/mirage/helpers/mirage-mapbox-gl-monkeypatch"], function (_exports, _profileAllNta, _selectionAllNta, _mirageMapboxGlMonkeypatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    (0, _mirageMapboxGlMonkeypatch.default)();
    /*
      Config
      Note: these only affect routes defined *after* them!
       this.namespace = '';    // make this `/api`, for example, if your API is namespaced
      this.timing = 400;      // delay for each request, automatically set to 0 during testing
    */

    this.urlPrefix = 'https://factfinder-api.herokuapp.com';
    /*
      Routes
       Shorthand cheatsheet:
      this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
    */

    this.get('/explorer/:id', () => ({
      profile: _profileAllNta.default,
      selection: _selectionAllNta.default
    }));
    this.get('/selection', () => _selectionAllNta.default);
    this.get('/selection/:id', () => _selectionAllNta.default);
    this.get('/profile', () => _profileAllNta.default);
    this.get('/profile/:id', () => _profileAllNta.default);
    /*
      Passthroughs
    */

    this.passthrough('https://labs-layers-api.herokuapp.com/**');
    this.passthrough('https://labs-layers-api-staging.herokuapp.com/v1/base/style.json');
    this.passthrough('https://layers-api.planninglabs.nyc/**');
    this.passthrough('https://layers-api-staging.planninglabs.nyc/**');
    this.passthrough('https://search-api.planninglabs.nyc/**');
    this.passthrough('https://tiles.planninglabs.nyc/**');
    this.passthrough('https://factfinder-api.herokuapp.com/**');
    this.passthrough('https://planninglabs.carto.com/**');
    this.passthrough('https://raw.githubusercontent.com/**');
    this.passthrough('http://raw.githubusercontent.com/**');
  }
});