define("labs-nyc-factfinder/table-config/acs/housing/year-structure-built", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total housing units',
    highlight: true,
    data: 'hu3'
  }, {
    title: 'Built 2010 or later',
    data: 'blt10ltr'
  }, {
    title: 'Built 2000 to 2009',
    data: 'blt00t09'
  }, {
    title: 'Built 1990 to 1999',
    data: 'blt90t99'
  }, {
    title: 'Built 1980 to 1989',
    data: 'blt80t89'
  }, {
    title: 'Built 1970 to 1979',
    data: 'blt70t79'
  }, {
    title: 'Built 1960 to 1969',
    data: 'blt60t69'
  }, {
    title: 'Built 1950 to 1959',
    data: 'blt50t59'
  }, {
    title: 'Built 1940 to 1949',
    data: 'blt40t49'
  }, {
    title: 'Built 1939 or earlier',
    data: 'bltbf39'
  }];
  _exports.default = _default;
});