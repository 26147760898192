define("labs-nyc-factfinder/components/labs-ui/site-header", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['top-bar site-header'],
    closed: true,
    responsiveNav: false,
    responsiveSize: 'large'
  });

  _exports.default = _default;
});