define("labs-nyc-factfinder/templates/components/page-load-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z0hH6btt",
    "block": "[[[10,0],[14,0,\"spinner\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"bounce1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bounce2\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"bounce3\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "labs-nyc-factfinder/templates/components/page-load-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});