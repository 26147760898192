define("labs-nyc-factfinder/table-config/acs/economic/ratio-of-income-to-poverty-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population for whom poverty status is determined',
    highlight: true,
    data: 'poppvu2'
  }, {
    title: 'Under .50',
    data: 'pvu50'
  }, {
    title: '.50 to .74',
    data: 'pv50t74'
  }, {
    title: '.75 to .99',
    data: 'pv75t99'
  }, {
    title: '1.00 to 1.24',
    data: 'pv100t124'
  }, {
    title: '1.25 to 1.49',
    data: 'pv125t149'
  }, {
    title: '1.50 to 1.74',
    data: 'pv150t174'
  }, {
    title: '1.75 to 1.84',
    data: 'pv175t184'
  }, {
    title: '1.85 to 1.99',
    data: 'pv185t199'
  }, {
    title: '2.00 to 2.99',
    data: 'pv200t299'
  }, {
    title: '3.00 to 3.99',
    data: 'pv300t399'
  }, {
    title: '4.00 to 4.99',
    data: 'pv400t499'
  }, {
    title: '5.00 and over',
    data: 'pv500pl'
  }];
  _exports.default = _default;
});