define("labs-nyc-factfinder/queries/radius", ["exports", "labs-nyc-factfinder/queries/summary-levels"], function (_exports, _summaryLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Radius = (summaryLevel, geometry, radius) => {
    const sqlBase = _summaryLevels.default[summaryLevel](false);

    const [lng, lat] = geometry.coordinates;
    return `
    SELECT *
    FROM (${sqlBase}) base
    WHERE ST_Dwithin(
      base.the_geom::geography,
      ST_SetSRID(ST_GeomFromText('POINT(${lng} ${lat})'), 4326)::geography,
      ${radius}
    )
  `;
  };

  var _default = Radius;
  _exports.default = _default;
});