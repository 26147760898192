define("labs-nyc-factfinder/table-config/census/housing-tenure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    data: 'ochu_2',
    title: 'Occupied housing units',
    highlight: true
  }, {
    data: 'oochu_1',
    title: 'Owner-occupied housing units'
  }, {
    data: 'rochu_1',
    title: 'Renter-occupied housing units'
  }];
  _exports.default = _default;
});