define("labs-nyc-factfinder/chart-config/acs/economic/class-of-worker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'prvwswrkr',
    label: 'Private wage and salary workers'
  }, {
    property: 'gvtwrkr',
    label: 'Government workers'
  }, {
    property: 'slfemninc',
    label: 'Self-employed workers in own incorporated business'
  }, {
    property: 'updfmwrkr',
    label: 'Unpaid family workers'
  }];
  _exports.default = _default;
});