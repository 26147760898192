define("labs-nyc-factfinder/utils/fetch-explorer-model", ["exports", "labs-nyc-factfinder/config/environment", "labs-nyc-factfinder/utils/nest-survey"], function (_exports, _environment, _nestSurvey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchExplorerModel;
  const {
    SupportServiceHost
  } = _environment.default; // TODO: Rename endpoint to geography

  const SELECTION_API_URL = function () {
    let geotype = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'cities';
    let geoid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'NYC';
    return `${SupportServiceHost}/selection/${geotype}/${geoid}`;
  };

  const COMPARISON_GEO_OPTIONS_URL = `${SupportServiceHost}/geo-options`;

  async function fetchExplorerModel(store, geotype, geoid) {
    let compareTo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '1';
    let selectionResponse = null;
    let acsSurveyResponse = null;
    let decennialSurveyResponse = null;
    selectionResponse = await fetch(SELECTION_API_URL(geotype, geoid));
    selectionResponse = await selectionResponse.json();
    acsSurveyResponse = await store.query('acsRow', {
      geotype,
      geoid,
      compareTo
    });
    acsSurveyResponse = acsSurveyResponse.toArray();
    decennialSurveyResponse = await store.query('decennialRow', {
      geotype,
      geoid,
      compareTo
    });
    decennialSurveyResponse = decennialSurveyResponse.toArray();
    const nestedACSModel = (0, _nestSurvey.default)(acsSurveyResponse, 'variable');
    const nestedDecennialModel = (0, _nestSurvey.default)(decennialSurveyResponse, 'variable');
    let comparisonGeoOptions = await fetch(COMPARISON_GEO_OPTIONS_URL);
    comparisonGeoOptions = await comparisonGeoOptions.json();
    return {
      geoid,
      geotype,
      selection: selectionResponse,
      acs: nestedACSModel,
      decennial: nestedDecennialModel,
      comparisonGeoOptions
    };
  }
});