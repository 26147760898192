define("labs-nyc-factfinder/table-config/acs/social/u-s--citizenship-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Foreign-born population',
    highlight: true,
    data: 'fb3'
  }, {
    title: 'Naturalized U.S. citizen',
    data: 'fbntlzd'
  }, {
    title: 'Not a U.S. citizen',
    data: 'fbnotczn'
  }];
  _exports.default = _default;
});