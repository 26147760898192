define("labs-nyc-factfinder/components/acs-bar", ["exports", "@ember/service", "@ember/component", "@ember/object", "ember-resize/mixins/resize-aware", "d3-selection", "d3-scale", "d3-array", "d3-axis", "d3-format", "numeral", "labs-nyc-factfinder/utils/munge-bar-chart-data", "d3-transition"], function (_exports, _service, _component, _object, _resizeAware, _d3Selection, _d3Scale, _d3Array, _d3Axis, _d3Format, _numeral, _mungeBarChartData, _d3Transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line
  // eslint-disable-line
  const translation = (x, y) => `translate(${x},${y})`;

  const margin = {
    top: 10,
    right: 10,
    bottom: 60,
    left: 10
  };

  var _default = _component.default.extend(_resizeAware.default, {
    // necessary to get tests to pass https://github.com/mike-north/ember-resize/issues/43
    resizeService: (0, _service.inject)('resize'),
    classNameBindings: ['loading'],
    classNames: ['acs-bar callout'],
    height: 800,
    xMax: null,
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,
    loading: false,

    didRender() {
      if (this.get('data')) {
        this.createChart();
      }
    },

    debouncedDidResize(width) {
      this.set('width', width);
      this.updateChart();
    },

    createChart() {
      let svg = this.get('svg');

      if (!svg) {
        const el = this.$();
        svg = (0, _d3Selection.select)(el.get(0)).append('svg').attr('class', 'chart');
        svg.append('g').attr('class', 'axis axis-bottom');
      }

      this.set('svg', svg);
      this.updateChart();
    },

    updateChart: function updateChart() {
      const svg = this.get('svg');
      const data = this.get('data');
      const config = this.get('config');
      const isDecennial = this.get('survey') === 'census';
      const mode = this.get('mode');
      const el = this.$();
      const elWidth = el.width();
      const rawData = (0, _mungeBarChartData.default)(config, data, mode);
      const additionalHeight = rawData.length > 7 ? 16 * (rawData.length - 7) : 0;
      const height = this.get('height') - margin.top - margin.bottom + additionalHeight;
      const width = elWidth - margin.left - margin.right;
      const textWidth = width * 0.35;
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      const y = (0, _d3Scale.scaleBand)().domain(rawData.map(d => (0, _object.get)(d, 'group'))).range([0, height]).paddingOuter(0).paddingInner(0.1); // function to limit the maximum value to 100%

      function limitMax(newMax) {
        if (newMax > 1) return 1;
        return newMax;
      }

      const xMax = (0, _d3Array.max)([(0, _d3Array.max)(rawData, d => (0, _object.get)(d, 'percent') + (0, _object.getWithDefault)(d, 'percentMarginOfError', 0)), (0, _d3Array.max)(rawData, d => (0, _object.get)(d, 'comparisonPercent') + (0, _object.getWithDefault)(d, 'comparisonPercentMarginOfError', 0))]);
      const x = (0, _d3Scale.scaleLinear)().domain([0, limitMax(xMax)]).range([textWidth, width]);
      /* eslint-disable */
      // wrap bar type label text to multiple lines

      function wrap(textElements, wrapWidth) {
        textElements.each(function () {
          const wrapText = (0, _d3Selection.select)(this);
          const words = wrapText.text().split(/\s+/).reverse();
          let line = [];
          let lineNumber = 0;
          const lineHeight = 0.8;
          const yPosition = wrapText.attr('y');
          let tspan = wrapText.text(null).append('tspan').attr('x', 0).attr('y', yPosition).attr('dy', 0);
          let word;

          while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(' '));

            if (tspan.node().getComputedTextLength() > wrapWidth) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = wrapText.append('tspan').attr('x', 0).attr('y', yPosition).attr('dy', () => {
                const value = (lineNumber += 1) * lineHeight;
                return `${value}em`;
              }).text(word);
            }
          }

          if (lineNumber == 1) {
            wrapText.attr('y', yPosition - 4);
            wrapText.selectAll('tspan').attr('y', yPosition - 4);
          }

          if (lineNumber == 2) {
            wrapText.attr('y', yPosition - 8);
            wrapText.selectAll('tspan').attr('y', yPosition - 8);
          }
        });
      }
      /* eslint-enable */
      // add bar type label text
      // start by deleting all existing labels on every update


      svg.selectAll('.typelabel').remove();
      const groupLabels = svg.selectAll('.typelabel').data(rawData, d => (0, _object.get)(d, 'group'));
      groupLabels.enter().append('text').attr('class', 'label typelabel').attr('text-anchor', 'start').attr('alignment-baseline', 'top').attr('x', 0).attr('width', textWidth).attr('y', d => y((0, _object.get)(d, 'group')) + y.bandwidth() + -9).text(d => `${(0, _object.get)(d, 'group')}`).call(wrap, textWidth); // draw axes

      const bottomAxis = (0, _d3Axis.axisBottom)().scale(x).ticks(5).tickFormat((0, _d3Format.format)('.0%')).tickSizeOuter([0]);
      svg.select('.axis-bottom').attr('transform', translation(0, height + 4)).call(bottomAxis); // draw bars

      const bars = svg.selectAll('.buildingsbar').data(rawData, d => (0, _object.get)(d, 'group'));
      bars.enter().append('rect').attr('class', d => `buildingsbar ${(0, _object.get)(d, 'classValue')}`).attr('fill', d => {
        if ((0, _object.get)(d, 'color')) return (0, _object.get)(d, 'color');
        return '#60acbf';
      }).attr('x', x(0)).attr('width', d => x((0, _object.get)(d, 'percent')) - textWidth).attr('y', d => y((0, _object.get)(d, 'group'))).attr('height', y.bandwidth()).attr('rx', 2).attr('ry', 2);
      bars.transition().duration(300).attr('x', x(0)).attr('width', d => x((0, _object.get)(d, 'percent')) - textWidth);
      bars.exit().remove(); // draw MOE

      if (!isDecennial) {
        const moebars = svg.selectAll('.moebar').data(rawData, d => (0, _object.get)(d, 'group'));

        const xFunctionMOE = d => {
          if ((0, _object.get)(d, 'percentMarginOfError') > (0, _object.get)(d, 'percent')) return x(0);
          return x((0, _object.get)(d, 'percent')) - x((0, _object.get)(d, 'percentMarginOfError')) - -textWidth;
        };

        const widthFunctionMOE = d => {
          const defaultWidth = (x((0, _object.get)(d, 'percentMarginOfError')) - textWidth) * 2;
          const axesWidth = width - textWidth;
          const barWidth = x((0, _object.get)(d, 'percent')) - textWidth;

          if ((0, _object.get)(d, 'percentMarginOfError') > (0, _object.get)(d, 'percent')) {
            const newWidth = defaultWidth - (x((0, _object.get)(d, 'percentMarginOfError') - (0, _object.get)(d, 'percent')) - textWidth);
            return newWidth;
          } // if the percentage bar + the MOE bar extend past 100% on the x axes, modify width of MOE bar


          if (barWidth + defaultWidth * 0.5 > axesWidth) {
            const gapWidth = axesWidth - barWidth;
            const shortenedWidth = defaultWidth * 0.5 + gapWidth;
            return shortenedWidth;
          }

          return defaultWidth;
        };

        moebars.enter().append('rect').attr('class', d => `moebar ${(0, _object.get)(d, 'classValue')}`).attr('fill', d => {
          if ((0, _object.get)(d, 'color')) return (0, _object.get)(d, 'color');
          return '#2e6472';
        }).attr('opacity', 0.4).attr('x', xFunctionMOE).attr('y', d => y((0, _object.get)(d, 'group')) + y.bandwidth() / 2 + -3).attr('height', 6).attr('width', widthFunctionMOE);
        moebars.transition().duration(300).attr('x', xFunctionMOE).attr('width', widthFunctionMOE);
        moebars.exit().remove(); // draw Comparison MOE

        const comparisonMOEbars = svg.selectAll('.comparisonMoebar').data(rawData, d => (0, _object.get)(d, 'group'));

        const xFunctionComparisonMOE = d => x((0, _object.get)(d, 'comparisonPercent')) - x((0, _object.get)(d, 'comparisonPercentMarginOfError')) - -textWidth;

        const widthFunctionComparisonMOE = d => (x((0, _object.get)(d, 'comparisonPercentMarginOfError')) - textWidth) * 2;

        comparisonMOEbars.enter().append('rect').attr('class', d => `comparisonMoebar ${(0, _object.get)(d, 'classValue')}`).attr('fill', d => {
          if ((0, _object.get)(d, 'color')) return (0, _object.get)(d, 'color');
          return '#000000';
        }).attr('opacity', 1).attr('x', xFunctionComparisonMOE).attr('y', d => y((0, _object.get)(d, 'group')) + y.bandwidth() / 2 + -0.5).attr('height', 1).attr('width', widthFunctionComparisonMOE);
        comparisonMOEbars.transition().duration(300).attr('x', xFunctionComparisonMOE).attr('width', widthFunctionComparisonMOE);
        comparisonMOEbars.exit().remove();
      } // draw comparison dots


      const comparisonBars = svg.selectAll('.comparisonbar').data(rawData, d => (0, _object.get)(d, 'group'));

      const cxFunction = d => x((0, _object.get)(d, 'comparisonPercent'));

      comparisonBars.enter().append('circle').attr('fill', '#FFF').attr('stroke', '#000').attr('class', d => `comparisonbar ${(0, _object.get)(d, 'classValue')}`).attr('cx', cxFunction).attr('cy', d => y((0, _object.get)(d, 'group')) + y.bandwidth() / 2).attr('r', 2.5);
      comparisonBars.transition().duration(300).attr('cx', cxFunction);
      comparisonBars.exit().remove();
    }
  });

  _exports.default = _default;
});