define("labs-nyc-factfinder/chart-config/acs/economic/index", ["exports", "labs-nyc-factfinder/chart-config/acs/economic/class-of-worker", "labs-nyc-factfinder/chart-config/acs/economic/income-and-benefits", "labs-nyc-factfinder/chart-config/acs/economic/commute-to-work", "labs-nyc-factfinder/chart-config/acs/economic/occupation", "labs-nyc-factfinder/chart-config/acs/economic/ratio-of-income-to-poverty-level"], function (_exports, _classOfWorker, _incomeAndBenefits, _commuteToWork, _occupation, _ratioOfIncomeToPovertyLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    classOfWorker: _classOfWorker.default,
    incomeAndBenefits: _incomeAndBenefits.default,
    commuteToWork: _commuteToWork.default,
    occupation: _occupation.default,
    ratioOfIncomeToPovertyLevel: _ratioOfIncomeToPovertyLevel.default
  };
  _exports.default = _default;
});