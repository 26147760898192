define("labs-nyc-factfinder/table-config/acs/social/school-enrollment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population 3 years and over enrolled in school',
    highlight: true,
    data: 'pop3plen'
  }, {
    title: 'Nursery school, preschool',
    data: 'se_nscpsc'
  }, {
    title: 'Kindergarten',
    data: 'se_kndgtn'
  }, {
    title: 'Elementary school (grades 1-8)',
    data: 'se_g1t8'
  }, {
    title: 'High school (grades 9-12)',
    data: 'se_g9t12'
  }, {
    title: 'College or graduate school',
    data: 'se_clggsc'
  }];
  _exports.default = _default;
});