define("labs-nyc-factfinder/routes/explorer", ["exports", "@ember/routing/route", "labs-nyc-factfinder/utils/fetch-explorer-model"], function (_exports, _route, _fetchExplorerModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExplorerRoute extends _route.default {
    beforeModel() {
      this.store.unloadAll('row');
    }

    async model(_ref, _ref2) {
      let {
        compareTo
      } = _ref;
      let {
        to: {
          params: {
            geotype,
            geoid
          }
        }
      } = _ref2;
      // eslint-disable-line
      return await (0, _fetchExplorerModel.default)(this.store, geotype, geoid, compareTo);
    }

  }

  _exports.default = ExplorerRoute;
});