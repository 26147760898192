define("labs-nyc-factfinder/controllers/index", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "@ember/object", "@glimmer/tracking", "mapbox-gl", "@mapbox/mapbox-gl-draw", "@turf/combine", "shpjs", "@turf/bbox", "labs-nyc-factfinder/utils/carto", "labs-nyc-factfinder/utils/radius-mode", "labs-nyc-factfinder/queries/intersection", "labs-nyc-factfinder/queries/radius", "labs-nyc-factfinder/layer-groups", "labs-nyc-factfinder/layers/draw-styles", "labs-nyc-factfinder/sources", "labs-nyc-factfinder/layers/selected-features", "labs-nyc-factfinder/layers/highlighted-feature", "labs-nyc-factfinder/sources/choropleths", "labs-nyc-factfinder/layers/subdued-nta-labels", "@lottiefiles/lottie-player"], function (_exports, _controller, _computed, _service, _object, _tracking, _mapboxGl, _mapboxGlDraw, _combine, _shpjs, _bbox, _carto, _radiusMode, _intersection, _radius, _layerGroups, _drawStyles, _sources, _selectedFeatures, _highlightedFeature, _choropleths, _subduedNtaLabels, _lottiePlayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const selectedFillLayer = _selectedFeatures.default.fill;
  const combine = _combine.default;
  const draw = new _mapboxGlDraw.default({
    displayControlsDefault: false,
    controls: {
      rectangle: false,
      polygon: false,
      trash: false
    },
    styles: _drawStyles.default,
    modes: Object.assign({
      draw_radius: _radiusMode.default
    }, _mapboxGlDraw.default.modes)
  }); // TODO: split out different functions into components (draw, shapefile, etc)

  var _default = _controller.default.extend((_obj = {
    queryParams: ['lastreport'],
    // stores the last report the user visited
    // in the event that they go back to change
    // the geography
    lastreport: 'demographic',
    // global: stores user's geographic selection
    selection: (0, _service.inject)(),
    // handles map highlighting
    mapMouseover: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    // static layerGroups/sources...
    layerGroups: _layerGroups.default,
    sources: _sources.default,
    subduedNtaLabels: _subduedNtaLabels.default,
    choroplethsSource: _choropleths.default,
    zoom: 11.67,
    center: [-73.9515, 40.7198],
    mode: 'direct-select',
    isDrawing: false,
    drawMode: null,
    selectedFillLayer,
    highlightedFeature: _highlightedFeature.default,
    selectedChoropleth: 'population',
    summaryLevel: (0, _computed.alias)('selection.summaryLevel'),
    customVisualOverlayData: null,
    customVisualOverlayLines: false,
    customVisualOverlayPoints: false,
    selectedSource: (0, _object.computed)('selection.current', function () {
      const current = this.get('selection.current');
      return {
        type: 'geojson',
        data: current
      };
    }),

    fitBounds() {
      const map = this.get('map');
      const FC = this.get('selection').current;
      map.fitBounds((0, _bbox.default)(FC), {
        padding: 40
      });
    },

    handleDrawButtonClick(type) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'toggle_draw',
        'toggle_draw_type': type
      });
      this.get('metrics').trackEvent('GoogleAnalytics', {
        eventCategory: 'Draw',
        eventAction: 'Toggle Draw Type',
        eventLabel: type
      });
      const isDrawing = this.get('isDrawing');
      const map = this.get('selection').currentMapInstance; // sometimes this is never set

      if (isDrawing) {
        draw.trash();
        this.set('isDrawing', false);
        this.set('drawMode', null);
      } else {
        map.addControl(draw, 'top-left');
        this.set('drawMode', type);

        if (type === 'polygon') {
          draw.changeMode('draw_polygon');
        }

        if (type === 'radius') draw.changeMode('draw_radius');
        this.set('isDrawing', true);
        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory: 'Draw',
          eventAction: `Draw ${type} Start`,
          eventLabel: this.get('selection').summaryLevel
        });
      }
    },

    handleMapLoad(map) {
      this.set('map', map); // setup controls

      const navigationControl = new _mapboxGl.default.NavigationControl();
      const geoLocateControl = new _mapboxGl.default.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });
      map.addControl(navigationControl, 'top-left');
      map.addControl(new _mapboxGl.default.ScaleControl({
        unit: 'imperial'
      }), 'bottom-left');
      map.addControl(geoLocateControl, 'top-left');
      this.set('selection.currentMapInstance', map);

      if (this.get('selection.selectedCount')) {
        this.fitBounds(map);
      } // remove default neighborhood names


      map.removeLayer('place_suburb');
      map.removeLayer('place_city_large');
      map.addLayer(_subduedNtaLabels.default); // trigger handleSummaryLevelToggle to show the correct census geoms

      const summaryLevel = this.get('summaryLevel');
      this.handleSummaryLevelToggle(summaryLevel);
    },

    // allows geometry to be selected by clicking on shape on map
    handleClick(feature) {
      const selection = this.get('selection');

      if (!this.get('isDrawing')) {
        selection.handleSelectedFeatures.perform([feature]);
      }
    },

    handleDrawCreate(e) {
      // delete the drawn geometry
      draw.deleteAll();
      const selection = this.get('selection');
      const {
        summaryLevel
      } = selection;
      const {
        geometry
      } = e.features[0];
      geometry.crs = {
        type: 'name',
        properties: {
          name: 'EPSG:4326'
        }
      };
      let SQL;

      if (geometry.type === 'Polygon') {
        SQL = (0, _intersection.default)(summaryLevel, geometry);
      } else {
        const {
          radius
        } = e.features[0].properties;
        SQL = (0, _radius.default)(summaryLevel, geometry, radius);
      }

      _carto.default.SQL(SQL, 'geojson', 'post').then(FC => {
        selection.handleSelectedFeatures.perform(FC.features);
        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory: 'Draw',
          eventAction: 'Draw Create',
          eventLabel: this.get('selection').summaryLevel,
          eventValue: FC.features.length
        });
      });
    },

    handleDrawModeChange(e) {
      const isDrawing = e.mode === 'draw_polygon' || e.mode === 'draw_radius'; // delay setting isDrawing boolean so that polygon-closing click won't be handled

      setTimeout(() => {
        this.set('isDrawing', isDrawing);

        if (!isDrawing) {
          const map = this.get('selection').currentMapInstance;
          this.set('drawMode', null);
          map.removeControl(draw);
        }
      }, 200);
    },

    handleMousemove(e) {
      if (!this.get('isDrawing')) {
        const mapMouseover = this.get('mapMouseover');
        mapMouseover.highlighter(e);
      }
    },

    handleSummaryLevelToggle(summaryLevel) {
      this.get('selection').handleSummaryLevelToggle(summaryLevel);
    },

    addedFile(file) {
      const reader = new FileReader(); // const selection = this.get('selection');
      // const { summaryLevel } = selection;

      let buffer;

      reader.onload = event => {
        buffer = event.target.result;
        (0, _shpjs.default)(buffer).then(geojson => {
          let combined;
          this.set('customVisualOverlayData', geojson);
          combined = combine(geojson);
          combined = combined.features[0].geometry;
          combined.crs = {
            type: 'name',
            properties: {
              name: 'EPSG:4326'
            }
          };

          if (combined.type === 'MultiPolygon' || combined.type === 'MultiLineString') {
            this.set('customVisualOverlayLines', true);
          }

          if (combined.type === 'MultiPoint') {
            this.set('customVisualOverlayPoints', true);
          } // const SQL = generateIntersectionSQL(summaryLevel, combined);
          // carto.SQL(SQL, 'geojson', 'post')
          //   .then((FC) => {
          //     selection.handleSelectedFeatures(FC.features);
          //     this.fitBounds();
          //   })
          //   .catch(() => {
          //     alert('Something went wrong with this Shapefile. Try to simplify the geometries.'); // eslint-disable-line
          //   });

        }).catch(() => {
          file._removeLink.click();

          alert('Something went wrong with this Shapefile. Check that it is valid'); // eslint-disable-line
        });
      };

      reader.readAsArrayBuffer(file);
    },

    removedFile() {
      this.setProperties({
        customVisualOverlayData: null,
        customVisualOverlayLines: false,
        customVisualOverlayPoints: false
      });
    },

    openModal: true,

    toggleModal() {
      this.openModal = !this.openModal;
    }

  }, (_applyDecoratedDescriptor(_obj, "openModal", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "openModal"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "toggleModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleModal"), _obj)), _obj));

  _exports.default = _default;
});