define("labs-nyc-factfinder/table-config/acs/demographic/hispanic-subgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Hispanic/Latino',
    highlight: true,
    data: 'hsp2'
  }, {
    title: 'Mexican',
    data: 'hspme'
  }, {
    title: 'Puerto Rican',
    data: 'hsppr'
  }, {
    title: 'Cuban',
    data: 'hspcub'
  }, {
    title: 'Dominican (Dominican Republic)',
    data: 'hspdom'
  }, {
    title: 'Central American',
    data: 'hspcam'
  }, {
    title: 'Costa Rican',
    indent: 1,
    data: 'hspcr'
  }, {
    title: 'Guatemalan',
    indent: 1,
    data: 'hspguatm'
  }, {
    title: 'Honduran',
    indent: 1,
    data: 'hsphnd'
  }, {
    title: 'Nicaraguan',
    indent: 1,
    data: 'hspnic'
  }, {
    title: 'Panamanian',
    indent: 1,
    data: 'hsppan'
  }, {
    title: 'Salvadoran',
    indent: 1,
    data: 'hspsalv'
  }, {
    title: 'Other Central American',
    indent: 1,
    data: 'hspocam'
  }, {
    title: 'South American',
    data: 'hspsam'
  }, {
    title: 'Argentinean',
    indent: 1,
    data: 'hsparg'
  }, {
    title: 'Bolivian',
    indent: 1,
    data: 'hspbol'
  }, {
    title: 'Chilean',
    indent: 1,
    data: 'hspchl'
  }, {
    title: 'Colombian',
    indent: 1,
    data: 'hspcol'
  }, {
    title: 'Ecuadorian',
    indent: 1,
    data: 'hspec'
  }, {
    title: 'Paraguayan',
    indent: 1,
    data: 'hspprg'
  }, {
    title: 'Peruvian',
    indent: 1,
    data: 'hspperu'
  }, {
    title: 'Uruguayan',
    indent: 1,
    data: 'hspurg'
  }, {
    title: 'Venezuelan',
    indent: 1,
    data: 'hspvnz'
  }, {
    title: 'Other South American',
    indent: 1,
    data: 'hsposam'
  }, {
    title: 'Other Hispanic/Latino',
    data: 'hspoth'
  }, {
    title: 'Spaniard',
    indent: 1,
    data: 'hspspnrd'
  }, {
    title: 'Spanish',
    indent: 1,
    data: 'hspspnsh'
  }, {
    title: 'Spanish American',
    indent: 1,
    data: 'hspspam'
  }, {
    title: 'All other Hispanic/Latino',
    indent: 1,
    data: 'hspalloth'
  }];
  _exports.default = _default;
});