define("labs-nyc-factfinder/chart-config/acs/social/school-enrollment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'se_nscpsc',
    label: 'Nursery school, preschool'
  }, {
    property: 'se_kndgtn',
    label: 'Kindergarten'
  }, {
    property: 'se_g1t8',
    label: 'Elementary school (grades 1-8)'
  }, {
    property: 'se_g9t12',
    label: 'High school (grades 9-12)'
  }, {
    property: 'se_clggsc',
    label: 'College or graduate school'
  }];
  _exports.default = _default;
});