define("labs-nyc-factfinder/templates/components/population-pyramid", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "F7SI7jHM",
    "block": "[[[41,[30,0,[\"title\"]],[[[10,\"h4\"],[14,0,\"header-small\"],[12],[1,[30,0,[\"title\"]]],[13]],[]],null],[1,\"\\n\"],[10,0],[14,5,\"text-align: center; margin-left: 15px;\"],[12],[1,\"Male\"],[10,0],[14,5,\"display: inline-block; width: 50px;\"],[12],[13],[1,\"Female\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"],[10,0],[14,0,\"age-chart-tooltip\"],[12],[1,\"\\n  Hover over bars for details about each age group\\n\"],[13],[1,\"\\n\"],[10,\"svg\"],[14,0,\"age-chart-key\"],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[12],[1,\"\\n  \"],[10,\"g\"],[12],[1,\"\\n\\n    \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"9\"],[14,\"height\",\"11\"],[14,\"width\",\"20\"],[14,\"rx\",\"2\"],[14,\"ry\",\"2\"],[14,0,\"bar\"],[12],[13],[1,\"\\n    \"],[10,\"rect\"],[14,\"alighnment-baseline\",\"middle\"],[14,\"x\",\"15\"],[14,\"y\",\"11.5\"],[14,\"height\",\"6\"],[14,\"width\",\"10\"],[14,0,\"moe\"],[12],[13],[1,\"\\n    \"],[10,\"text\"],[14,\"fill\",\"#000\"],[14,\"x\",\"30\"],[14,\"dy\",\"18\"],[14,5,\"text-anchor: start;\"],[12],[1,\"Selected Area percent and margin of error\"],[13],[1,\"\\n\\n    \"],[10,\"rect\"],[14,\"x\",\"2.5\"],[14,\"y\",\"29.5\"],[14,\"height\",\"1\"],[14,\"width\",\"20\"],[14,0,\"comparisonmoe\"],[12],[13],[1,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"12.5\"],[14,\"cy\",\"30\"],[14,\"r\",\"2.5\"],[14,0,\"comparison\"],[12],[13],[1,\"\\n    \"],[10,\"text\"],[14,\"fill\",\"#000\"],[14,\"x\",\"30\"],[14,\"dy\",\"33\"],[14,5,\"text-anchor: start;\"],[12],[1,\"Comparison Area percent and margin of error\"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/population-pyramid.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});