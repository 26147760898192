define("labs-nyc-factfinder/layer-groups/index", ["exports", "labs-nyc-factfinder/layer-groups/census-blocks", "labs-nyc-factfinder/layer-groups/choropleths", "labs-nyc-factfinder/layer-groups/neighborhood-tabulation-areas-selection", "labs-nyc-factfinder/layer-groups/nyc-pumas-selection", "labs-nyc-factfinder/layer-groups/cdtas", "labs-nyc-factfinder/layer-groups/districts", "labs-nyc-factfinder/layer-groups/boroughs", "labs-nyc-factfinder/layer-groups/cities"], function (_exports, _censusBlocks, _choropleths, _neighborhoodTabulationAreasSelection, _nycPumasSelection, _cdtas, _districts, _boroughs, _cities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    censusBlocks: _censusBlocks.default,
    choropleths: _choropleths.default,
    neighborhoodTabulationAreasSelection: _neighborhoodTabulationAreasSelection.default,
    nycPumasSelection: _nycPumasSelection.default,
    cdtasSelection: _cdtas.default,
    districtsSelection: _districts.default,
    boroughsSelection: _boroughs.default,
    citiesSelection: _cities.default
  };
  _exports.default = _default;
});