define("labs-nyc-factfinder/templates/components/labs-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mhg86ooI",
    "block": "[[[41,[30,0,[\"_loader\",\"isLoaded\"]],[[[1,\"  \"],[10,0],[14,0,\"labs-map-loaded\"],[12],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"sourceId\",\"options\",\"map\"],[\"hovered-feature\",[30,0,[\"hoveredFeatureSource\"]],[30,0,[\"_loader\",\"map\"]]]]]],[1,\"\\n\\n\"],[1,\"  \"],[1,[28,[35,2],null,[[\"map\",\"layer\"],[[30,0,[\"_loader\",\"map\"]],[33,3]]]]],[1,\"\\n\\n  \"],[1,[28,[35,2],null,[[\"map\",\"layer\"],[[30,0,[\"_loader\",\"map\"]],[33,4]]]]],[1,\"\\n\\n  \"],[18,1,[[28,[37,6],null,[[\"mapInstance\",\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"labs-layers\",\"instance\"],[[30,0,[\"_loader\",\"map\"]],[50,\"mapbox-gl-call\",0,null,[[\"obj\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-control\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-image\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-layer\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-marker\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-on\",0,null,[[\"eventSource\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-popup\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"mapbox-gl-source\",0,null,[[\"map\"],[[30,0,[\"_loader\",\"map\"]]]]],[50,\"labs-layers\",0,null,[[\"map\",\"layerGroups\",\"hoveredFeature\"],[[30,0,[\"_loader\",\"map\"]],[33,8],[33,9]]]],[30,0,[\"_loader\",\"map\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"_loader\",\"error\"]],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,0,[\"_loader\",\"error\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[54,[\"error rendering mapbox-gl\",[30,0,[\"_loader\",\"error\"]]]]],[1,\"\\n\"]],[]]]],[]],null]],[]]]],[\"&default\",\"&else\"],false,[\"if\",\"mapbox-gl-source\",\"mapbox-gl-layer\",\"highlightedLineFeatureLayer\",\"highlightedCircleFeatureLayer\",\"yield\",\"hash\",\"component\",\"layerGroups\",\"hoveredFeature\",\"has-block\",\"log\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/labs-map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});