define("labs-nyc-factfinder/templates/components/labs-ui/site-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tgVzUkhS",
    "block": "[[[10,0],[14,0,\"top-bar-left grid-x grid-padding-x align-middle\"],[12],[1,\"\\n  \"],[10,3],[14,0,\"dcp-link\"],[14,6,\"http://www1.nyc.gov/site/planning/index.page\"],[12],[10,\"img\"],[14,0,\"dcp-logo\"],[14,\"src\",\"https://raw.githubusercontent.com/NYCPlanning/logo/master/dcp_logo_772.png\"],[14,\"alt\",\"NYC Planning\"],[12],[13],[13],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"title\"],[[50,\"labs-ui/site-title\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"top-bar-right\"],[12],[1,\"\\n\"],[41,[30,0,[\"responsiveNav\"]],[[[1,\"    \"],[10,0],[15,0,[29,[\"cell auto hide-for-\",[30,0,[\"responsiveSize\"]],\" text-right\"]]],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"responsive-nav-toggler hide-for-print\"],[24,4,\"button\"],[4,[38,4],[[30,0],[28,[37,5],[[30,0,[\"closed\"]]],null],[28,[37,6],[[30,0,[\"closed\"]]],null]],null],[12],[1,\"Menu\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[11,\"nav\"],[16,0,[29,[\"cell \",[52,[30,0,[\"responsiveNav\"]],[28,[37,7],[[30,0,[\"responsiveSize\"]],\"-shrink responsive\"],null],\"shrink\"],\" \",[52,[28,[37,8],[[30,0,[\"responsiveNav\"]],[30,0,[\"closed\"]]],null],[28,[37,7],[\"show-for-\",[30,0,[\"responsiveSize\"]]],null]],\" hide-for-print\"]]],[4,[38,4],[[30,0],[28,[37,5],[[30,0,[\"closed\"]]],null],[28,[37,6],[[30,0,[\"closed\"]]],null]],[[\"preventDefault\"],[false]]],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"nav\"],[[50,\"labs-ui/site-nav\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\",\"action\",\"mut\",\"not\",\"concat\",\"and\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/labs-ui/site-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});