define("labs-nyc-factfinder/components/data-table-row-current", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    mode: 'current',
    reliability: false,
    comparison: true,
    isSelected: false,
    data: {},
    // model object with specific properties
    rowConfig: {},
    // model's row configuration object
    tagName: 'tr',
    classNameBindings: ['getClassNames'],
    getClassNames: (0, _object.computed)('rowConfig', 'isSelected', function () {
      const rowConfig = this.get('rowConfig');
      const classes = [];

      if (rowConfig.highlight) {
        classes.push('row-highlight');
      }

      if (rowConfig.indent) {
        classes.push(`row-indent-x${rowConfig.indent}`);
      }

      if (this.isSelected) {
        classes.push('is-selected');
      }

      return classes.join(' ');
    }),
    actions: {
      showData() {
        window.logModel = this.get('data');
      }

    },

    click() {
      this.set('isSelected', !this.isSelected);
    }

  });

  _exports.default = _default;
});