define("labs-nyc-factfinder/templates/components/ui/nestable-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0vLit1Ve",
    "block": "[[[10,0],[15,0,[29,[\"nestable-list-item grid-x \",[52,[30,0,[\"hasChildren\"]],\"clickable\"],\" \",[30,1,[\"type\"]],\"-list-item\"]]],[12],[1,\"\\n  \"],[11,0],[24,0,\"cell small-1\"],[4,[38,1],[\"click\",[28,[37,2],[[30,2],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"a11y-orange clickable\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"selected\"]],\"selected\"],null],[[[1,\"        \"],[1,[28,[35,4],null,[[\"icon\",\"prefix\"],[\"check-square\",\"far\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1,[\"selected\"]],\"unselected\"],null],[[[1,\"        \"],[1,[28,[35,4],null,[[\"icon\",\"prefix\"],[\"square\",\"far\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"icon\",\"prefix\"],[\"minus-square\",\"far\"]]]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,0],[24,0,\"cell auto\"],[4,[38,1],[\"click\",[30,0,[\"toggleOpen\"]]],null],[12],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"cell small-1\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasChildren\"]],[[[1,\"    \"],[11,1],[4,[38,1],[\"click\",[30,0,[\"toggleOpen\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"icon\"],[[52,[30,0,[\"open\"]],\"caret-up\",\"caret-down\"]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"cell small-12\"],[12],[1,\"\\n\"],[41,[30,0,[\"open\"]],[[[42,[28,[37,6],[[28,[37,6],[[30,1,[\"children\"]]],null]],null],\"id\",[[[1,\"        \"],[8,[39,7],null,[[\"@item\",\"@onItemToggle\"],[[30,3],[30,2]]],null],[1,\"\\n\"]],[3]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@item\",\"@onItemToggle\",\"child\"],false,[\"if\",\"on\",\"fn\",\"eq\",\"fa-icon\",\"each\",\"-track-array\",\"ui/nestable-list-item\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/ui/nestable-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});