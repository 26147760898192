define("labs-nyc-factfinder/table-config/acs/social/computers-and-internet-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total Households',
    highlight: true,
    data: 'hh3'
  }, {
    title: 'With a computer',
    data: 'hhcomp'
  }, {
    title: 'With a broadband Internet subscription',
    data: 'hhint'
  }];
  _exports.default = _default;
});