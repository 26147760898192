define("labs-nyc-factfinder/table-config/acs/social/place-of-birth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total population',
    highlight: true,
    data: 'pop_4'
  }, {
    title: 'Native',
    data: 'ntv'
  }, {
    indent: 1,
    title: 'Born in United States',
    data: 'ntvus'
  }, {
    indent: 2,
    title: 'Born in New York State',
    data: 'ntvnys'
  }, {
    indent: 2,
    title: 'Born outside New York State',
    data: 'ntvnotnys'
  }, {
    indent: 1,
    title: 'Born in Puerto Rico, U.S. Island areas, or born abroad to American parent(s)',
    data: 'ntvprusab'
  }, {
    title: 'Foreign-born',
    data: 'fb1'
  }, {
    divider: true
  }, {
    title: 'Foreign-born',
    highlight: true,
    data: 'fb2'
  }, {
    title: 'Europe',
    data: 'eur'
  }, {
    indent: 1,
    title: 'Northern Europe',
    data: 'neur'
  }, {
    indent: 2,
    title: 'Denmark',
    data: 'denmark'
  }, {
    indent: 2,
    title: 'Ireland',
    data: 'ireland'
  }, {
    indent: 2,
    title: 'Norway',
    data: 'norway'
  }, {
    indent: 2,
    title: 'Sweden',
    data: 'sweden'
  }, {
    indent: 2,
    title: 'United Kingdom (inc. Crown Dependencies)',
    data: 'untdkgdm'
  }, {
    indent: 3,
    title: 'United Kingdom, excluding England and Scotland',
    data: 'uknoengsc'
  }, {
    indent: 3,
    title: 'England',
    data: 'eng'
  }, {
    indent: 3,
    title: 'Scotland',
    data: 'scot'
  }, {
    indent: 2,
    title: 'Other Northern Europe',
    data: 'oneur'
  }, {
    indent: 1,
    title: 'Western Europe',
    data: 'weur'
  }, {
    indent: 2,
    title: 'Austria',
    data: 'austria'
  }, {
    indent: 2,
    title: 'Belgium',
    data: 'belgium'
  }, {
    indent: 2,
    title: 'France',
    data: 'france'
  }, {
    indent: 2,
    title: 'Germany',
    data: 'germany'
  }, {
    indent: 2,
    title: 'Netherlands',
    data: 'nthrlds'
  }, {
    indent: 2,
    title: 'Switzerland',
    data: 'switzrld'
  }, {
    indent: 2,
    title: 'Other Western Europe',
    data: 'oweur'
  }, {
    indent: 1,
    title: 'Southern Europe',
    data: 'seur'
  }, {
    indent: 2,
    title: 'Greece',
    data: 'greece'
  }, {
    indent: 2,
    title: 'Italy',
    data: 'italy'
  }, {
    indent: 2,
    title: 'Portugal',
    data: 'portugal'
  }, {
    indent: 3,
    title: 'Azores Islands',
    data: 'azores'
  }, {
    indent: 2,
    title: 'Spain',
    data: 'spain'
  }, {
    indent: 2,
    title: 'Other Southern Europe',
    data: 'oseur'
  }, {
    indent: 1,
    title: 'Eastern Europe',
    data: 'eeur'
  }, {
    indent: 2,
    title: 'Albania',
    data: 'albania'
  }, {
    indent: 2,
    title: 'Belarus',
    data: 'belarus'
  }, {
    indent: 2,
    title: 'Bosnia and Herzegovina',
    data: 'bosniah'
  }, {
    indent: 2,
    title: 'Bulgaria',
    data: 'bulgaria'
  }, {
    indent: 2,
    title: 'Croatia',
    data: 'croatia'
  }, {
    indent: 2,
    title: 'Czechoslovakia (includes Czech Republic and Slovakia)',
    data: 'czchslv'
  }, {
    indent: 2,
    title: 'Hungary',
    data: 'hungary'
  }, {
    indent: 2,
    title: 'Latvia',
    data: 'latvia'
  }, {
    indent: 2,
    title: 'Lithuania',
    data: 'lthuania'
  }, {
    indent: 2,
    title: 'Moldova',
    data: 'moldova'
  }, {
    indent: 2,
    title: 'North Macedonia (Macedonia)',
    data: 'mcdonia'
  }, {
    indent: 2,
    title: 'Poland',
    data: 'poland'
  }, {
    indent: 2,
    title: 'Romania',
    data: 'romania'
  }, {
    indent: 2,
    title: 'Russia',
    data: 'russia'
  }, {
    indent: 2,
    title: 'Ukraine',
    data: 'ukraine'
  }, {
    indent: 2,
    title: 'Serbia',
    data: 'serbia'
  }, {
    indent: 2,
    title: 'Other Eastern Europe',
    data: 'oeeur'
  }, {
    indent: 1,
    title: 'Europe, n.e.c.',
    data: 'eurnec'
  }, {
    title: 'Asia',
    data: 'asia'
  }, {
    indent: 1,
    title: 'Eastern Asia',
    data: 'easia'
  }, {
    indent: 2,
    title: 'China',
    data: 'china'
  }, {
    indent: 3,
    title: 'China, excluding Hong Kong and Taiwan',
    data: 'chnohktwn'
  }, {
    indent: 3,
    title: 'Hong Kong',
    data: 'hongkong'
  }, {
    indent: 3,
    title: 'Taiwan',
    data: 'taiwan'
  }, {
    indent: 2,
    title: 'Japan',
    data: 'japan'
  }, {
    indent: 2,
    title: 'Korea',
    data: 'korea'
  }, {
    indent: 2,
    title: 'Other Eastern Asia',
    data: 'oeasia'
  }, {
    indent: 1,
    title: 'South Central Asia',
    data: 'scasia'
  }, {
    indent: 2,
    title: 'Afghanistan',
    data: 'afghan'
  }, {
    indent: 2,
    title: 'Bangladesh',
    data: 'bngldsh'
  }, {
    indent: 2,
    title: 'Bhutan',
    data: 'bhutan'
  }, {
    indent: 2,
    title: 'India',
    data: 'india'
  }, {
    indent: 2,
    title: 'Iran',
    data: 'iran'
  }, {
    indent: 2,
    title: 'Kazakhstan',
    data: 'kzkhstan'
  }, {
    indent: 2,
    title: 'Nepal',
    data: 'nepal'
  }, {
    indent: 2,
    title: 'Pakistan',
    data: 'pakistan'
  }, {
    indent: 2,
    title: 'Sri Lanka',
    data: 'srilanka'
  }, {
    indent: 2,
    title: 'Uzbekistan',
    data: 'uzbkstan'
  }, {
    indent: 2,
    title: 'Other South Central Asia',
    data: 'oscasia'
  }, {
    indent: 1,
    title: 'South Eastern Asia',
    data: 'seasia'
  }, {
    indent: 2,
    title: 'Burma (Myanmar)',
    data: 'burma'
  }, {
    indent: 2,
    title: 'Cambodia',
    data: 'cambodia'
  }, {
    indent: 2,
    title: 'Indonesia',
    data: 'indnsia'
  }, {
    indent: 2,
    title: 'Laos',
    data: 'laos'
  }, {
    indent: 2,
    title: 'Malaysia',
    data: 'malaysia'
  }, {
    indent: 2,
    title: 'Philippines',
    data: 'philipns'
  }, {
    indent: 2,
    title: 'Singapore',
    data: 'sngapore'
  }, {
    indent: 2,
    title: 'Thailand',
    data: 'thailand'
  }, {
    indent: 2,
    title: 'Vietnam',
    data: 'vietnam'
  }, {
    indent: 2,
    title: 'Other South Eastern Asia',
    data: 'oseasia'
  }, {
    indent: 1,
    title: 'Western Asia',
    data: 'wasia'
  }, {
    indent: 2,
    title: 'Armenia',
    data: 'armenia'
  }, {
    indent: 2,
    title: 'Azerbaijan',
    data: 'azerbjn'
  }, {
    indent: 2,
    title: 'Georgia',
    data: 'georgia'
  }, {
    indent: 2,
    title: 'Iraq',
    data: 'iraq'
  }, {
    indent: 2,
    title: 'Israel',
    data: 'israel'
  }, {
    indent: 2,
    title: 'Jordan',
    data: 'jordan'
  }, {
    indent: 2,
    title: 'Kuwait',
    data: 'kuwait'
  }, {
    indent: 2,
    title: 'Lebanon',
    data: 'lebanon'
  }, {
    indent: 2,
    title: 'Saudi Arabia',
    data: 'saudiar'
  }, {
    indent: 2,
    title: 'Syria',
    data: 'syria'
  }, {
    indent: 2,
    title: 'Turkey',
    data: 'turkey'
  }, {
    indent: 2,
    title: 'United Arab Emirates',
    data: 'uaremir'
  }, {
    indent: 2,
    title: 'Yemen',
    data: 'yemen'
  }, {
    indent: 2,
    title: 'Other Western Asia',
    data: 'owasia'
  }, {
    indent: 1,
    title: 'Asia,n.e.c.',
    data: 'asianec'
  }, {
    title: 'Africa',
    data: 'afr'
  }, {
    indent: 1,
    title: 'Eastern Africa',
    data: 'eafr'
  }, {
    indent: 2,
    title: 'Eritrea',
    data: 'eritrea'
  }, {
    indent: 2,
    title: 'Ethiopia',
    data: 'ethiopia'
  }, {
    indent: 2,
    title: 'Kenya',
    data: 'kenya'
  }, {
    indent: 2,
    title: 'Somalia',
    data: 'somalia'
  }, {
    indent: 2,
    title: 'Tanzania',
    data: 'tanzania'
  }, {
    indent: 2,
    title: 'Uganda',
    data: 'uganda'
  }, {
    indent: 2,
    title: 'Zimbabwe',
    data: 'zimbabwe'
  }, {
    indent: 2,
    title: 'Other Eastern Africa',
    data: 'oeafr'
  }, {
    indent: 1,
    title: 'Middle Africa',
    data: 'mafr'
  }, {
    indent: 2,
    title: 'Cameroon          ',
    data: 'cameroon'
  }, {
    indent: 2,
    title: 'Congo',
    data: 'congo'
  }, {
    indent: 2,
    title: 'Democratic Republic of Congo (Zaire)',
    data: 'drcongo'
  }, {
    indent: 2,
    title: 'Other Middle Africa',
    data: 'omafr'
  }, {
    indent: 1,
    title: 'Northern Africa',
    data: 'nafr'
  }, {
    indent: 2,
    title: 'Algeria',
    data: 'algeria'
  }, {
    indent: 2,
    title: 'Egypt',
    data: 'egypt'
  }, {
    indent: 2,
    title: 'Morocco',
    data: 'morocco'
  }, {
    indent: 2,
    title: 'Sudan',
    data: 'sudan'
  }, {
    indent: 2,
    title: 'Other Northern Africa',
    data: 'onafr'
  }, {
    indent: 1,
    title: 'Southern Africa',
    data: 'sthrnafr'
  }, {
    indent: 2,
    title: 'South Africa',
    data: 'sthafrca'
  }, {
    indent: 2,
    title: 'Other Southern Africa',
    data: 'osthrnafr'
  }, {
    indent: 1,
    title: 'Western Africa',
    data: 'wafr'
  }, {
    indent: 2,
    title: 'Cabo Verde',
    data: 'caboverd'
  }, {
    indent: 2,
    title: 'Ghana',
    data: 'ghana'
  }, {
    indent: 2,
    title: 'Ivory Coast ',
    data: 'ivorycst'
  }, {
    indent: 2,
    title: 'Liberia',
    data: 'liberia'
  }, {
    indent: 2,
    title: 'Nigeria',
    data: 'nigeria'
  }, {
    indent: 2,
    title: 'Senegal',
    data: 'senegal'
  }, {
    indent: 2,
    title: 'Sierra Leone',
    data: 'sierral'
  }, {
    indent: 2,
    title: 'Togo',
    data: 'togo'
  }, {
    indent: 2,
    title: 'Other Western Africa',
    data: 'owafr'
  }, {
    indent: 1,
    title: 'Africa, n.e.c.',
    data: 'afrnec'
  }, {
    title: 'Oceania',
    data: 'oceania'
  }, {
    indent: 1,
    title: 'Australia and New Zealand Subregion:',
    data: 'ausnzsbr'
  }, {
    indent: 2,
    title: 'Australia',
    data: 'austrlia'
  }, {
    indent: 2,
    title: 'Other Australian and New Zealand Subregion',
    data: 'oausnzsbr'
  }, {
    indent: 1,
    title: 'Fiji',
    data: 'fiji'
  }, {
    indent: 1,
    title: 'Marshall Islands',
    data: 'mrshlisl'
  }, {
    indent: 1,
    title: 'Micronesia',
    data: 'microns'
  }, {
    indent: 1,
    title: 'Oceania, n.e.c.',
    data: 'ocnianec'
  }, {
    title: 'Americas',
    data: 'amricas'
  }, {
    indent: 1,
    title: 'Latin America',
    data: 'lam'
  }, {
    indent: 2,
    title: 'Caribbean',
    data: 'carib'
  }, {
    indent: 3,
    title: 'Bahamas',
    data: 'bahamas'
  }, {
    indent: 3,
    title: 'Barbados',
    data: 'barbados'
  }, {
    indent: 3,
    title: 'Cuba',
    data: 'cuba'
  }, {
    indent: 3,
    title: 'Dominica',
    data: 'dominica'
  }, {
    indent: 3,
    title: 'Dominican Republic',
    data: 'domrep'
  }, {
    indent: 3,
    title: 'Grenada',
    data: 'grenada'
  }, {
    indent: 3,
    title: 'Haiti',
    data: 'haiti'
  }, {
    indent: 3,
    title: 'Jamaica',
    data: 'jamaica'
  }, {
    indent: 3,
    title: 'St. Lucia',
    data: 'stlucia'
  }, {
    indent: 3,
    title: 'St. Vincent and the Grenadines',
    data: 'stvgren'
  }, {
    indent: 3,
    title: 'Trinidad and Tobago',
    data: 'trandtob'
  }, {
    indent: 3,
    title: 'West Indies',
    data: 'windies'
  }, {
    indent: 3,
    title: 'Other Caribbean',
    data: 'ocarib'
  }, {
    indent: 2,
    title: 'Central America',
    data: 'cam'
  }, {
    indent: 3,
    title: 'Belize',
    data: 'belize'
  }, {
    indent: 3,
    title: 'Costa Rica',
    data: 'cstarica'
  }, {
    indent: 3,
    title: 'El Salvador',
    data: 'elslvdr'
  }, {
    indent: 3,
    title: 'Guatemala',
    data: 'guatmala'
  }, {
    indent: 3,
    title: 'Honduras',
    data: 'honduras'
  }, {
    indent: 3,
    title: 'Mexico',
    data: 'mexico'
  }, {
    indent: 3,
    title: 'Nicaragua',
    data: 'nicargua'
  }, {
    indent: 3,
    title: 'Panama',
    data: 'panama'
  }, {
    indent: 3,
    title: 'Other Central America',
    data: 'ocam'
  }, {
    indent: 2,
    title: 'South America',
    data: 'sam'
  }, {
    indent: 3,
    title: 'Argentina',
    data: 'argntina'
  }, {
    indent: 3,
    title: 'Bolivia',
    data: 'bolivia'
  }, {
    indent: 3,
    title: 'Brazil',
    data: 'brazil'
  }, {
    indent: 3,
    title: 'Chile',
    data: 'chile'
  }, {
    indent: 3,
    title: 'Colombia',
    data: 'colombia'
  }, {
    indent: 3,
    title: 'Ecuador',
    data: 'ecuador'
  }, {
    indent: 3,
    title: 'Guyana',
    data: 'guyana'
  }, {
    indent: 3,
    title: 'Peru',
    data: 'peru'
  }, {
    indent: 3,
    title: 'Uruguay',
    data: 'uruguay'
  }, {
    indent: 3,
    title: 'Venezuela',
    data: 'vnzuela'
  }, {
    indent: 3,
    title: 'Other South America',
    data: 'osam'
  }, {
    indent: 2,
    title: 'Northern America',
    data: 'nam'
  }, {
    indent: 3,
    title: 'Canada',
    data: 'canada'
  }, {
    indent: 3,
    title: 'Other Northern America',
    data: 'onam'
  }];
  _exports.default = _default;
});