define("labs-nyc-factfinder/table-config/acs/housing/year-householder-moved-into-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Occupied housing units',
    highlight: true,
    data: 'ochu3'
  }, {
    title: 'Moved in 2010 or later',
    data: 'mv10ltr'
  }, {
    title: 'Moved in 2000 to 2009',
    data: 'mv00t09'
  }, {
    title: 'Moved in 1990 to 1999',
    data: 'mv90t99'
  }, {
    title: 'Moved in 1989 or earlier',
    data: 'mvbf89'
  }];
  _exports.default = _default;
});