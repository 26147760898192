define("labs-nyc-factfinder/components/default-modal", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    open: true,
    actions: {
      toggleModal() {
        this.toggleProperty('open');
      }

    }
  });

  _exports.default = _default;
});