define("labs-nyc-factfinder/layer-groups/choropleths", ["exports", "labs-nyc-factfinder/choropleth-config"], function (_exports, _choroplethConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    id: 'choropleths',
    title: 'Thematic Maps (NTAs)',
    titleTooltip: 'Neighborhood Tabulation Area maps for selected variables',
    visible: false,
    layers: [{
      layer: {
        id: 'choropleth-nta-fill',
        type: 'fill',
        source: 'choropleths',
        paint: {
          'fill-opacity': 0.6,
          'fill-color': _choroplethConfig.default.find(d => d.id === 'popacre').paintFill['fill-color']
        }
      }
    }, {
      layer: {
        id: 'choropleth-nta-line',
        type: 'line',
        source: 'choropleths',
        paint: {
          'line-width': {
            stops: [[10, 2], [14, 5]]
          },
          'line-opacity': 0.6,
          'line-color': _choroplethConfig.default.find(d => d.id === 'popacre').paintLine['line-color'],
          'line-translate-anchor': 'map'
        }
      }
    }]
  };
  _exports.default = _default;
});