define("labs-nyc-factfinder/layers/point-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    id: 'cd-circle',
    type: 'circle',
    source: 'currentAddress',
    paint: {
      'circle-radius': 8,
      'circle-color': '#00A1F9',
      'circle-stroke-width': 2,
      'circle-stroke-color': '#FFFFFF'
    }
  };
  _exports.default = _default;
});