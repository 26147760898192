define("labs-nyc-factfinder/utils/track-event", ["exports", "@ember/utils", "@ember/service"], function (_exports, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trackEvent;

  function trackEvent(eventCategory, incAction, incLabel, incValue) {
    return (target, name, desc) => {
      const descriptor = desc;
      const originalValue = descriptor.value;

      descriptor.value = function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        originalValue.call(this, ...args);

        if (!this.get('metrics')) {
          this.set('metrics', (0, _service.inject)());
        }

        let eventAction = incAction;
        let eventLabel = incLabel;
        let eventValue = incValue; // allow getting prop names for values

        if (eventAction) {
          const actionIdentifier = this.get(eventAction);

          if (!(0, _utils.isEmpty)(actionIdentifier)) {
            eventAction = actionIdentifier;
          }
        }

        if (eventLabel) {
          const labelIdentifier = this.get(eventLabel);

          if (!(0, _utils.isEmpty)(labelIdentifier)) {
            eventLabel = labelIdentifier;
          }
        }

        if (eventValue) {
          const valueIdentifier = this.get(eventValue);

          if (!(0, _utils.isEmpty)(valueIdentifier)) {
            eventValue = valueIdentifier;
          }
        }

        this.get('metrics').trackEvent('GoogleAnalytics', {
          eventCategory,
          eventAction,
          eventLabel,
          eventValue
        });
      };

      return descriptor;
    };
  }
});