define("labs-nyc-factfinder/table-config/acs/economic/commute-to-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Workers 16 years and over',
    highlight: true,
    data: 'wrkr16pl'
  }, {
    title: 'Car, truck, or van -- drove alone',
    data: 'cw_drvaln'
  }, {
    title: 'Car, truck, or van -- carpooled',
    data: 'cw_crpld'
  }, {
    title: 'Public transportation',
    data: 'cw_pbtrns'
  }, {
    title: 'Walked',
    data: 'cw_wlkd'
  }, {
    title: 'Other means',
    data: 'cw_oth'
  }, {
    title: 'Worked at home',
    data: 'cw_wrkdhm'
  }, {
    divider: true
  }, {
    title: 'Mean travel time to work (minutes)',
    tooltip: 'Aggregate travel time to work, divided by workers 16 years and over who did not work at home',
    data: 'mntrvtm',
    special: true,
    decimal: 1
  }];
  _exports.default = _default;
});