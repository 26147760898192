define("labs-nyc-factfinder/queries/intersection", ["exports", "labs-nyc-factfinder/queries/summary-levels"], function (_exports, _summaryLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Intersection = (summaryLevel, geometry) => {
    const sqlBase = _summaryLevels.default[summaryLevel](false);

    return `SELECT * FROM (${sqlBase}) base WHERE ST_Intersects(the_geom, ST_GeomFromGeoJSON('${JSON.stringify(geometry)}'))`;
  };

  var _default = Intersection;
  _exports.default = _default;
});