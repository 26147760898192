define("labs-nyc-factfinder/components/lookup-layer-group", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      const recordIdentifier = this.get('for');
      const foundRecord = this.get('store').peekRecord('layer-group', recordIdentifier);

      if (foundRecord) {
        this.set('model', foundRecord);
      }
    },

    store: (0, _service.inject)(),
    for: ''
  });

  _exports.default = _default;
});