define("labs-nyc-factfinder/chart-config/acs/housing/vehicles-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'novhclav',
    label: 'No vehicles available'
  }, {
    property: 'vhcl1av',
    label: '1 vehicle available'
  }, {
    property: 'vhcl2av',
    label: '2 vehicles available'
  }, {
    property: 'vhcl3plav',
    label: '3 or more vehicles availabe'
  }];
  _exports.default = _default;
});