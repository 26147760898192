define("labs-nyc-factfinder/table-config/acs/index", ["exports", "labs-nyc-factfinder/table-config/acs/demographic", "labs-nyc-factfinder/table-config/acs/economic", "labs-nyc-factfinder/table-config/acs/housing", "labs-nyc-factfinder/table-config/acs/social"], function (_exports, _demographic, _economic, _housing, _social) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    demographic: _demographic.default,
    economic: _economic.default,
    housing: _housing.default,
    social: _social.default
  };
  _exports.default = _default;
});