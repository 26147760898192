define("labs-nyc-factfinder/chart-config/acs/social/index", ["exports", "labs-nyc-factfinder/chart-config/acs/social/household-type", "labs-nyc-factfinder/chart-config/acs/social/school-enrollment", "labs-nyc-factfinder/chart-config/acs/social/educational-attainment", "labs-nyc-factfinder/chart-config/acs/social/residence-1-year-ago", "labs-nyc-factfinder/chart-config/acs/social/place-of-birth", "labs-nyc-factfinder/chart-config/acs/social/foreign-born"], function (_exports, _householdType, _schoolEnrollment, _educationalAttainment, _residence1YearAgo, _placeOfBirth, _foreignBorn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    householdType: _householdType.default,
    schoolEnrollment: _schoolEnrollment.default,
    educationalAttainment: _educationalAttainment.default,
    residence1YearAgo: _residence1YearAgo.default,
    placeOfBirth: _placeOfBirth.default,
    foreignBorn: _foreignBorn.default
  };
  _exports.default = _default;
});