define("labs-nyc-factfinder/table-config/acs/housing/occupants-per-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Occupied housing units',
    highlight: true,
    data: 'ochu5'
  }, {
    title: '1.00 or less',
    data: 'ocpr0t1'
  }, {
    title: '1.01 or more',
    data: 'ocpr1pl'
  }, {
    indent: 1,
    title: '1.51 or more',
    data: 'ocpr1p5pl'
  }];
  _exports.default = _default;
});