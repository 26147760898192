define("labs-nyc-factfinder/table-config/acs/housing/vehicles-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Occupied housing units',
    highlight: true,
    data: 'ochu4'
  }, {
    title: 'No vehicles available',
    data: 'novhclav'
  }, {
    title: '1 vehicle available',
    data: 'vhcl1av'
  }, {
    title: '2 vehicles available',
    data: 'vhcl2av'
  }, {
    title: '3 or more vehicles available',
    data: 'vhcl3plav'
  }];
  _exports.default = _default;
});