define("labs-nyc-factfinder/table-config/acs/economic/class-of-worker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Civilian employed population 16 years and over',
    highlight: true,
    data: 'cvem16pl4'
  }, {
    title: 'Private wage and salary workers',
    data: 'prvwswrkr'
  }, {
    title: 'Government workers',
    data: 'gvtwrkr'
  }, {
    title: 'Self-employed workers in own not incorporated business',
    data: 'slfemninc'
  }, {
    title: 'Unpaid family workers',
    data: 'updfmwrkr'
  }];
  _exports.default = _default;
});