define("labs-nyc-factfinder/table-config/acs/housing/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Owner-occupied units',
    highlight: true,
    data: 'oochu2'
  }, {
    title: 'Less than $50,000',
    data: 'vlu50'
  }, {
    title: '$50,000 to $99,999',
    data: 'vl50t99'
  }, {
    title: '$100,000 to $149,999',
    data: 'vl100t149'
  }, {
    title: '$150,000 to $199,999',
    data: 'vl150t199'
  }, {
    title: '$200,000 to $299,999',
    data: 'vl200t299'
  }, {
    title: '$300,000 to $499,999',
    data: 'vl300t499'
  }, {
    title: '$500,000 to $999,999',
    data: 'vl500t999'
  }, {
    title: '$1,000,000 or more',
    data: 'vl1milpl'
  }, {
    title: 'Median (dollars)',
    tooltip: 'Medians are calculated using linear interpolation, which may result in top-coded values',
    data: 'mdvl',
    special: true
  }];
  _exports.default = _default;
});