define("labs-nyc-factfinder/components/explorer/download-data-dropdown", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DownloadDataDropdown = (_dec = (0, _service.inject)(), (_class = class DownloadDataDropdown extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "metrics", _descriptor, this);

      _initializerDefineProperty(this, "open", _descriptor2, this);
    }

    toggleOpen() {
      this.open = !this.open;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'download_data',
        'toggle': this.open ? 'Opened' : 'Closed'
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Download Data',
        eventAction: 'Toggle Menu',
        eventLabel: this.open ? 'Opened' : 'Closed'
      });
    }

    closeMenu() {
      if (this.open) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'download_data',
          'toggle': 'Closed'
        });
        this.metrics.trackEvent('GoogleAnalytics', {
          eventCategory: 'Download Data',
          eventAction: 'Toggle Menu',
          eventLabel: 'Closed'
        });
      }

      this.open = false;
    }

    downloadCSV(csv) {
      var csvFile;
      var downloadLink;
      const d = new Date(Date.now());
      var filename = ['popfactfinder.planning.nyc.gov_data', d.getFullYear(), ("0" + (d.getMonth() + 1)).slice(-2), ("0" + d.getDate()).slice(-2)].join('-').concat('.csv'); // CSV file

      csvFile = new Blob([csv], {
        type: "text/csv"
      }); // Download link

      downloadLink = document.createElement("a"); // File name

      downloadLink.download = filename; // Create a link to the file

      downloadLink.href = window.URL.createObjectURL(csvFile); // Hide download link

      downloadLink.style.display = "none"; // Add the link to DOM

      document.body.appendChild(downloadLink); // Click download link

      downloadLink.click();
    }

    convertStringForCSV(s) {
      if (s.indexOf(',') !== -1) {
        return '"'.concat(s).concat('"');
      }

      if (s === " ") {
        return "";
      }

      return s;
    }

    async exportTableToCSV() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'download_file',
        'filetype': 'csv'
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Download Data',
        eventAction: 'Download File',
        eventLabel: 'CSV'
      }); // This toggles the reliability data on if it is not before downloading

      const initialReliabilitySetting = this.args.showReliability;

      if (!initialReliabilitySetting) {
        await this.args.toggleReliability();
      }

      var csv = [];
      var rowspanCounter = 0;
      var rowspanText;
      var rows = document.querySelectorAll("h3, table tr");
      const geoSelection = document.querySelector(".profile-geographies");

      if (geoSelection) {
        csv.push(this.actions.convertStringForCSV(geoSelection.innerText));
      }

      for (var i = 0; i < rows.length; i++) {
        var row = [];

        if (rows[i].nodeName === "H3") {
          //add two blank rows above each table
          csv.push(null, null);
          row.push(this.actions.convertStringForCSV(rows[i].innerText));
        } else {
          var cols = rows[i].querySelectorAll("td, th"); //if the first cell of a row spans multiple rows, repeat that text in each row that it spans

          if (cols[0].rowSpan > 1) {
            rowspanCounter = cols[0].rowSpan - 1;
            rowspanText = this.actions.convertStringForCSV(cols[0].innerText);
          } else if (rowspanCounter > 0) {
            row.push(rowspanText);
            rowspanCounter--;
          }

          for (var j = 0; j < cols.length; j++) {
            row.push(this.actions.convertStringForCSV(cols[j].innerText)); // If a cell spans multiple columns, repeat the contents of that cell in each additional cell

            if (cols[j].colSpan > 1) {
              var additionalRepetitions = cols[j].colSpan;

              while (additionalRepetitions > 1) {
                row.push(this.actions.convertStringForCSV(cols[j].innerText));
                additionalRepetitions--;
              }
            }
          }
        }

        csv.push(row.join(","));
      } // Download CSV file


      this.actions.downloadCSV(csv.join("\n")); // This toggles the reliability data back off if it was off before downloading

      if (!initialReliabilitySetting) {
        this.args.toggleReliability();
      }
    }

    get numSelected() {
      return this.args.topics.reduce((prev, cur) => {
        if (cur.type === 'subtopic' && cur.selected === "selected") {
          return prev += 1;
        }

        return prev += cur.children.filter(child => child.selected === "selected").length;
      }, 0);
    }

    get source() {
      return this.args.sources.find(source => source.selected);
    }

    get selectedTopicsList() {
      var list = this.args.topics.map(topic => {
        return {
          label: topic.label,
          children: topic.children.filter(subtopic => subtopic.selected === "selected")
        };
      });
      return list.filter(topiary => topiary.children.length > 0);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadCSV", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCSV"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "convertStringForCSV", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "convertStringForCSV"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTableToCSV", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "exportTableToCSV"), _class.prototype)), _class));
  _exports.default = DownloadDataDropdown;
});