define("labs-nyc-factfinder/templates/components/comparison-area-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "acsxxemT",
    "block": "[[[8,[39,0],null,[[\"@searchField\",\"@options\",\"@placeholder\",\"@placeholderComponent\",\"@onChange\",\"@searchEnabled\"],[\"label\",[30,0,[\"nestedComparisonGeoOptions\"]],[30,1,[\"label\"]],\"comparison-area-selector-trigger\",[28,[37,1],[[30,0,[\"updateProperty\"]]],null],\"true\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,2,[\"label\"]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@comparisonGeo\",\"area\"],false,[\"power-select\",\"fn\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/comparison-area-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});