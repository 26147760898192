define("labs-nyc-factfinder/chart-config/census/household-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'fam',
    label: 'Family households (families)'
  }, {
    property: 'nfamhh',
    label: 'Nonfamily households'
  }];
  _exports.default = _default;
});