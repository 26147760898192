define("labs-nyc-factfinder/chart-config/acs/economic/commute-to-work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'cw_drvaln',
    label: 'Car, truck, or van -- drove alone'
  }, {
    property: 'cw_crpld',
    label: 'Car, truck, or van -- carpooled'
  }, {
    property: 'cw_pbtrns',
    label: 'Public transportation'
  }, {
    property: 'cw_wlkd',
    label: 'Walked'
  }, {
    property: 'cw_oth',
    label: 'Other means'
  }, {
    property: 'cw_wrkdhm',
    label: 'Worked at home'
  }];
  _exports.default = _default;
});