define("labs-nyc-factfinder/table-config/acs/housing/index", ["exports", "labs-nyc-factfinder/table-config/acs/housing/gross-rent-as-a-percentage-of-household-income--grapi", "labs-nyc-factfinder/table-config/acs/housing/gross-rent", "labs-nyc-factfinder/table-config/acs/housing/housing-occupancy", "labs-nyc-factfinder/table-config/acs/housing/housing-tenure", "labs-nyc-factfinder/table-config/acs/housing/mortgage-status", "labs-nyc-factfinder/table-config/acs/housing/occupants-per-room", "labs-nyc-factfinder/table-config/acs/housing/rooms", "labs-nyc-factfinder/table-config/acs/housing/selected-monthly-owner-costs-as-a-percentage-of-household-income--smocapi", "labs-nyc-factfinder/table-config/acs/housing/units-in-structure", "labs-nyc-factfinder/table-config/acs/housing/value", "labs-nyc-factfinder/table-config/acs/housing/vehicles-available", "labs-nyc-factfinder/table-config/acs/housing/year-householder-moved-into-unit", "labs-nyc-factfinder/table-config/acs/housing/year-structure-built"], function (_exports, _grossRentAsAPercentageOfHouseholdIncomeGrapi, _grossRent, _housingOccupancy, _housingTenure, _mortgageStatus, _occupantsPerRoom, _rooms, _selectedMonthlyOwnerCostsAsAPercentageOfHouseholdIncomeSmocapi, _unitsInStructure, _value, _vehiclesAvailable, _yearHouseholderMovedIntoUnit, _yearStructureBuilt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    grossRentAsAPercentageOfHouseholdIncomeGrapi: _grossRentAsAPercentageOfHouseholdIncomeGrapi.default,
    grossRent: _grossRent.default,
    housingOccupancy: _housingOccupancy.default,
    housingTenure: _housingTenure.default,
    mortgageStatus: _mortgageStatus.default,
    occupantsPerRoom: _occupantsPerRoom.default,
    rooms: _rooms.default,
    selectedMonthlyOwnerCostsAsAPercentageOfHouseholdIncomeSmocapi: _selectedMonthlyOwnerCostsAsAPercentageOfHouseholdIncomeSmocapi.default,
    unitsInStructure: _unitsInStructure.default,
    value: _value.default,
    vehiclesAvailable: _vehiclesAvailable.default,
    yearHouseholderMovedIntoUnit: _yearHouseholderMovedIntoUnit.default,
    yearStructureBuilt: _yearStructureBuilt.default
  };
  _exports.default = _default;
});