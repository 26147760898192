define("labs-nyc-factfinder/table-config/acs/demographic/index", ["exports", "labs-nyc-factfinder/table-config/acs/demographic/asian-subgroup", "labs-nyc-factfinder/table-config/acs/demographic/hispanic-subgroup", "labs-nyc-factfinder/table-config/acs/demographic/mutually-exclusive-race-hispanic-origin", "labs-nyc-factfinder/table-config/acs/demographic/sex-and-age"], function (_exports, _asianSubgroup, _hispanicSubgroup, _mutuallyExclusiveRaceHispanicOrigin, _sexAndAge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    asianSubgroup: _asianSubgroup.default,
    hispanicSubgroup: _hispanicSubgroup.default,
    mutuallyExclusiveRaceHispanicOrigin: _mutuallyExclusiveRaceHispanicOrigin.default,
    sexAndAge: _sexAndAge.default
  };
  _exports.default = _default;
});