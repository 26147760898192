define("labs-nyc-factfinder/helpers/dasherize", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function (params) {
    return (0, _string.dasherize)(params[0]);
  });

  _exports.default = _default;
});