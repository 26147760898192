define("labs-nyc-factfinder/table-config/census/relationship-to-head-of-household--householder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    data: 'pop3',
    title: 'Total population',
    highlight: true
  }, {
    data: 'popinhh_1',
    title: 'In households'
  }, {
    data: 'hhldr',
    title: 'Householder',
    indent: 1
  }, {
    data: 'spouse',
    title: 'Spouse',
    indent: 1
  }, {
    data: 'opsxs',
    title: 'Opposite-sex spouse',
    indent: 2
  }, {
    data: 'smsxs',
    title: 'Same-sex spouse',
    indent: 2
  }, {
    data: 'umrdptnr',
    title: 'Unmarried partner',
    indent: 1
  }, {
    data: 'opsxumrd',
    title: 'Opposite-sex unmarried partner',
    indent: 2
  }, {
    data: 'smsxumrd',
    title: 'Same-sex unmarried partner',
    indent: 2
  }, {
    data: 'child',
    title: 'Child',
    indent: 1
  }, {
    data: 'childu18',
    title: 'Under 18 years',
    indent: 2
  }, {
    data: 'grndch',
    title: 'Grandchild',
    indent: 1
  }, {
    data: 'grndchu18',
    title: 'Under 18 years',
    indent: 2
  }, {
    data: 'othrrel',
    title: 'Other relatives',
    indent: 1
  }, {
    data: 'nonrel',
    title: 'Nonrelatives',
    indent: 1
  }, {
    data: 'ingrpqtrs',
    title: 'In group quarters'
  }, {
    data: 'institlzd',
    title: 'Institutionalized population',
    indent: 1
  }, {
    data: 'gqcrctl',
    title: 'Correctional facilities for adults',
    indent: 2
  }, {
    data: 'gqjuv',
    title: 'Juvenile facilities',
    indent: 2
  }, {
    data: 'gqnrsng',
    title: 'Nursing facilities/Skilled-nursing facilities',
    indent: 2
  }, {
    data: 'gqoinst',
    title: 'Other institutional facilities',
    indent: 2
  }, {
    data: 'noinstlzd',
    title: 'Noninstitutionalized population',
    indent: 1
  }, {
    data: 'gqclghsg',
    title: 'College/University student housing',
    indent: 2
  }, {
    data: 'gqmltry',
    title: 'Military quarters',
    indent: 2
  }, {
    data: 'gqoninst',
    title: 'Other noninstitutional facilities',
    indent: 2
  }];
  _exports.default = _default;
});