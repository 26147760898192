define("labs-nyc-factfinder/controllers/explorer", ["exports", "@ember/object", "@ember/controller", "ember-concurrency", "@glimmer/tracking", "@ember/service", "labs-nyc-factfinder/topics-config/acs", "labs-nyc-factfinder/topics-config/census", "labs-nyc-factfinder/sources-config", "labs-nyc-factfinder/utils/fetch-explorer-model"], function (_exports, _object, _controller, _emberConcurrency, _tracking, _service, _acs, _census, _sourcesConfig, _fetchExplorerModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExplorerController = (_dec = (0, _service.inject)(), _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class ExplorerController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "metrics", _descriptor, this);

      _defineProperty(this, "queryParams", [{
        sourceId: 'source'
      }, 'censusTopics', 'acsTopics', 'compareTo', 'showReliability', 'showCharts']);

      _initializerDefineProperty(this, "showCharts", _descriptor2, this);

      _initializerDefineProperty(this, "sourceId", _descriptor3, this);

      _initializerDefineProperty(this, "censusTopics", _descriptor4, this);

      _initializerDefineProperty(this, "acsTopics", _descriptor5, this);

      _initializerDefineProperty(this, "showReliability", _descriptor6, this);

      _initializerDefineProperty(this, "isLoading", _descriptor7, this);

      // The comparison geography ID.
      // Must match ID of one option in comparisonGeoOptions.
      // Default "0" maps to NYC.
      _initializerDefineProperty(this, "compareTo", _descriptor8, this);

      _defineProperty(this, "toggleChildren", (children, selectedValue) => {
        return children.map(child => {
          return { ...child,
            selected: selectedValue,
            children: this.toggleChildren(child.children, selectedValue)
          };
        });
      });

      _defineProperty(this, "toggleTopicInList", (topics, itemId) => {
        return topics.map(topic => {
          if (topic.id === itemId) {
            return { ...topic,
              selected: 'selected',
              children: this.toggleChildren(topic.children, 'selected')
            };
          }

          if (topic.children && topic.children.length > 0) {
            const newChildren = this.toggleTopicInList(topic.children, itemId);
            let newSelectedValue = 'indeterminate';

            if (newChildren.every(child => child.selected === 'selected')) {
              newSelectedValue = 'selected';
            }

            if (newChildren.every(child => child.selected === 'unselected')) {
              newSelectedValue = 'unselected';
            }

            return { ...topic,
              selected: newSelectedValue,
              children: newChildren
            };
          }

          return topic;
        });
      });
    }

    pauseToRerender(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }

    toggleSourceInList(sourceId) {
      return _sourcesConfig.default.map(source => {
        if (source.id === sourceId) {
          return { ...source,
            selected: true
          };
        }

        return { ...source,
          selected: false
        };
      });
    }

    get comparisonGeo() {
      if (this.model.comparisonGeoOptions) {
        return this.model.comparisonGeoOptions.findBy('geoid', this.compareTo);
      }

      return null;
    }

    get sources() {
      if (this.sourceId) {
        return this.toggleSourceInList(this.sourceId);
      }

      return _sourcesConfig.default;
    }

    get source() {
      return this.sources.find(source => source.selected);
    }

    set source(newSource) {
      const {
        id
      } = newSource;
      sessionStorage.setItem('source', id);
      this.transitionToRoute('explorer', {
        queryParams: {
          source: id
        }
      });
    } // returns either 'current', 'previous' or 'change'


    get mode() {
      return this.source.mode;
    }

    get surveyData() {
      return this.source.type === 'acs' ? this.model.acs : this.model.decennial;
    }

    get topicsIdList() {
      const rawTopicsList = this.source.type === 'census' ? this.censusTopics : this.acsTopics;

      if (rawTopicsList === 'all') {
        if (this.source.type === 'census') {
          return _census.default.map(topic => topic.id);
        }

        return _acs.default.reduce((topicsIdList, curTopic) => {
          return topicsIdList.concat(curTopic.children.map(subtopic => subtopic.id));
        }, []);
      } else if (rawTopicsList === 'none') {
        return [];
      } else {
        return rawTopicsList.split(',');
      }
    }

    get topics() {
      if (Array.isArray(this.topicsIdList)) {
        if (this.source.type === 'census') {
          return this.topicsIdList.reduce((topicsIdList, curTopicId) => {
            return this.toggleTopicInList(topicsIdList, curTopicId);
          }, _census.default);
        }

        return this.topicsIdList.reduce((topicsIdList, curTopicId) => {
          return this.toggleTopicInList(topicsIdList, curTopicId);
        }, _acs.default);
      }

      return [];
    }

    set topics(newTopics) {
      const qpKey = this.source.type === 'census' ? 'censusTopics' : 'acsTopics';
      sessionStorage.setItem([qpKey], newTopics);
      this.transitionToRoute('explorer', {
        queryParams: {
          [qpKey]: newTopics
        }
      });
    }

    get isAllTopicsSelected() {
      const {
        topics
      } = this;

      if (topics.every(topic => topic.selected === 'selected')) {
        return 'selected';
      }

      if (topics.every(topic => topic.selected === 'unselected')) {
        return 'unselected';
      }

      return 'indeterminate';
    }

    get selectedCount() {
      return this.model.selection.features.length;
    }

    get sortedLabels() {
      var {
        features
      } = this.model.selection;

      if (['districts', 'boroughs'].includes(this.model.selection.type)) {
        for (var i = 0; i < features.length; i++) {
          features[i].properties.borocode = features[i].properties.geoid.toString().slice(0, 1);

          if (this.model.selection.type === 'districts') {
            features[i].properties.district = features[i].properties.geoid % 100;
          }
        }
      } else if (this.model.selection.type === 'ccds') {
        return features.map(ccd => ccd.properties.geolabel).sort((a, b) => a - b);
      }

      const bronx = features.filter(d => d.properties.borocode === '2');
      const brooklyn = features.filter(d => d.properties.borocode === '3');
      const manhattan = features.filter(d => d.properties.borocode === '1');
      const queens = features.filter(d => d.properties.borocode === '4');
      const statenisland = features.filter(d => d.properties.borocode === '5');
      return [{
        label: 'Bronx',
        features: bronx
      }, {
        label: 'Brooklyn',
        features: brooklyn
      }, {
        label: 'Manhattan',
        features: manhattan
      }, {
        label: 'Queens',
        features: queens
      }, {
        label: 'Staten Island',
        features: statenisland
      }].filter(d => d.features.length > 0);
    }

    get agePopDist() {
      const {
        surveyData
      } = this;
      const variables = ['pop0t5', 'pop5t9', 'pop10t14', 'pop15t19', 'pop20t24', 'pop25t29', 'pop30t34', 'pop35t39', 'pop40t44', 'pop45t49', 'pop50t54', 'pop55t59', 'pop60t64', 'pop65t69', 'pop70t74', 'pop75t79', 'pop80t84', 'pop85pl'];
      const pyramidData = variables.map(variable => {
        const male = surveyData[`m${variable}`];
        const female = surveyData[`f${variable}`];
        return {
          group: variable,
          male,
          female
        };
      });
      return pyramidData;
    }

    setSource(newSource) {
      this.source = newSource;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'select_data_source',
        select_data_source: newSource.label
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Data Source',
        eventAction: 'Select Data Source',
        eventLabel: newSource.label
      });
    }

    async toggleTopic(topic) {
      this.isLoading = true;
      await this.pauseToRerender(1);

      if (topic.type === 'subtopic') {
        if (this.topicsIdList.includes(topic.id)) {
          this.topics = this.topicsIdList.filter(topicId => topicId !== topic.id);
        } else {
          this.topics = this.topicsIdList.concat([topic.id]);
        }
      }

      if (topic.type === 'topic') {
        const topicChildrenIds = topic.children.map(subtopic => subtopic.id);

        if (topic.selected === 'selected' || topic.selected === 'indeterminate') {
          this.topics = this.topicsIdList.filter(topicId => !topicChildrenIds.includes(topicId));
        } else {
          this.topics = this.topicsIdList.concat(topicChildrenIds);
        }
      }

      this.isLoading = false;
    }

    async toggleAllTopics() {
      this.isLoading = true;
      await this.pauseToRerender(1);
      this.topics = this.isAllTopicsSelected === 'unselected' ? 'all' : 'none';
      this.isLoading = false;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'select_all_topics',
        toggle: this.isAllTopicsSelected === 'unselected' ? 'none' : 'all'
      });
      this.metrics.trackEvent('GoogleAnalytics', {
        eventCategory: 'Select Topics',
        eventAction: 'Select All Topics Toggle',
        eventLabel: this.isAllTopicsSelected === 'unselected' ? 'none' : 'all'
      });
    } // acceptable controlId values include: 'showReliability', 'showCharts'


    toggleBooleanControl(controlId) {
      let {
        [controlId]: currentControlValue
      } = this;

      if (controlId === 'showReliability') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'toggle_show_reliability_data',
          show_reliability_data: !currentControlValue
        });
        this.metrics.trackEvent('GoogleAnalytics', {
          eventCategory: 'Show Reliability Data',
          eventAction: 'Toggle Reliability Data',
          eventLabel: !currentControlValue
        });
      } else if (controlId === 'showCharts') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'toggle_show_charts',
          show_charts: !currentControlValue
        });
        this.metrics.trackEvent('GoogleAnalytics', {
          eventCategory: 'Show Charts',
          eventAction: 'Toggle Charts',
          eventLabel: !currentControlValue
        });
      }

      sessionStorage.setItem([controlId], !currentControlValue);
      this.transitionToRoute('explorer', {
        queryParams: {
          [controlId]: !currentControlValue
        }
      });
    }

    *reloadExplorerModel(newGeoid) {
      yield this.store.unloadAll('row');
      const newExplorerModel = yield (0, _fetchExplorerModel.default)(this.store, this.model.geotype, this.model.geoid, newGeoid);
      this.model = newExplorerModel;
      sessionStorage.setItem('compareTo', newGeoid);
      yield this.transitionToRoute('explorer', {
        queryParams: {
          compareTo: newGeoid
        }
      });
    }

    updateCompareTo(geoid) {
      // TODO: Figure out how to reload the model on compareTo queryParam update, instead of
      // manually firing off the Task here.
      this.reloadExplorerModel.perform(geoid);
    }

    toggleReliability() {
      this.showReliability = !this.showReliability;
      /*global Promise*/

      return new Promise(resolve => {
        setTimeout(function () {
          resolve('slow');
        }, 1);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showCharts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sourceId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'decennial-current';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "censusTopics", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'populationSexAgeDensity,mutuallyExclusiveRaceHispanicOrigin,detailedRaceAndEthnicity,relationHeadHousehold,householdType,housingOccupancy,housingTenure,householdSize';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "acsTopics", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'demo-sexAndAge,demo-mutuallyExclusiveRaceHispanicOrigin,demo-hispanicSubgroup,demo-asianSubgroup';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showReliability", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "compareTo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '0';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setSource", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleTopic", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTopic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAllTopics", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllTopics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBooleanControl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBooleanControl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadExplorerModel", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "reloadExplorerModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCompareTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateCompareTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleReliability", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleReliability"), _class.prototype)), _class));
  _exports.default = ExplorerController;
});