define("labs-nyc-factfinder/table-config/acs/social/index", ["exports", "labs-nyc-factfinder/table-config/acs/social/ancestry", "labs-nyc-factfinder/table-config/acs/social/computers-and-internet-use", "labs-nyc-factfinder/table-config/acs/social/u-s--citizenship-status", "labs-nyc-factfinder/table-config/acs/social/disability-status-of-the-civilian-noninstitutionalized-population", "labs-nyc-factfinder/table-config/acs/social/educational-attainment--highest-grade-completed", "labs-nyc-factfinder/table-config/acs/social/grandparents", "labs-nyc-factfinder/table-config/acs/social/household-type", "labs-nyc-factfinder/table-config/acs/social/language-spoken-at-home", "labs-nyc-factfinder/table-config/acs/social/marital-status", "labs-nyc-factfinder/table-config/acs/social/place-of-birth", "labs-nyc-factfinder/table-config/acs/social/relationship-to-head-of-household--householder", "labs-nyc-factfinder/table-config/acs/social/residence-1-year-ago", "labs-nyc-factfinder/table-config/acs/social/school-enrollment", "labs-nyc-factfinder/table-config/acs/social/veteran-status", "labs-nyc-factfinder/table-config/acs/social/year-of-entry"], function (_exports, _ancestry, _computersAndInternetUse, _uSCitizenshipStatus, _disabilityStatusOfTheCivilianNoninstitutionalizedPopulation, _educationalAttainmentHighestGradeCompleted, _grandparents, _householdType, _languageSpokenAtHome, _maritalStatus, _placeOfBirth, _relationshipToHeadOfHouseholdHouseholder, _residence1YearAgo, _schoolEnrollment, _veteranStatus, _yearOfEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    ancestry: _ancestry.default,
    computersAndInternetUse: _computersAndInternetUse.default,
    uSCitizenshipStatus: _uSCitizenshipStatus.default,
    disabilityStatusOfTheCivilianNoninstitutionalizedPopulation: _disabilityStatusOfTheCivilianNoninstitutionalizedPopulation.default,
    educationalAttainmentHighestGradeCompleted: _educationalAttainmentHighestGradeCompleted.default,
    grandparents: _grandparents.default,
    householdType: _householdType.default,
    languageSpokenAtHome: _languageSpokenAtHome.default,
    maritalStatus: _maritalStatus.default,
    placeOfBirth: _placeOfBirth.default,
    relationshipToHeadOfHouseholdHouseholder: _relationshipToHeadOfHouseholdHouseholder.default,
    residence1YearAgo: _residence1YearAgo.default,
    schoolEnrollment: _schoolEnrollment.default,
    veteranStatus: _veteranStatus.default,
    yearOfEntry: _yearOfEntry.default
  };
  _exports.default = _default;
});