define("labs-nyc-factfinder/table-config/acs/housing/selected-monthly-owner-costs-as-a-percentage-of-household-income--smocapi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Housing units with a mortgage (excluding units where SMOCAPI cannot be computed)',
    highlight: true,
    data: 'huwmrtgex'
  }, {
    title: 'Less than 20.0 percent',
    data: 'smpu20'
  }, {
    title: '20.0 to 24.9 percent',
    data: 'smp20t24'
  }, {
    title: '25.0 to 29.9 percent',
    data: 'smp25t29'
  }, {
    title: '30.0 to 34.9 percent',
    data: 'smp30t34'
  }, {
    title: '35.0 percent or more',
    data: 'smp35pl'
  }, {
    divider: true
  }, {
    title: 'Not computed',
    data: 'smpntc',
    special: true
  }, {
    divider: true
  }, {
    title: 'Housing unit without a mortgage (excluding units where SMOCAPI cannot be computed)',
    highlight: true,
    data: 'hunomrtg2'
  }, {
    title: 'Less than 10.0 percent',
    data: 'nmsmpu10'
  }, {
    title: '10.0 to 14.9 percent',
    data: 'nmsmp1014'
  }, {
    title: '15.0 to 19.9 percent',
    data: 'nmsmp1519'
  }, {
    title: '20.0 to 24.9 percent',
    data: 'nmsmp2024'
  }, {
    title: '25.0 to 29.9 percent',
    data: 'nmsmp2529'
  }, {
    title: '30.0 to 34.9 percent',
    data: 'nmsmp3034'
  }, {
    title: '35.0 percent or more',
    data: 'nmsmp35pl'
  }, {
    divider: true
  }, {
    title: 'Not computed',
    data: 'nmsmpntc',
    special: true
  }];
  _exports.default = _default;
});