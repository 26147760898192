define("labs-nyc-factfinder/mixins/track-page", ["exports", "@ember/service", "@ember/object/mixin", "@ember/object", "@ember/runloop"], function (_exports, _service, _mixin, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    metrics: (0, _service.inject)(),

    didTransition() {
      this._super(...arguments);

      this._trackPage();
    },

    _trackPage() {
      (0, _runloop.scheduleOnce)('afterRender', this, () => {
        const page = this.get('url');
        const title = this.get('currentRouteName') || 'unknown';
        (0, _object.get)(this, 'metrics').trackPage({
          page,
          title
        });
      });
    }

  });

  _exports.default = _default;
});