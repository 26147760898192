define("labs-nyc-factfinder/templates/components/reveal-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LukseEzw",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"reveal-overlay\"],[14,5,\"display:block;\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"reveal-overlay-target\"],[4,[38,1],[[30,0],[30,2]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"reveal\"],[14,\"role\",\"dialog\"],[14,\"aria-hidden\",\"false\"],[14,\"tabindex\",\"-1\"],[14,5,\"display:block;\"],[14,\"data-test-confirmation-modal\",\"\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n      \"],[11,\"button\"],[24,0,\"close-button\"],[24,\"aria-label\",\"Close modal\"],[24,4,\"button\"],[4,[38,1],[[30,0],[30,2]],null],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@open\",\"@closeModal\",\"&default\"],false,[\"if\",\"action\",\"yield\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/reveal-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});