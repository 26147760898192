define("labs-nyc-factfinder/table-config/acs/social/relationship-to-head-of-household--householder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population in households',
    highlight: true,
    data: 'hhpop'
  }, {
    title: 'Householder',
    data: 'rshphhldr'
  }, {
    title: 'Spouse',
    data: 'rshp_sp'
  }, {
    title: 'Child',
    data: 'rshp_ch'
  }, {
    title: 'Other relatives',
    data: 'rshp_othr'
  }, {
    title: 'Nonrelatives',
    data: 'rshp_nr'
  }, {
    indent: 1,
    title: 'Unmarried partner',
    data: 'rshp_nrup'
  }, {
    indent: 2,
    title: 'Same sex unmarried partner',
    data: 'rshp_ssup'
  }];
  _exports.default = _default;
});