define("labs-nyc-factfinder/services/registered-layers", ["exports", "ember", "@ember/object"], function (_exports, _ember, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const flattenedIds = function (layers) {
    return layers.map(layer => layer.config.layers.mapBy('layer.id')).reduce((accumulator, curr) => accumulator.concat(curr), []);
  };

  var _default = _ember.default.Service.extend({
    layers: [],
    currentlyVisible: (0, _object.computed)('layers.@each.visible', function () {
      const layers = this.get('layers');
      return layers.filterBy('visible', true);
    }),
    layerGroupIds: (0, _object.computed)('layers.@each', function () {
      const layers = this.get('layers');
      return layers.mapBy('config.id');
    }),
    layerIds: (0, _object.computed)('layers.@each', function () {
      const layers = this.get('layers');
      return flattenedIds(layers);
    }),
    visibleLayerIds: (0, _object.computed)('currentlyVisible', function () {
      const layers = this.get('layers');
      return flattenedIds(layers);
    }),
    highlightableAndVisibleLayerIds: (0, _object.computed)('currentlyVisible', function () {
      const layers = this.get('currentlyVisible'); // return an array of layerids that are both visible and highlightable

      return layers.map(layer => layer.config.layers.filter(l => l.highlightable).map(l => l.layer.id)).reduce((accumulator, curr) => accumulator.concat(curr), []);
    }),
    clickableAndVisibleLayerIds: (0, _object.computed)('currentlyVisible', function () {
      const layers = this.get('layers'); // return an array of layerids that are both visible and highlightable

      return layers.map(layer => layer.config.layers.filter(l => l.clickable).map(l => l.layer.id)).reduce((accumulator, curr) => accumulator.concat(curr), []);
    }),

    getTooltipTemplate(id) {
      // find the layer with this id, return its tooltipTemplate
      const layer = this.get('layers').reduce((accumulator, curr) => {
        const match = curr.config.layers.filter(l => l.layer.id === id);
        return match.length > 0 ? accumulator.concat(match[0]) : accumulator;
      }, [])[0];
      return layer.tooltipTemplate;
    },

    findLayer(id) {
      return this.get('layers').findBy('config.id', id);
    }

  });

  _exports.default = _default;
});