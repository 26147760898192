define("labs-nyc-factfinder/chart-config/acs/housing/index", ["exports", "labs-nyc-factfinder/chart-config/acs/housing/housing-tenure", "labs-nyc-factfinder/chart-config/acs/housing/value", "labs-nyc-factfinder/chart-config/acs/housing/vehicles-available", "labs-nyc-factfinder/chart-config/acs/housing/gross-rent", "labs-nyc-factfinder/chart-config/acs/housing/gross-rent-grapi"], function (_exports, _housingTenure, _value, _vehiclesAvailable, _grossRent, _grossRentGrapi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    housingTenure: _housingTenure.default,
    value: _value.default,
    vehiclesAvailable: _vehiclesAvailable.default,
    grossRent: _grossRent.default,
    grossRentGrapi: _grossRentGrapi.default
  };
  _exports.default = _default;
});