define("labs-nyc-factfinder/templates/components/scroll-to", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "upwN9n9o",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"label\"]]",
    "moduleName": "labs-nyc-factfinder/templates/components/scroll-to.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});