define("labs-nyc-factfinder/table-config/acs/housing/units-in-structure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Total housing units',
    highlight: true,
    data: 'hu2'
  }, {
    title: '1-unit, detached',
    data: 'hu1ud'
  }, {
    title: '1-unit, attached',
    data: 'hu1ua'
  }, {
    title: '2 units',
    data: 'hu2u'
  }, {
    title: '3 or 4 units',
    data: 'hu3t4u'
  }, {
    title: '5 to 9 units',
    data: 'hu5t9u'
  }, {
    title: '10 to 19 units',
    data: 'hu10t19u'
  }, {
    title: '20 or more units',
    data: 'hu20plu'
  }, {
    title: 'Mobile home',
    data: 'mobhm'
  }, {
    title: 'Boat, RV, van, etc.',
    data: 'btrvvetc'
  }];
  _exports.default = _default;
});