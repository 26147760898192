define("labs-nyc-factfinder/components/mapbox-gl-image", ["exports", "ember-mapbox-gl/components/mapbox-gl-image"], function (_exports, _mapboxGlImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mapboxGlImage.default;
    }
  });
});