define("labs-nyc-factfinder/table-config/acs/economic/employment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population 16 years and over',
    highlight: true,
    data: 'pop16pl'
  }, {
    title: 'In labor force',
    data: 'lf'
  }, {
    title: 'Civilian labor force',
    indent: 1,
    data: 'cvlf1'
  }, {
    title: 'Employed',
    indent: 2,
    data: 'cvem16pl1'
  }, {
    title: 'Unemployed',
    indent: 2,
    data: 'cvlfuem1'
  }, {
    title: 'Armed Forces',
    indent: 1,
    data: 'lfarmdf'
  }, {
    title: 'Not in labor force',
    data: 'nlf1'
  }, {
    divider: true
  }, {
    title: 'Civilian labor force',
    highlight: true,
    data: 'cvlf2'
  }, {
    title: 'Unemployed (percent equivalent to unemployment rate)',
    data: 'cvlfuem2'
  }, {
    divider: true
  }, {
    title: 'Females 16 years and over',
    highlight: true,
    data: 'f16pl'
  }, {
    title: 'In labor force',
    data: 'f16pllf'
  }, {
    title: 'Civilian labor force',
    indent: 1,
    data: 'f16plcvlf'
  }, {
    title: 'Employed',
    indent: 2,
    data: 'f16plclfe'
  }, {
    divider: true
  }, {
    title: 'Own children under 6 years',
    highlight: true,
    data: 'ochu6'
  }, {
    title: 'All parents in family in labor force',
    data: 'ochu6plf'
  }, {
    divider: true
  }, {
    title: 'Own children 6 to 17 years',
    highlight: true,
    data: 'och6t17'
  }, {
    title: 'All parents in family in labor force',
    data: 'oc6t17plf'
  }];
  _exports.default = _default;
});