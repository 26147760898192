define("labs-nyc-factfinder/components/map-utility-box", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember/object", "ember-concurrency", "numeral", "fetch", "labs-nyc-factfinder/config/environment", "labs-nyc-factfinder/choropleth-config"], function (_exports, _component, _computed, _service, _object, _emberConcurrency, _numeral, _fetch, _environment, _choroplethConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    SupportServiceHost
  } = _environment.default;

  var _default = _component.default.extend({
    selection: (0, _service.inject)(),
    router: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    lastreport: null,
    choroplethConfigs: _choroplethConfig.default,
    selectionCount: (0, _computed.alias)('selection.selectedCount'),
    mode: 'direct-select',
    advanced: false,
    choroplethMode: 'popacre',
    summaryLevel: (0, _computed.alias)('selection.summaryLevel'),
    selectionSummaryData: (0, _computed.alias)('selection.current.selectionSummary.totals'),
    choroplethPaintFill: (0, _object.computed)('choroplethMode', function () {
      const {
        choroplethMode: mode
      } = this.getProperties('choroplethMode');
      return _choroplethConfig.default.find(d => d.id === mode).paintFill;
    }),

    choroplethPaintLine(mode) {
      return _choroplethConfig.default.find(d => d.id === mode).paintLine;
    },

    legendTitle: (0, _object.computed)('choroplethMode', function () {
      const {
        choroplethMode: mode
      } = this.getProperties('choroplethMode');
      return _choroplethConfig.default.find(d => d.id === mode).legendTitle;
    }),
    stops: (0, _object.computed)('choroplethMode', function () {
      const {
        choroplethMode: mode
      } = this.getProperties('choroplethMode'); // return an array of objects, each with a display-ready range and color

      const config = _choroplethConfig.default.find(d => d.id === mode);

      const {
        isPercent,
        stops,
        colors
      } = config;

      const format = value => {
        // eslint-disable-line
        return isPercent ? `${value}%` : (0, _numeral.default)(value).format('0,0');
      };

      return [{
        label: `${format(stops[3])} or more`,
        color: colors[4]
      }, {
        label: `${format(stops[2])} - ${format(stops[3] - 1)}`,
        color: colors[3]
      }, {
        label: `${format(stops[1])} - ${format(stops[2] - 1)}`,
        color: colors[2]
      }, {
        label: `${format(stops[0])} - ${format(stops[1] - 1)}`,
        color: colors[1]
      }, {
        label: isPercent ? `Less than ${format(stops[0])}` : `Under ${format(stops[0])}`,
        color: colors[0]
      }];
    }),
    generateExplorerPageTask: (0, _emberConcurrency.task)(function* (type, geoids, queryParams) {
      const postBody = {
        geotype: type,
        geoids
      };
      const {
        id: selectionId
      } = yield (0, _fetch.default)(`${SupportServiceHost}/selection`, {
        // eslint-disable-line
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(postBody)
      }).then(d => d.json());
      yield this.get('router').transitionTo('explorer', 'selection', selectionId, {
        queryParams
      });
    }).restartable(),

    clearSelection() {
      this.get('selection').clearSelection();
    },

    handleDrawButtonClick(type) {
      const el = this.get('element').getElementsByClassName('draw-tool');
      this.sendAction('handleDrawButtonClick', type);
      this.$(el).blur();
    },

    handleDrawRadiusButtonClick() {
      this.sendAction('handleDrawRadiusButtonClick');
    },

    transitionTo() {},

    generateExplorerPage() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'see_more_data',
        'selection_geotype': this.get('summaryLevel'),
        'selection_itemcount': this.get('selection.current.features.length')
      });
      this.get('metrics').trackEvent('GoogleAnalytics', {
        eventCategory: 'Selection',
        eventAction: 'Created Profile',
        eventLabel: this.get('summaryLevel'),
        eventValue: this.get('selection.current.features.length')
      });
      const type = this.get('summaryLevel');
      const geoids = this.get('selection.current.features').mapBy('properties.geoid');
      let queryParams = {};

      if (sessionStorage.length) {
        let queryKeys = ['source', 'censusTopics', 'acsTopics', 'compareTo', 'showReliability'];
        queryKeys.forEach(key => {
          if (sessionStorage[key]) queryParams[key] = sessionStorage[key];
        });
      } // Reset the source for geotypes that do not have ACS data.


      if (['districts'].includes(type) && ['acs-current', 'acs-previous', 'acs-change'].includes(queryParams['source'])) {
        queryParams['source'] = 'decennial-current';
      }

      if (geoids.length > 1) {
        this.get('generateExplorerPageTask').perform(type, geoids, queryParams);
      } else if (geoids.length === 1) {
        this.get('router').transitionTo('explorer', type, geoids[0], {
          queryParams
        });
      } else {
        console.log("Warning: Cannot generate profile because selected geoids array is empty.");
      }
    },

    setChoroplethMode(mode) {
      this.get('metrics').trackEvent('GoogleAnalytics', {
        eventCategory: 'Advanced Options',
        eventAction: 'Selected Choropleth',
        eventLabel: this.get('choroplethMode')
      });
      this.set('choroplethMode', mode);
    }

  });

  _exports.default = _default;
});