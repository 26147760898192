define("labs-nyc-factfinder/chart-config/acs/economic/income-and-benefits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'hhiu10',
    label: 'Less than $10,000'
  }, {
    property: 'hhi10t14',
    label: '$10,000 to $14,999'
  }, {
    property: 'hhi15t24',
    label: '$15,000 to $24,999'
  }, {
    property: 'hhi25t34',
    label: '$25,000 to $34,999'
  }, {
    property: 'hhi35t49',
    label: '$35,000 to $49,999'
  }, {
    property: 'hhi50t74',
    label: '$50,000 to $74,999'
  }, {
    property: 'hhi75t99',
    label: '$75,000 to $99,999'
  }, {
    property: 'hi100t149',
    label: '$100,000 to $149,999'
  }, {
    property: 'hi150t199',
    label: '$150,000 to $199,999'
  }, {
    property: 'hhi200pl',
    label: '$200,000 or more'
  }];
  _exports.default = _default;
});