define("labs-nyc-factfinder/table-config/acs/economic/income_in_past_12_months_is_below_the_poverty_level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'All families',
    highlight: true,
    data: 'fampvu'
  }, {
    title: 'Below poverty',
    data: 'fambwpv'
  }, {
    title: 'Population for whom poverty status is determined',
    highlight: true,
    data: 'poppvu1'
  }, {
    title: 'Below poverty',
    data: 'pbwpv'
  }, {
    indent: 1,
    title: 'Under 18 years',
    highlight: true,
    data: 'pu18pvu'
  }, {
    indent: 1,
    title: 'Below poverty',
    data: 'pu18bwpv'
  }, {
    indent: 1,
    title: '65 years and over',
    highlight: true,
    data: 'p65plpvu'
  }, {
    indent: 1,
    title: 'Below poverty',
    data: 'p65plbwpv'
  }];
  _exports.default = _default;
});