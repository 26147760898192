define("labs-nyc-factfinder/table-config/acs/social/educational-attainment--highest-grade-completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Population 25 years and over',
    highlight: true,
    data: 'ea_p25pl'
  }, {
    title: 'Less than 9th grade',
    data: 'ea_lt9g'
  }, {
    title: '9th to 12th grade, no diploma',
    data: 'ea_9t12nd'
  }, {
    title: 'High school graduate (includes equivalency)',
    data: 'ea_hscgrd'
  }, {
    title: 'Some college, no degree',
    data: 'ea_sclgnd'
  }, {
    title: 'Associate\'s degree',
    data: 'ea_ascd'
  }, {
    title: 'Bachelor\'s degree',
    data: 'ea_bchd'
  }, {
    title: 'Graduate or professional degree',
    data: 'ea_grdpfd'
  }, {
    divider: true
  }, {
    title: 'Less than high school graduate',
    data: 'ea_lthsgr'
  }, {
    title: 'Bachelor\'s degree or higher',
    data: 'ea_bchdh'
  }];
  _exports.default = _default;
});