define("labs-nyc-factfinder/chart-config/acs/social/foreign-born", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    property: 'eur',
    label: 'Europe '
  }, {
    property: 'asia',
    label: 'Asia'
  }, {
    property: 'afr',
    label: 'Africa'
  }, {
    property: 'lam',
    label: 'Latin America'
  }];
  _exports.default = _default;
});