define("labs-nyc-factfinder/table-config/census/index", ["exports", "labs-nyc-factfinder/table-config/census/housing-occupancy", "labs-nyc-factfinder/table-config/census/housing-tenure", "labs-nyc-factfinder/table-config/census/household-type", "labs-nyc-factfinder/table-config/census/household-size", "labs-nyc-factfinder/table-config/census/mutually-exclusive-race---hispanic-origin", "labs-nyc-factfinder/table-config/census/relationship-to-head-of-household--householder", "labs-nyc-factfinder/table-config/census/population-sex-age-density", "labs-nyc-factfinder/table-config/census/detailed-race-and-ethnicity"], function (_exports, _housingOccupancy, _housingTenure, _householdType, _householdSize, _mutuallyExclusiveRaceHispanicOrigin, _relationshipToHeadOfHouseholdHouseholder, _populationSexAgeDensity, _detailedRaceAndEthnicity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @create-index
  var _default = {
    householdType: _householdType.default,
    housingOccupancy: _housingOccupancy.default,
    housingTenure: _housingTenure.default,
    householdSize: _householdSize.default,
    mutuallyExclusiveRaceHispanicOrigin: _mutuallyExclusiveRaceHispanicOrigin.default,
    relationshipToHeadOfHouseholdHouseholder: _relationshipToHeadOfHouseholdHouseholder.default,
    populationSexAgeDensity: _populationSexAgeDensity.default,
    detailedRaceAndEthnicity: _detailedRaceAndEthnicity.default
  };
  _exports.default = _default;
});